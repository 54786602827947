import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactsIcon from '@mui/icons-material/Contacts';
import Dashboard from '@mui/icons-material/Dashboard';
import Logout from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import PollIcon from '@mui/icons-material/Poll';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import StorageIcon from '@mui/icons-material/Storage';
import TuneIcon from '@mui/icons-material/Tune';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, alpha } from '@mui/material/styles';

import { ProfileCircleIcon, CarIcon, CloseIcon, MenuIcon } from 'assets/icons';
import logo from 'assets/images/logo.png';
import useCurrentRoute from 'hooks/useCurrentRoute';
import useCurrentUser from 'hooks/useCurrentUser';
import useIsMobile from 'hooks/useIsMobile';
import { signOutUser } from 'redux/slices/user.slice';

import ChatSelector from './ChatSelector';

export const drawerWidth = 250;

const SideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: '44px',
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '12px',
  margin: '1px 0',
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  '& .MuiListItemIcon-root': {
    minWidth: '32px',
    color: theme.palette.text.secondary,
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  },
  [theme.breakpoints.up('xl')]: {
    '& .MuiListItemText-primary': {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  '& .MuiListItemText-primary': {
    fontSize: '15px',
    fontWeight: 500,
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

const CurrentSideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: '44px',
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '12px',
  margin: '1px 0',
  boxShadow: `0 4px 8px ${alpha(theme.palette.primary.main, 0.25)}`,
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  '& .MuiListItemIcon-root': {
    minWidth: '32px',
    color: 'inherit',
  },
  [theme.breakpoints.up('xl')]: {
    '& .MuiListItemText-primary': {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
  '& .MuiListItemText-primary': {
    fontSize: '15px',
    fontWeight: 600,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

interface SideBarItemInterface {
  text: string;
  Icon: React.ReactNode;
  isSelected: boolean;
  path:
    | '/'
    | '/users'
    | '/clients'
    | '/businessUnit'
    | '/analysis'
    | '/businessUnits'
    | '/dashboard'
    | '/db-reader'
    | '/cars'
    | '/evals'
    | '/information'
    | '/clientQuestions'
    | '/campaigns'
    | '/profile'
    | '/reports';
}

interface SideBarButtonInterface extends SideBarItemInterface {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBarButton: React.FC<SideBarButtonInterface> = ({ text, Icon, isSelected, path, setMobileOpen }) => {
  const { isMobile } = useIsMobile();
  return (
    <Link
      to={path}
      onClick={() => {
        if (isMobile) {
          setMobileOpen(false);
        }
      }}
      style={{ textDecoration: 'none' }}
    >
      <ListItem sx={{ px: '16px', py: '4px' }}>
        {isSelected ? (
          <CurrentSideBarItemButton sx={{ px: 2 }}>
            <ListItemIcon sx={{ color: 'inherit', mr: 1 }}>{Icon}</ListItemIcon>
            <ListItemText primary={text} />
          </CurrentSideBarItemButton>
        ) : (
          <SideBarItemButton sx={{ px: 2 }}>
            <ListItemIcon sx={{ color: 'inherit', mr: 1 }}>{Icon}</ListItemIcon>
            <ListItemText primary={text} />
          </SideBarItemButton>
        )}
      </ListItem>
    </Link>
  );
};

const SideBar = () => {
  const { path } = useCurrentRoute();
  const currentUser = useCurrentUser();
  const isAdmin = !!currentUser?.isAdmin;
  const isBusinessUnitAdmin = isAdmin || (!!currentUser?.canAssignClient && !currentUser?.isAssignable);
  const isBranchManager = isBusinessUnitAdmin || (!!currentUser?.canAssignClient && !!currentUser?.isAssignable);
  const isOriginAdmin = !!currentUser?.isOriginAdmin;

  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [chatMenuAnchorEl, setChatMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const originAdminSections: SideBarItemInterface[] = [
    {
      text: 'Reportes (βeta)',
      Icon: <ScatterPlotIcon />,
      path: '/reports',
      isSelected: path.includes('/reports'),
    },
  ];

  const sellersSections: SideBarItemInterface[] = [
    {
      text: 'Clientes',
      Icon: <ContactsIcon />,
      path: '/clients',
      isSelected: path.includes('/clients'),
    },
  ];

  const branchManagerSections: SideBarItemInterface[] = [
    {
      text: 'Dashboard',
      Icon: <Dashboard />,
      isSelected: path.includes('/dashboard'),
      path: '/dashboard',
    },
    {
      text: 'Análisis',
      Icon: <AutoGraphIcon />,
      path: '/analysis',
      isSelected: path.includes('/analysis'),
    },
    {
      text: 'Usuarios',
      Icon: <PeopleIcon />,
      path: '/users',
      isSelected: path.includes('/users'),
    },
    {
      text: 'Reportes',
      Icon: <ScatterPlotIcon />,
      path: '/reports',
      isSelected: path.includes('/reports'),
    },
  ];

  const businessUnitAdminSections: SideBarItemInterface[] = [
    {
      text: 'Ajustes',
      Icon: <TuneIcon />,
      path: '/information',
      isSelected: path.includes('/information'),
    },
  ];

  const adminSections: SideBarItemInterface[] = [
    {
      text: 'Empresas',
      Icon: <Dashboard />,
      path: '/businessUnits',
      isSelected: path.includes('/businessUnits'),
    },
    {
      text: 'Autos',
      Icon: <CarIcon />,
      path: '/cars',
      isSelected: path.includes('/cars') || path.includes('/carModels'),
    },
    {
      text: 'DB reader',
      Icon: <StorageIcon />,
      path: '/db-reader',
      isSelected: path.includes('/db-reader'),
    },
    {
      text: 'Evals',
      Icon: <PollIcon />,
      path: '/evals',
      isSelected: path.includes('/evals'),
    },
    {
      text: 'Preguntas',
      Icon: <QuestionMarkIcon />,
      path: '/clientQuestions',
      isSelected: path.includes('/clientQuestions'),
    },
    {
      text: 'Campañas',
      Icon: <CampaignIcon />,
      path: '/campaigns',
      isSelected: path.includes('/campaigns'),
    },
  ];

  const drawer = (
    <div>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'right', mr: 2, mt: 2 }}>
        <IconButton aria-label="open drawer" onClick={handleDrawerToggle} color="inherit">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          py: 0,
          px: '24px !important',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          navigate('/');
        }}
      >
        <img src={logo} alt="logo" style={{ width: '85%', maxWidth: '180px' }} />
      </Box>
      <Box sx={{ flex: 1, px: 1 }}>
        <List sx={{ py: 0 }}>
          {!isOriginAdmin &&
            sellersSections.map((section) => (
              <SideBarButton
                Icon={section.Icon}
                text={section.text}
                key={section.text}
                isSelected={section.isSelected}
                path={section.path}
                setMobileOpen={setMobileOpen}
              />
            ))}
          {isBranchManager &&
            branchManagerSections.map((section) => (
              <SideBarButton
                Icon={section.Icon}
                text={section.text}
                key={section.text}
                isSelected={section.isSelected}
                path={section.path}
                setMobileOpen={setMobileOpen}
              />
            ))}
          {isOriginAdmin &&
            originAdminSections.map((section) => (
              <SideBarButton
                Icon={section.Icon}
                text={section.text}
                key={section.text}
                isSelected={section.isSelected}
                path={section.path}
                setMobileOpen={setMobileOpen}
              />
            ))}
          <ListItem sx={{ px: '16px', py: '4px' }}>
            <SideBarItemButton onClick={(e) => setChatMenuAnchorEl(e.currentTarget)}>
              <ListItemIcon sx={{ color: 'inherit', mr: 1 }}>
                <WhatsAppIcon />
              </ListItemIcon>
              <ListItemText primary="Chat" />
            </SideBarItemButton>
          </ListItem>
          <SideBarButton
            Icon={<ProfileCircleIcon />}
            text="Mi Perfil"
            isSelected={path.includes('/profile')}
            path="/profile"
            setMobileOpen={setMobileOpen}
          />
          <ListItem sx={{ px: '16px', py: '4px' }}>
            <SideBarItemButton
              onClick={() => {
                dispatch(signOutUser());
                navigate('/');
              }}
            >
              <ListItemIcon sx={{ color: 'inherit', mr: 1 }}>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </SideBarItemButton>
          </ListItem>
          {(isAdmin || isBusinessUnitAdmin) && (
            <>
              <Divider
                sx={{
                  width: '85%',
                  mx: 'auto',
                  borderColor: 'grey.300',
                  fontSize: '12px',
                  fontWeight: 300,
                  opacity: 0.8,
                }}
              >
                Admin
              </Divider>
              {isBusinessUnitAdmin &&
                businessUnitAdminSections.map((section) => (
                  <SideBarButton
                    Icon={section.Icon}
                    text={section.text}
                    key={section.text}
                    isSelected={section.isSelected}
                    path={section.path}
                    setMobileOpen={setMobileOpen}
                  />
                ))}
              {isAdmin &&
                adminSections.map((section) => (
                  <SideBarButton
                    Icon={section.Icon}
                    text={section.text}
                    key={section.text}
                    isSelected={section.isSelected}
                    path={section.path}
                    setMobileOpen={setMobileOpen}
                  />
                ))}
            </>
          )}
        </List>
      </Box>
    </div>
  );

  return (
    <>
      <ChatSelector anchorEl={chatMenuAnchorEl} onClose={() => setChatMenuAnchorEl(null)} />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          mr: 2,
          display: { xs: 'block', md: 'none' },
          position: 'fixed',
          top: '20px',
          left: `25px`,
          zIndex: '100',
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box component="nav" sx={{ mr: 1, width: { xs: '100%', md: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
              boxShadow: (theme) => `4px 0 10px -5px ${alpha(theme.palette.common.black, 0.15)}`,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              border: 0,
              boxShadow: (theme) => `4px 0 10px -5px ${alpha(theme.palette.common.black, 0.15)}`,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default SideBar;
