import React, { ReactNode } from 'react';

import { BusinessUnitsProvider } from './BusinessUnitsContext';

interface AppProvidersProps {
  children: ReactNode;
}

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  return (
    <BusinessUnitsProvider>
      {/* Add other providers here as needed */}
      {children}
    </BusinessUnitsProvider>
  );
};

export default AppProviders;
