import React, { FC } from 'react';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useCreateProspectMutation } from 'services/api/prospects.api';
import validateEmail from 'utils/validations/validateEmail';

import { BlueSeparator } from './How';

const FooterContacts = (title: string, content: string) => (
  <Box px={{ xs: 5, lg: 0 }}>
    <Box width="min-content" display="inline-grid">
      <Typography
        fontFamily="Roboto"
        fontSize={{ lg: '15px', xs: '20px' }}
        lineHeight="30px"
        fontWeight="600"
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <BlueSeparator />
    </Box>
    <Typography fontFamily="Jost" fontSize={{ lg: '15px', xs: '20px' }} lineHeight="30px" fontWeight="regular">
      {content}
    </Typography>
  </Box>
);

const Footer: FC = () => {
  const [email, setEmail] = React.useState('');
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [phone, setPhone] = React.useState('');

  const [createProspect] = useCreateProspectMutation();

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setInvalidEmail(true);
    }
    await createProspect({ email, phone, reason: 'Landing page lead' });
  };

  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Grid pt={8}>
      <Box
        sx={
          isMobile
            ? {
                background: 'linear-gradient(90deg, #15183A, #224267)',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }
            : {
                backgroundImage: 'url("/landing/footer-background.svg")',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                zIndex: 1,
              }
        }
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt={12}
        pb={2}
      >
        <Box display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
          <Box textAlign={{ xs: 'center', lg: 'start' }}>
            <Typography
              fontFamily="Roboto"
              fontSize={{ lg: '50px', xs: '35px' }}
              lineHeight="50px"
              fontWeight="medium"
              color="white"
            >
              ¿Quieres saber más?
            </Typography>
            <Typography
              fontFamily="Roboto"
              fontSize={{ lg: '50px', xs: '35px' }}
              lineHeight="60px"
              fontWeight="600"
              color="#47BCD8"
            >
              ¡Hablemos!
            </Typography>
          </Box>
          <Box pl={{ xs: 4, sm: 12, lg: 20 }} pr={{ xs: 4, sm: 12, lg: 0 }} pt={{ xs: 2, lg: 0 }}>
            <TextField
              placeholder="Tu correo"
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: 'white', borderRadius: '6px' }}
              onChange={(e) => setEmail(e.target.value)}
              error={invalidEmail}
              helperText={invalidEmail && 'Por favor ingrese un email válido'}
            />
            <TextField
              placeholder="Tu Celular"
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: 'white', borderRadius: '6px', my: 2 }}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Box display="flex" justifyContent={{ xs: 'center', lg: 'end' }}>
              <PrimaryBtn
                variant="outlined"
                sx={{
                  color: 'white',
                  backgroundColor: 'transparent',
                  borderColor: '#F9D00A',
                  '&:hover': {
                    backgroundColor: '#F9D00A',
                    borderColor: '#F9D00A',
                    color: '#15183A',
                  },
                }}
                onClick={() => {
                  // eslint-disable-next-line no-console
                  handleSubmit().catch((e) => console.error(e));
                }}
              >
                Enviar
              </PrimaryBtn>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ lg: 'row', xs: 'column' }}
          justifyContent="space-around"
          textAlign={{ xs: 'center', lg: 'start' }}
          gap={{ xs: 4, lg: 0 }}
          py={4}
          width="90%"
          color="white"
        >
          {FooterContacts('Dirección', 'Av. Vitacura 4380, piso 2, Vitacura, Santiago')}
          {FooterContacts('Teléfono', '+56 9 9226 8245')}
          {FooterContacts('Correo', 'contacto@zeller.ai')}
        </Box>
      </Box>
    </Grid>
  );
};

export default Footer;
