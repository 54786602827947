import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AttachFile from '@mui/icons-material/AttachFile';
import Send from '@mui/icons-material/Send';
import { Grid, IconButton, TextField } from '@mui/material';

import Conversation from 'components/common/Conversation';
import useCurrentUser from 'hooks/useCurrentUser';
import Pusher from 'pusher-js';
import {
  useGetDemoClientMessagesQuery,
  useCreateDemochatMessageMutation,
  useGetIsPrivateDemoQuery,
  useUploadDemochatFileMutation,
} from 'services/api/demochat';
import type { Message } from 'services/types/message';

import ChatWrapper from './ChatWrapper';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const Demochat = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const localStorageKey = `zeller-pusher-demo-${companyIdentifier}`;
  const clientPhoneIdentifier = localStorage.getItem(localStorageKey);
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [messageToSend, setMessageToSend] = useState('');
  const [createDemochatMessage] = useCreateDemochatMessageMutation();
  const [conversation, setConversation] = useState<Message[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { data: privateDemoData } = useGetIsPrivateDemoQuery({
    companyIdentifier: companyIdentifier ?? '',
  });
  if (privateDemoData && privateDemoData.isPrivateDemo) {
    if (!currentUser || currentUser.id === 0) {
      navigate(`/sign-in?redirect=/demochat/${companyIdentifier}`);
    }
  }

  const { data: messages, refetch } = useGetDemoClientMessagesQuery({
    clientPhoneIdentifier: clientPhoneIdentifier ?? '',
  });

  const [uploadDemochatFile] = useUploadDemochatFileMutation();
  const [refetchFlag, setRefetchFlag] = useState<number>(1);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching messages');
    });
  }, [refetchFlag]);

  useEffect(() => {
    if (messages) {
      setConversation(messages);
    }
  }, [messages]);

  if (!clientPhoneIdentifier) {
    // navigate to PusherSignin
    navigate(`/demochat/${companyIdentifier}/newchat`);
  }

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, { cluster: 'sa1' });

    const channel = pusher.subscribe(`chat-${clientPhoneIdentifier}`);
    channel.bind('new-message', (data: object) => {
      setRefetchFlag((prev) => prev + 1);
      console.log('New message', JSON.stringify(data)); // eslint-disable-line no-console
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${clientPhoneIdentifier}`);
      pusher.disconnect();
    };
  }, [clientPhoneIdentifier, setRefetchFlag]);

  const handleSendMessage = (e?: React.KeyboardEvent<HTMLDivElement>) => {
    if (e) {
      e.preventDefault();
    }
    if (!clientPhoneIdentifier || !messageToSend || messageToSend.length === 0) {
      return;
    }
    const message = messageToSend.trim();
    setMessageToSend('');
    setConversation([
      ...conversation,
      {
        createdAt: new Date(),
        updatedAt: new Date(),
        status: 'sent to api',
        text: message,
        sender: 'client',
        role: 'user',
      } as Message,
    ]);
    createDemochatMessage({ clientPhoneIdentifier, message })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending demo chat message', error);
      });
  };

  useEffect(() => {
    const messageParam = searchParams.get('message');
    if (messageParam) {
      const autoStartChatParam = searchParams.get('autoStartChat');
      if (autoStartChatParam && clientPhoneIdentifier) {
        createDemochatMessage({ clientPhoneIdentifier, message: messageParam })
          .then(() => {
            setRefetchFlag(refetchFlag + 1);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error sending initial demo chat message', error);
          });
      } else {
        setMessageToSend(messageParam);
      }
      searchParams.delete('message');
      searchParams.delete('autoStartChat');
      setSearchParams(searchParams, { replace: true });
    }
  }, []); // Run only once on component mount

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !clientPhoneIdentifier) return;

    // Check file type
    const isValidFileType = file.type.startsWith('image/') || file.type === 'application/pdf';
    if (!isValidFileType) {
      // eslint-disable-next-line no-alert
      alert('Solo se pueden subir archivos de imagen o PDF');
      return;
    }

    try {
      uploadDemochatFile({ clientPhoneIdentifier, file })
        .unwrap()
        .then(() => {
          setRefetchFlag((prev) => prev + 1);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error uploading file:', error);
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading file:', error);
    }
  };

  return (
    <ChatWrapper companyIdentifier={companyIdentifier} includeGoToNewChatButton>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Conversation conversation={conversation || []} isFilteredConversation isClientView />
        <Grid container width="100%" p={2} justifyContent="space-between" alignItems="center">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*,.pdf"
            onChange={handleFileUpload}
          />
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            onClick={() => fileInputRef.current?.click()}
          >
            <AttachFile />
          </IconButton>
          <TextField
            id="outlined-basic-email"
            label="Mensaje"
            fullWidth
            multiline
            sx={{
              maxWidth: '80%',
              backgroundColor: '#fff',
              borderRadius: '40px',
              opacity: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': { borderRadius: '40px' },
            }}
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage(e)}
            autoFocus
          />
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            onClick={() => handleSendMessage()}
            disabled={messageToSend.length === 0}
          >
            <Send
              sx={{
                fontSize: '2.5rem',
              }}
            />
          </IconButton>
        </Grid>
      </div>
    </ChatWrapper>
  );
};

export default Demochat;
