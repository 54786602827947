import React, { FC } from 'react';

import Box from '@mui/material/Box';

import SearchBar from 'components/UI/SearchBar';
import BusinessUnitTypeSelector from 'components/common/BusinessUnitTypeSelector';

interface SearchSectionProps {
  searchTerm: string;
  onSearchTermChange: (term: string) => void;
  businessUnitId: number | null;
  setBusinessUnitId: (id: number) => void;
  businessUnitTypeFilter: 'car_dealership' | 'financial_advisor' | null;
  setBusinessUnitTypeFilter: (type: 'car_dealership' | 'financial_advisor' | null) => void;
}

const SearchSection: FC<SearchSectionProps> = ({
  searchTerm,
  onSearchTermChange,
  businessUnitId,
  setBusinessUnitId,
  businessUnitTypeFilter,
  setBusinessUnitTypeFilter,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        flexWrap: 'nowrap',
        minWidth: 'auto',
      }}
    >
      <SearchBar
        searchTerm={searchTerm}
        onSearchTermChange={onSearchTermChange}
        businessUnitId={businessUnitId}
        setBusinessUnitId={setBusinessUnitId}
      />
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <BusinessUnitTypeSelector
          businessUnitType={businessUnitTypeFilter}
          setBusinessUnitType={setBusinessUnitTypeFilter}
        />
      </Box>
    </Box>
  );
};

export default SearchSection;
