import React, { FC, useEffect, useState } from 'react';

import FilterAlt from '@mui/icons-material/FilterAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

import RelifIcon from 'assets/images/relif_icon.svg';
import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import InfoDialog from 'components/UI/InfoDialog';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import OptionsDialog from 'components/UI/OptionsDialog';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  useGetCreditStatusMutation,
  useUpdateEditableAttributesMutation,
  useUploadClientToCRMMutation,
} from 'services/api/clients.api';
import { Client, ClientStatus } from 'services/types/client';

import TemplateMessageModal from './TemplateMessageModal';

interface Props {
  client: Client;
  refetch: () => void;
  setIsFilteredConversation: (isFiltered: boolean) => void;
  isFilteredConversation: boolean;
}

const ChatHeader: FC<Props> = ({ client, refetch, setIsFilteredConversation, isFilteredConversation }) => {
  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openScheduleTemplateModal, setOpenScheduleTemplateModal] = useState<boolean>(false);
  const [openUploadToCRMModal, setOpenUploadToCRMModal] = useState<boolean>(false);
  const [openCRMError, setOpenCRMError] = useState<boolean>(false);
  const [CRMUploadError, setCRMUploadError] = useState<null | string>(null);
  const [openCRMSuccess, setOpenCRMSuccess] = useState<boolean>(false);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<ClientStatus | null>(client.status || null);

  const [updateEditableAttributes] = useUpdateEditableAttributesMutation();
  const [getCreditStatus, { data: creditStatus }] = useGetCreditStatusMutation();
  const [uploadClientToCRM, { isLoading: isUploadClientToCRMLoading }] = useUploadClientToCRMMutation();

  const uploadableBusinessIds = [2, 3, 8, 10, 11, 14, 16, 17, 18, 19, 20, 21, 22, 24, 27];

  const openDropdownMenu = Boolean(anchorEl);
  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(client.publicChatLink || window.location.href)
      .then(() => {
        setLinkCopied(true);
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error copying link');
      });
  };

  const handleEnableAutomaticResponses = () => {
    updateEditableAttributes({
      id: client.id,
      body: { disabledAutomaticResponses: !client.disabledAutomaticResponses },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error updating client');
      });
  };

  const handleChangeStatus = () => {
    if (!selectedStatus) {
      return;
    }
    updateEditableAttributes({
      id: client.id,
      body: { status: selectedStatus },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error updating client');
      });
  };

  const handleGetCreditStatus = () => {
    getCreditStatus(client.id)
      .then(() => {
        refetch();
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error getting credit status');
      });
  };

  const handleUploadToCRM = () => {
    if (!isUploadClientToCRMLoading) {
      uploadClientToCRM({ clientId: client.id, businessUnitId: client.businessUnitId })
        .then((data) => {
          console.log(data); // eslint-disable-line no-console
          setOpenUploadToCRMModal(false);
          if ('error' in data) {
            setOpenCRMError(true);
            if ('data' in data.error && typeof data.error.data === 'string') {
              setCRMUploadError(data.error.data);
            }
          } else {
            setOpenCRMSuccess(true);
            refetch();
          }
        })
        .catch(() => {
          setOpenUploadToCRMModal(false);
          setOpenCRMError(true);
          setCRMUploadError('Error subiendo cliente al CRM');
        });
    }
  };

  useEffect(() => {
    if (selectedStatus) {
      handleChangeStatus();
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (creditStatus) {
      alert(JSON.stringify(creditStatus)); // eslint-disable-line no-alert
    }
  }, [creditStatus]);

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    }
  }, [linkCopied]);

  return (
    <Grid item xs={12} px={2}>
      <LoadingOverlay visible={isUploadClientToCRMLoading} />
      {openScheduleTemplateModal && (
        <TemplateMessageModal
          clientId={client.id}
          businessUnitId={client.businessUnitId}
          open
          setOpen={setOpenScheduleTemplateModal}
          refetch={refetch}
          schedule={false}
        />
      )}
      <OptionsDialog
        openDialog={openUploadToCRMModal && !isUploadClientToCRMLoading}
        setOpenDialog={setOpenUploadToCRMModal}
        header="Subir lead al CRM"
        description="¿Estás seguro que deseas subir este lead al CRM?"
      >
        <PrimaryBtn color="error" onClick={() => setOpenUploadToCRMModal(false)}>
          Cancelar
        </PrimaryBtn>
        <PrimaryBtn onClick={handleUploadToCRM}>Subir</PrimaryBtn>
      </OptionsDialog>
      <AlertDialog
        openDialog={openCRMError}
        setOpenDialog={setOpenCRMError}
        header="Error"
        msg={CRMUploadError || 'Error subiendo cliente al CRM'}
        cancelMsg="Cerrar"
      />
      <Modal open={openChangeStatusModal} onClose={() => setOpenChangeStatusModal(false)}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', lg: '700px' },
            bgcolor: 'background.paper',
            borderRadius: '20px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
          }}
        >
          <Box p={2} minWidth="250px">
            <FormControl fullWidth>
              <InputLabel id="select-status">Seleccionar estado</InputLabel>
              <Select
                labelId="select-status"
                value={selectedStatus || ''}
                label="Seleccionar estado"
                onChange={(e) => {
                  setSelectedStatus(e.target.value as ClientStatus);
                  setOpenChangeStatusModal(false);
                }}
              >
                <MenuItem value="open">Abierto</MenuItem>
                <MenuItem value="pending">Pendiente</MenuItem>
                <MenuItem value="success-closed">Cerrado exitoso</MenuItem>
                <MenuItem value="failed-closed">Cerrado fallido</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Paper>
      </Modal>
      {openCRMSuccess && <InfoDialog header="Cliente subido al CRM" msg="Cliente subido al CRM con éxito!" />}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Tooltip title="Recargar conversación" arrow>
            <IconButton onClick={refetch} size="large">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <IconButton onClick={handleDropdownClick}>
            <MoreVertIcon />
          </IconButton>

          <Menu
            open={openDropdownMenu}
            onClose={handleDropdownClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenScheduleTemplateModal(true);
              }}
            >
              Enviar Template
            </MenuItem>
            <MenuItem onClick={handleEnableAutomaticResponses}>
              {client.disabledAutomaticResponses ? 'Habilitar' : 'Deshabilitar'} IA
            </MenuItem>
            {client.creditSimulations && client.creditSimulations.length > 0 && (
              <MenuItem onClick={handleGetCreditStatus}>Estado de simulación de crédito</MenuItem>
            )}
            <MenuItem onClick={handleCopyLink} disabled={linkCopied}>
              {linkCopied ? 'Link copiado' : 'Compartir link'}
            </MenuItem>
            {uploadableBusinessIds.includes(client.businessUnitId) && (
              <MenuItem onClick={() => setOpenUploadToCRMModal(true)}>Subir al CRM</MenuItem>
            )}
            <MenuItem
              onClick={() => {
                setOpenChangeStatusModal(true);
                handleDropdownClose();
              }}
            >
              Cambiar estado
            </MenuItem>
          </Menu>
          {currentUser?.isAdmin && (
            <Tooltip title="Filtrar conversación" arrow>
              <IconButton onClick={() => setIsFilteredConversation(!isFilteredConversation)} size="large">
                <FilterAlt
                  sx={{
                    color: isFilteredConversation ? 'primary.main' : 'inherit',
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {currentUser?.isAdmin && client.customAttributes && client.customAttributes.relifId && (
            <IconButton
              size="large"
              onClick={() => {
                if (!client.customAttributes) {
                  return;
                }
                window.open(
                  `${import.meta.env.VITE_RELIF_URL}/admin/users?selectedUserId=${
                    client.customAttributes.relifId
                  }&userId=${client.customAttributes.relifId}`,
                  '_blank'
                );
              }}
            >
              <img src={RelifIcon} alt="Relif" width={30} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ChatHeader;
