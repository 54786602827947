import React, { FC, useEffect, useState } from 'react';

import { Box, Paper, Stack, Modal, Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment';
import { useGetTemplatesQuery } from 'services/api/clients.api';
import { usePostTemplateMessageMutation } from 'services/api/messages';
import { SendTemplate, TemplateResponse } from 'services/types/message';

import CreateTemplateForm from './CreateTemplateForm';

declare type Parameter = { text: string; type: 'text' };
declare type Parameters = { HEADER: Parameter[]; BODY: Parameter[]; FOOTER: Parameter[]; BUTTON: Parameter[] };
const getEmptyParameters = (): Parameters => ({
  HEADER: [],
  BODY: [],
  FOOTER: [],
  BUTTON: [],
});

interface Props {
  clientId: number;
  businessUnitId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
  schedule: boolean;
}

const TemplateFormModal: FC<Props> = ({ clientId, businessUnitId, open, setOpen, refetch, schedule }) => {
  const { data: templates } = useGetTemplatesQuery(clientId);
  const [activeTab, setActiveTab] = useState(0);
  const [messageToSend, setMessageToSend] = useState({
    HEADER: '',
    BODY: '',
    FOOTER: '',
    BUTTONS: [
      { text: '', url: '' },
      { text: '', url: '' },
    ],
  });
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponse[number] | null>(null);
  const [postTemplate] = usePostTemplateMessageMutation();
  const [parameters, setParameters] = useState<Parameters>(getEmptyParameters());

  const tomorrow = moment(new Date().setDate(new Date().getDate() + 1));
  const [postAt, setPostAt] = useState<moment.Moment | null>(tomorrow);

  const emptyParameters = () => {
    setParameters(getEmptyParameters());
    setSelectedTemplate(null);
    setMessageToSend({
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    });
  };

  useEffect(() => {
    // build Form
    if (selectedTemplate) {
      const tempParameters: Parameters = getEmptyParameters();
      selectedTemplate?.components.forEach((component) => {
        if (component.type !== 'BUTTONS') {
          const count = (component.text?.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            tempParameters[component.type].push({ text: '', type: 'text' });
          }
        } else if (component.buttons) {
          // There can only be 2 buttons but only one with a url
          let url;
          if (component.buttons.length === 2) {
            url = component.buttons[0].url || component.buttons[1].url || '';
          } else {
            url = component.buttons[0].url || '';
          }
          const count = (url.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            tempParameters.BUTTON.push({ text: '', type: 'text' });
          }
        }
      });
      setParameters(tempParameters);
    }
  }, [selectedTemplate]);

  const getTemplateMessage = () => {
    const tempMessageToSend = {
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    };
    selectedTemplate?.components.forEach((component) => {
      if (component.text && component.type !== 'BUTTONS') {
        const count = (component.text.match(/{{[1-9]}}/g) || []).length;
        let newText = component.text;
        for (let i = 0; i < count; i += 1) {
          const re = new RegExp(`{{[${i + 1}]}}`, 'g');
          newText = newText.replace(re, parameters[component.type][i].text || `<parámetro ${i + 1}>`);
        }
        tempMessageToSend[component.type] = newText;
      } else if (component.buttons) {
        // There can only be 2 buttons but only one with a url
        let buttonWithUrl;
        if (component.buttons[0].url) {
          buttonWithUrl = 0;
        } else if (component.buttons.length === 2 && component.buttons[1].url) {
          buttonWithUrl = 1;
        }
        if (buttonWithUrl !== undefined) {
          let url = component.buttons[buttonWithUrl].url || '';
          const count = (url.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            const re = new RegExp(`{{[${i + 1}]}}`, 'g');
            url = url.replace(re, parameters.BUTTON[i].text);
          }
          tempMessageToSend.BUTTONS[buttonWithUrl].url = url;
        }
        component.buttons.forEach((button, index) => {
          tempMessageToSend.BUTTONS[index].text = button.text;
        });
      }
    });
    setMessageToSend(tempMessageToSend);
  };

  const getTemplateMessageWithoutParameters = () => {
    const tempMessageToSend = {
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    };
    selectedTemplate?.components.forEach((component) => {
      if (component.text && component.type !== 'BUTTONS') {
        tempMessageToSend[component.type] = component.text;
      } else if (component.buttons) {
        component.buttons.forEach((button, index) => {
          tempMessageToSend.BUTTONS[index].text = button.text;
          tempMessageToSend.BUTTONS[index].url = button.url || '';
        });
      }
    });
    setMessageToSend(tempMessageToSend);
  };

  useEffect(() => {
    if (
      parameters.HEADER.length > 0 ||
      parameters.BODY.length > 0 ||
      parameters.FOOTER.length > 0 ||
      parameters.BUTTON.length > 0
    ) {
      getTemplateMessage();
    } else {
      getTemplateMessageWithoutParameters();
    }
  }, [parameters]);

  useEffect(() => {
    if (templates && templates.length > 0 && !selectedTemplate) {
      const defaultTemplate = templates.find((template) => template.name === 'gracias_por_contactarnos');
      if (defaultTemplate) {
        setSelectedTemplate(defaultTemplate);
      }
    }
  }, [templates]);

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: keyof Parameters,
    index: number
  ) => {
    const data = [...parameters[type]];
    data[index].text = event.target.value;
    setParameters({ ...parameters, [type]: data });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let components: SendTemplate['components'] = [];
    Object.keys(parameters).forEach((key) => {
      if (parameters[key as keyof Parameters].length > 0 && components) {
        components.push({
          type: key.toLowerCase() as 'header' | 'body' | 'footer' | 'button',
          parameters: parameters[key as keyof Parameters],
          sub_type: key === 'BUTTON' ? ('url' as const) : undefined,
          index: key === 'BUTTON' ? 0 : undefined,
        });
      }
    });
    if (components.length === 0) {
      components = undefined;
    }
    if (selectedTemplate && !schedule) {
      postTemplate({
        clientId,
        templateName: selectedTemplate.name,
        components,
      })
        .then(() => refetch())
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    } else if (selectedTemplate && schedule && postAt) {
      // eslint-disable-next-line no-alert
      alert('Not available yet');
    }
    emptyParameters();
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal open onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', md: '70%' },
          maxHeight: '90vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: (theme) => theme.shadows[24],
          p: 4,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(Number(newValue))}
          sx={{
            mb: 2,
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
            },
          }}
        >
          <Tab label="Enviar Template" />
          <Tab label="Crear Template" />
        </Tabs>

        {activeTab === 0 ? (
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={3}>
            <Box flex={1} minWidth={{ xs: '100%', md: '250px' }}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="select-template">Seleccionar template</InputLabel>
                <Select
                  labelId="select-template"
                  id="select-template"
                  value={selectedTemplate?.name || ''}
                  label="Seleccionar template"
                  onChange={(e) => {
                    const targetTemplate = templates?.find((template) => template.name === e.target.value);
                    if (targetTemplate) {
                      setSelectedTemplate(targetTemplate);
                    }
                  }}
                >
                  {templates?.map((template) => (
                    <MenuItem key={template.id} value={template.name}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <form onSubmit={handleFormSubmit}>
                <Stack spacing={1.5}>
                  {Object.keys(parameters).map((key) => {
                    if (parameters[key as keyof Parameters].length > 0) {
                      return (
                        <Box key={key}>
                          <Typography variant="subtitle1" fontWeight={500} mb={0.5}>
                            Parámetros {key}
                          </Typography>
                          <Stack spacing={1.5}>
                            {parameters[key as keyof Parameters].map((form, index) => (
                              <TextField
                                key={`${key}-${index.toString()}`}
                                name={`text-${index.toString()}`}
                                onChange={(event) => handleFormChange(event, key as keyof Parameters, index)}
                                value={form.text}
                                size="small"
                                fullWidth
                              />
                            ))}
                          </Stack>
                        </Box>
                      );
                    }
                    return null;
                  })}
                  {schedule && (
                    <Box my={1.5}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          slotProps={{
                            textField: {
                              variant: 'outlined',
                              fullWidth: true,
                              sx: { borderRadius: 2 },
                            },
                          }}
                          label="Fecha recordatorio"
                          value={postAt}
                          onChange={(date) => setPostAt(date)}
                        />
                      </LocalizationProvider>
                    </Box>
                  )}
                  {selectedTemplate && (
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={
                        parameters.HEADER.some((p) => !p.text) ||
                        parameters.BODY.some((p) => !p.text) ||
                        parameters.FOOTER.some((p) => !p.text) ||
                        parameters.BUTTON.some((p) => !p.text)
                      }
                      sx={{
                        mt: 1.5,
                        borderRadius: 2,
                        textTransform: 'none',
                        fontSize: '1rem',
                      }}
                    >
                      {schedule ? 'Programar Plantilla' : 'Enviar Plantilla'}
                    </Button>
                  )}
                </Stack>
              </form>
            </Box>
            <Box flex={1.5}>
              {selectedTemplate && (
                <Stack spacing={1.5}>
                  <Typography variant="h6" fontWeight={500}>
                    Mensaje a enviar:
                  </Typography>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 3,
                      bgcolor: 'background.default',
                    }}
                  >
                    {messageToSend.HEADER && (
                      <Typography variant="h6" sx={{ whiteSpace: 'pre-line', mb: 1.5 }}>
                        {messageToSend.HEADER}
                      </Typography>
                    )}
                    {messageToSend.BODY && (
                      <Typography variant="body1" sx={{ whiteSpace: 'pre-line', mb: 1.5 }}>
                        {messageToSend.BODY}
                      </Typography>
                    )}
                    {messageToSend.FOOTER && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          whiteSpace: 'pre-line',
                          mb: 1.5,
                        }}
                      >
                        {messageToSend.FOOTER}
                      </Typography>
                    )}
                    {messageToSend.BUTTONS.some((b) => b.text !== '') && (
                      <Stack spacing={1.5}>
                        <Typography variant="subtitle1" fontWeight={500}>
                          Botones:
                        </Typography>
                        {messageToSend.BUTTONS.map(
                          (button) =>
                            button.text && (
                              <Button
                                key={button.text}
                                variant="outlined"
                                fullWidth
                                sx={{
                                  justifyContent: 'flex-start',
                                  textTransform: 'none',
                                  borderRadius: 2,
                                  p: 1.5,
                                }}
                              >
                                {button.text}
                                {button.url && (
                                  <Typography
                                    component="span"
                                    sx={{
                                      ml: 1,
                                      color: 'text.secondary',
                                      fontSize: '0.875rem',
                                    }}
                                  >
                                    ({button.url})
                                  </Typography>
                                )}
                              </Button>
                            )
                        )}
                      </Stack>
                    )}
                  </Paper>
                </Stack>
              )}

              {!selectedTemplate && templates && templates.length > 0 && (
                <Typography variant="body1" color="text.secondary" textAlign="center">
                  No hay template seleccionado
                </Typography>
              )}
              {templates && templates.length === 0 && (
                <Typography variant="body1" color="text.secondary" textAlign="center">
                  No hay templates disponibles, pídele a tu administrador que te arme un template
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          <CreateTemplateForm businessUnitId={businessUnitId} />
        )}
      </Paper>
    </Modal>
  );
};

export default TemplateFormModal;
