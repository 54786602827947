import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Send } from '@mui/icons-material';
import { Box, Container, Typography, CircularProgress, Select, MenuItem, TextField } from '@mui/material';

import ErrorImg from 'assets/images/404.webp';
import { PrimaryBtn } from 'components/UI/Buttons';

import { useGetMessageQuery } from '../../services/api/messages';
import { useGenerateRequestMutation } from '../../services/api/playground.api';
import { OpenAIFunction, AIResponse } from '../../services/types/message';

const AIModels = [
  'gpt-4o',
  'grok-beta',
  'grok-vision-beta',
  'gpt-4o-2024-11-20',
  'gpt-4o-mini',
  'gpt-4.5-preview-2025-02-27',
  'o1-preview',
  'o3-mini',
  'o1',
  'gemini-pro',
  'gemini-2.0-flash',
  'claude-3-5-haiku-latest',
  'claude-3-5-sonnet-latest',
  'claude-3-7-sonnet-latest',
  'claude-3-opus-latest',
  'deepseek-chat',
  'deepseek-reasoner',
  'sonar',
  'sonar-pro',
  'sonar-reasoning',
];

const DEFAULT_MODEL = 'gpt-4o-2024-11-20';

const ErrorPage: FC = () => (
  <Container sx={{ mt: 4 }}>
    <Typography variant="h4" mb={2}>
      Oops... El mensaje que buscas no existe. Revisa la url o elige otro mensaje.
    </Typography>
    <img src={ErrorImg} alt="Error" style={{ width: 'min(600px, 98%)' }} />
  </Container>
);

const Playground: FC = () => {
  const { messageId } = useParams<{ messageId: string }>();
  const [functions, setFunctions] = useState<string>('[]');
  const [conversation, setConversation] = useState<string>('[]');
  const [prompt, setPrompt] = useState<string>('');
  const [model, setModel] = useState<string>(DEFAULT_MODEL);
  const [toolChoice, setToolChoice] = useState<string>('auto');
  const [conversationError, setConversationError] = useState<boolean>(false);
  const [functionsError, setFunctionsError] = useState<boolean>(false);
  const [generateAIRequest, { data: AIData, isLoading: openAIRequestLoading }] = useGenerateRequestMutation();

  const data = AIData;

  if (!messageId) {
    return <ErrorPage />;
  }
  const { data: message, isLoading, isError } = useGetMessageQuery(Number(messageId));

  const currentResponse = {
    completionMessageContent: message?.text ?? '',
    toolCalls: message?.functionName
      ? [
          {
            function: {
              name: message.functionName,
              // remove all spaces and linebreaks
              arguments: message.functionArguments ? message.functionArguments.replaceAll(/\s/g, '') : '',
            },
          },
        ]
      : undefined,
  };

  useEffect(() => {
    if (!message) {
      return;
    }
    setModel(message.AIModel ?? DEFAULT_MODEL);
    setPrompt(message.prompt ?? '');
    setFunctions(JSON.stringify(message.functions ?? [], null, 2));
    setConversation(JSON.stringify(message.conversation, null, 2));
  }, [message]);

  useEffect(() => {
    window.onbeforeunload = (e) => {
      e.returnValue = 'Are you sure you want leave?';
      return message;
    };
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!message || isError) {
    return <ErrorPage />;
  }

  const onChangeConversation = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newConversation = e.target.value;

    try {
      const value = JSON.parse(newConversation) as AIResponse[];
      setConversation(JSON.stringify(value, null, 2));
      setConversationError(false);
    } catch (_e) {
      setConversation(newConversation);
      setConversationError(true);
    }
  };

  const onChangeFunctions = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newFunctions = e.target.value;

    try {
      const value = JSON.parse(newFunctions) as OpenAIFunction[];
      setFunctions(JSON.stringify(value, null, 2));
      setFunctionsError(false);
    } catch (_e) {
      setFunctions(newFunctions);
      setFunctionsError(true);
    }
  };

  const handleSend = () => {
    let conversationToSend: AIResponse[] = [];
    try {
      conversationToSend = conversationToSend.concat(JSON.parse(conversation) as AIResponse[]);
    } catch (_e) {
      setConversationError(true);
      return;
    }
    const requestData = {
      conversation: conversationToSend,
      functions: JSON.parse(functions) as OpenAIFunction[],
      prompt,
      model,
      toolChoice,
    };

    generateAIRequest(requestData).catch(console.error); // eslint-disable-line no-console
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        {/* <Box justifyContent="row"> */}
        <Box>
          <textarea
            name="prompt"
            rows={50}
            cols={120}
            style={{ fontSize: '12px' }}
            onChange={(e) => setPrompt(e.target.value)}
            value={prompt}
          />
        </Box>
        <Box flexDirection="column" display="flex">
          <textarea
            name="conversation"
            rows={31}
            cols={110}
            onChange={(e) => onChangeConversation(e)}
            value={conversation}
            style={{
              marginBottom: '8px',
              backgroundColor: conversationError ? '#e1c0c0' : '#c9d9c9',
              fontSize: '12px',
            }}
          />
          <textarea
            name="functions"
            rows={18}
            cols={110}
            onChange={(e) => onChangeFunctions(e)}
            value={functions}
            style={{ backgroundColor: functionsError ? '#e1c0c0' : '#c9d9c9', fontSize: '12px' }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center">
          <PrimaryBtn
            onClick={() => handleSend()}
            disabled={conversationError || functionsError || openAIRequestLoading}
            variant="contained"
          >
            {openAIRequestLoading ? <CircularProgress size={20} /> : <Send />}
            Enviar
          </PrimaryBtn>
          <Select value={model} onChange={(e) => setModel(e.target.value)} sx={{ ml: 2 }}>
            {AIModels.map((aiModel) => (
              <MenuItem key={aiModel} value={aiModel}>
                {aiModel}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label="ToolChoice"
            value={toolChoice}
            onChange={(e) => setToolChoice(e.target.value)}
            sx={{ ml: 2 }}
          />
        </Box>
        <p style={{ paddingLeft: '4px', margin: 0, fontSize: '16px' }}>
          {data ? `Nueva respuesta: ${JSON.stringify(data)}` : `Respuesta actual: ${JSON.stringify(currentResponse)}`}
        </p>
      </Box>
    </Box>
  );
};

export default Playground;
