import React, { FC, useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Grid,
  Divider,
} from '@mui/material';
import { GridColDef, GridFilterItem, GridFilterModel } from '@mui/x-data-grid';

interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  onFilterChange: (items: GridFilterItem[]) => void;
  columns: GridColDef[];
  filterModel: GridFilterModel;
  sources: string[];
}

const FilterModal: FC<FilterModalProps> = ({ open, onClose, onFilterChange, columns, filterModel, sources }) => {
  const [items, setItems] = useState<GridFilterItem[]>(filterModel.items);

  useEffect(() => {
    setItems(filterModel.items);
  }, [filterModel.items]);

  const handleFieldChange = (index: number, field: string) => {
    setItems(items.map((item, i) => (i === index ? { ...item, field } : item)));
  };
  const handleOperatorChange = (index: number, operator: string) => {
    setItems(items.map((item, i) => (i === index ? { ...item, operator } : item)));
  };
  const handleValueChange = (index: number, value: string) => {
    setItems(items.map((item, i) => (i === index ? { ...item, value } : item)));
  };
  const addFilter = () => {
    setItems(items.concat({ field: '', operator: '', value: '' }));
  };
  const removeFilter = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const isSomeItemEmpty = items.some((item) => {
    if (item.field === '' || item.operator === '') {
      return true;
    }
    if (item.operator === 'isEmpty' || item.operator === 'isNotEmpty') {
      return false;
    }
    return item.value === '';
  });

  const getTooltipTitle = () => {
    if (items.length === 0) {
      return 'Agrega un filtro para empezar';
    }
    if (isSomeItemEmpty) {
      return 'Completa todos los campos para aplicar el filtro';
    }
    return '';
  };

  const handleApply = () => {
    if (isSomeItemEmpty) {
      return;
    }
    onFilterChange(items);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '90%',
          maxWidth: '1000px',
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          pb: 1,
          pt: 2.5,
          px: 3,
          typography: 'h5',
          fontWeight: 600,
        }}
      >
        Filtrar
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 3 }}>
        {items.length > 0 ? (
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={3.5}>
              <Typography variant="subtitle2" color="text.secondary">
                Campo
              </Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Typography variant="subtitle2" color="text.secondary">
                Operador
              </Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Typography variant="subtitle2" color="text.secondary">
                Valor
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="subtitle2" color="text.secondary">
                Eliminar
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', py: 4 }}>
            Agrega un filtro para empezar
          </Typography>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
          {items.map((item, index) => (
            <Grid container key={item.field} alignItems="center" spacing={2}>
              <Grid item xs={3.5}>
                <FormControl sx={{ width: '100%' }} size="small">
                  <InputLabel id={`field-label-${index}`}>Campo</InputLabel>
                  <Select
                    labelId={`field-label-${index}`}
                    value={item.field}
                    onChange={(e) => handleFieldChange(index, e.target.value)}
                    label="Campo"
                  >
                    {columns.map((column) => (
                      <MenuItem key={column.field} value={column.field}>
                        {column.headerName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl sx={{ width: '100%' }} size="small">
                  <InputLabel id={`operator-label-${index}`}>Operador</InputLabel>
                  <Select
                    labelId={`operator-label-${index}`}
                    value={item.operator}
                    onChange={(e) => handleOperatorChange(index, e.target.value)}
                    label="Operador"
                  >
                    {item.field === 'source'
                      ? [
                          <MenuItem key="contains" value="contains">
                            Contiene
                          </MenuItem>,
                        ]
                      : [
                          <MenuItem key="contains" value="contains">
                            Contiene
                          </MenuItem>,
                          <MenuItem key="equals" value="equals">
                            Igual a
                          </MenuItem>,
                          <MenuItem key="notEquals" value="notEquals">
                            No es igual a
                          </MenuItem>,
                          <MenuItem key="startsWith" value="startsWith">
                            Comienza con
                          </MenuItem>,
                          <MenuItem key="endsWith" value="endsWith">
                            Termina con
                          </MenuItem>,
                          <MenuItem key="isEmpty" value="isEmpty">
                            Está vacío
                          </MenuItem>,
                          <MenuItem key="isNotEmpty" value="isNotEmpty">
                            No está vacío
                          </MenuItem>,
                          <MenuItem key="greaterThan" value="greaterThan">
                            Mayor a
                          </MenuItem>,
                          <MenuItem key="lessThan" value="lessThan">
                            Menor a
                          </MenuItem>,
                        ]}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3.5}>
                {item.field === 'source' ? (
                  <FormControl sx={{ width: '100%' }} size="small">
                    <InputLabel>Orígenes</InputLabel>
                    <Select
                      multiple
                      value={(item.value as string).length > 0 ? (item.value as string).split(',') : []}
                      onChange={(e) => handleValueChange(index, (e.target.value as string[]).join(','))}
                      label="Orígenes"
                    >
                      {sources.toSorted().map((source) => (
                        <MenuItem key={source} value={source}>
                          {source}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    label="Valor"
                    value={String(item.value) || ''}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    disabled={item.operator === 'isEmpty' || item.operator === 'isNotEmpty'}
                    fullWidth
                    size="small"
                  />
                )}
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  onClick={() => removeFilter(index)}
                  size="small"
                  sx={{
                    color: 'error.main',
                    '&:hover': {
                      backgroundColor: 'error.lighter',
                    },
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Box>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            startIcon={<AddIcon />}
            onClick={addFilter}
            variant="outlined"
            size="small"
            sx={{
              borderStyle: 'dashed',
              '&:hover': {
                borderStyle: 'dashed',
              },
            }}
          >
            Agregar filtro
          </Button>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose} variant="outlined" sx={{ mr: 1 }}>
          Cancelar
        </Button>
        <Tooltip title={getTooltipTitle()}>
          <span>
            <Button onClick={handleApply} disabled={isSomeItemEmpty} variant="contained">
              Aplicar
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
