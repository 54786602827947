import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useEditEvalMutation, useGetOneEvalsQuery, usePostEvalMutation } from 'services/api/evals.api';

interface Props {
  messageText: string;
  setOpen: (open: boolean) => void;
  messageId?: number;
  evalId?: number;
}

const EvalModal: FC<Props> = ({ messageText, setOpen, evalId, messageId }) => {
  const [score, setScore] = useState<number | null>(null);
  const [comment, setComment] = useState('');
  const [desiredResponse, setDesiredResponse] = useState('');
  const [showForm, setShowForm] = useState(false);

  const [postEval, { data: evalResponse }] = usePostEvalMutation();
  const [editEval, { data: evalEditResponse }] = useEditEvalMutation();

  const { data: evalToEdit } = evalId ? useGetOneEvalsQuery({ evalId }) : { data: null };

  useEffect(() => {
    if (evalToEdit) {
      setScore(evalToEdit.score);
      setComment(evalToEdit.comment || '');
      setDesiredResponse(evalToEdit.desiredResponse || '');
    }
  }, [evalToEdit]);

  if (!messageId && !evalId) {
    return null;
  }

  return (
    <Modal open onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          maxHeight: '95%',
        }}
      >
        {!showForm && messageId ? (
          <Box sx={{ display: 'flex', py: 10, justifyContent: 'center' }}>
            <Typography variant="h5" pr={2}>
              Crear Eval, elige una opción:
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to={`/conversationEvals?messageId=${messageId}`}
              >
                Crear Conversation Eval
              </Button>
              <Button variant="contained" color="primary" onClick={() => setShowForm(true)}>
                Crear Eval Normal
              </Button>
            </Stack>
          </Box>
        ) : (
          <Stack spacing={2} p={2}>
            {evalResponse || evalEditResponse ? (
              <>
                <Typography variant="h5">
                  Se ha {evalResponse ? 'guardado' : 'editado'} la evaluación con éxito!
                </Typography>
                <PrimaryBtn onClick={() => setOpen(false)}>Cerrar</PrimaryBtn>
              </>
            ) : (
              <>
                <Typography variant="h5">
                  Evaluar mensaje: <Typography variant="body1">{messageText}</Typography>
                </Typography>
                <FormControl>
                  <InputLabel>Score</InputLabel>
                  <Select value={score} label="Score" onChange={(e) => setScore(Number(e.target.value))}>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  value={desiredResponse}
                  onChange={(e) => setDesiredResponse(e.target.value)}
                  label="Respuesta ideal"
                  placeholder={messageText}
                  multiline
                  maxRows={10}
                />
                <TextField
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  label="Comentario de la respuesta"
                  multiline
                  maxRows={10}
                />
                <PrimaryBtn
                  disabled={score === null}
                  onClick={() => {
                    if (score !== null) {
                      if (evalToEdit && evalId) {
                        editEval({
                          evalId,
                          score,
                          comment,
                          desiredResponse,
                          // eslint-disable-next-line no-console
                        }).catch((e) => console.error(e));
                      } else if (messageId) {
                        postEval({
                          messageId,
                          score,
                          comment,
                          desiredResponse,
                          // eslint-disable-next-line no-console
                        }).catch((e) => console.error(e));
                      }
                    }
                  }}
                >
                  {evalToEdit && evalId ? 'Editar' : 'Guardar'}
                </PrimaryBtn>
              </>
            )}
          </Stack>
        )}
      </Paper>
    </Modal>
  );
};

EvalModal.defaultProps = {
  evalId: undefined,
  messageId: undefined,
};

export default EvalModal;
