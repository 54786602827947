import React, { FC, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useBusinessUnits } from 'contexts/BusinessUnitsContext';

interface Props {
  businessUnitId: number | null;
  setBusinessUnitId: (businessUnitId: number) => void;
  disableAllBusinessUnitsOption?: boolean;
  selectItemIfOnlyOne?: boolean;
  small?: boolean;
}

const BusinessUnitSelector: FC<Props> = ({
  businessUnitId,
  setBusinessUnitId,
  disableAllBusinessUnitsOption,
  selectItemIfOnlyOne,
  small,
}) => {
  const { businessUnits, getBusinessUnitsByCompany, getCompanyNames } = useBusinessUnits();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (selectItemIfOnlyOne && businessUnits && businessUnits.length === 1) {
      setBusinessUnitId(businessUnits[0].id);
    }
  }, [businessUnits, selectItemIfOnlyOne, setBusinessUnitId]);

  if (!businessUnits) {
    return null;
  }

  const menuItems = getCompanyNames()
    .sort((a, b) => a.localeCompare(b))
    .map((company) => {
      const companyBusinessUnits = getBusinessUnitsByCompany(company);
      // Need array render because you cant use a fragment inside a select
      return [
        <ListSubheader key={company} sx={{ textAlign: 'center', backgroundColor: '#f0f0f0' }}>
          <Typography fontWeight={500}>{company}</Typography>
        </ListSubheader>,
        companyBusinessUnits.map((businessUnit) => (
          <MenuItem key={businessUnit.id} value={businessUnit.id}>
            <Typography fontWeight={500}>{businessUnit.name}</Typography>
          </MenuItem>
        )),
      ];
    });

  return (
    <FormControl
      size={small ? 'small' : 'medium'}
      sx={{
        minWidth: { xs: '100%', sm: 180 },
        maxWidth: '100%',
        '& .MuiOutlinedInput-root': {
          height: small ? '40px' : '56px',
          borderRadius: '8px',
          backgroundColor: 'background.default',
        },
        '& .MuiSelect-select': {
          height: small ? '40px !important' : '56px !important',
          minHeight: small ? '40px !important' : '56px !important',
          display: 'flex',
          alignItems: 'center',
          py: 0,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .MuiInputLabel-root': {
          display: 'flex',
          alignItems: 'center',
          transform: small ? 'translate(14px, 9px)' : 'translate(14px, 17px)',
          '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: small ? 'translate(14px, -9px) scale(0.75)' : 'translate(14px, -9px) scale(0.75)',
          },
        },
        '& .MuiMenuItem-root': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .MuiListSubheader-root': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    >
      <InputLabel id="business-unit-select-label">Unidad de negocio</InputLabel>
      <Select
        labelId="business-unit-select-label"
        label="Unidad de negocio"
        value={businessUnitId || ''}
        onChange={(e) => setBusinessUnitId(e.target.value as number)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        {!disableAllBusinessUnitsOption && <MenuItem value="-1">Todas las unidades de negocio</MenuItem>}
        {menuItems}
      </Select>
    </FormControl>
  );
};

BusinessUnitSelector.defaultProps = {
  disableAllBusinessUnitsOption: false,
  selectItemIfOnlyOne: false,
  small: false,
};

export default BusinessUnitSelector;
