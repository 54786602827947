import { ExtendedLead } from 'services/types/lead';

import baseApi from './baseApi';

const leadsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLeads: build.query({
      query: (params: {
        businessUnitId: number;
        paginationModel: { page: number; pageSize: number };
        startDate: string | null;
        endDate: string | null;
        outboundCampaignId: string | null;
      }) => ({
        url: `/leads`,
        body: params,
        method: 'POST',
      }),
      transformResponse: (response: { rows: ExtendedLead[]; count: number }) => {
        return {
          leads: response.rows,
          total: response.count,
        };
      },
    }),
    getOutboundCampaignIds: build.query({
      query: (params: { businessUnitId: number; startDate: string | null; endDate: string | null }) => ({
        url: `/leads/outboundCampaignIds?businessUnitId=${params.businessUnitId}&startDate=${params.startDate}&endDate=${params.endDate}`,
        method: 'GET',
      }),
      transformResponse: (response: string[]) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetLeadsQuery, useGetOutboundCampaignIdsQuery } = leadsApi;
