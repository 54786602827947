import { Client } from './client';

export interface MessageToEval {
  role: 'user' | 'assistant' | 'tool';
  text: string | null;
  toolCalls?:
    | {
        id: string;
        type: 'function';
        function: {
          name: string;
          arguments: string;
        };
      }[]
    | null;
  toolCallId?: string | null;
  expected?: {
    responseDistance?: number;
    responseDescription: string;
  };
  key?: string;
}

export interface ConversationEval {
  id: number;
  createdAt: string;
  updatedAt: string;
  businessUnitIds: number[];
  mockClient: Partial<Client>;
  messages: MessageToEval[];
}

export type CreateConversationEvalRequest = Omit<ConversationEval, 'id' | 'createdAt' | 'updatedAt'>;
export type UpdateConversationEvalRequest = Partial<ConversationEval> & { conversationEvalId: number };

type ChatCompletionResponse = {
  completionMessageContent: string | null;
  toolCalls?: unknown[] | null;
  /* completionUsage?: OpenAI.Completions.CompletionUsage | null;
  model: AIModel; */
};

type EvalData = {
  businessUnitId: number;
  mockClient: Client;
  messages: unknown[];
  expected: {
    responseDistance?: number;
    expectedCompletionMessageContent?: string;
    expectedToolCalls?: {
      name: string;
      arguments: string;
    }[];
    responseDescription: string;
  };
};

export type RunConversationEvalResponse = {
  successCount: number;
  total: number;
  failures: {
    evalData: EvalData;
    response: ChatCompletionResponse;
    alignmentScore: number | undefined;
  }[];
  successes: {
    evalData: EvalData;
    response: ChatCompletionResponse;
  }[];
};

export function isRunConversationEvalError(
  error: unknown
): error is { data: RunConversationEvalResponse; status: number } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    'status' in error &&
    'failures' in (error.data as RunConversationEvalResponse)
  );
}
