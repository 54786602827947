import React, { createContext, useContext, ReactNode, useMemo } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetUserBusinessUnitsQuery } from 'services/api/user.api';
import { BusinessUnit } from 'services/types/businessUnit';

interface BusinessUnitsContextType {
  businessUnits: BusinessUnit[] | undefined;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  // Utility functions
  getBusinessUnitById: (id: number) => BusinessUnit | undefined;
  getBusinessUnitsByCompany: (companyName: string) => BusinessUnit[];
  getCompanyNames: () => string[];
}

const BusinessUnitsContext = createContext<BusinessUnitsContextType | undefined>(undefined);

export const BusinessUnitsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const currentUser = useCurrentUser();
  const {
    data: businessUnits,
    isLoading,
    error,
  } = useGetUserBusinessUnitsQuery(currentUser && currentUser.id ? null : skipToken);

  const value = useMemo(() => {
    // Utility functions
    const getBusinessUnitById = (id: number) => businessUnits?.find((bu) => bu.id === id);

    const getBusinessUnitsByCompany = (companyName: string) =>
      businessUnits?.filter((bu) => bu.company?.name === companyName) ?? [];

    const getCompanyNames = () =>
      Array.from(new Set(businessUnits?.map((bu) => bu.company?.name).filter(Boolean) ?? []));

    return {
      businessUnits,
      isLoading,
      error,
      getBusinessUnitById,
      getBusinessUnitsByCompany,
      getCompanyNames,
    };
  }, [businessUnits, isLoading, error]);

  return <BusinessUnitsContext.Provider value={value}>{children}</BusinessUnitsContext.Provider>;
};

export function useBusinessUnits() {
  const context = useContext(BusinessUnitsContext);
  if (context === undefined) {
    throw new Error('useBusinessUnits must be used within a BusinessUnitsProvider');
  }
  return context;
}
