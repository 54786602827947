import React, { useEffect, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Conversation from 'components/common/Conversation';
import { useGetRecommendedClientsMutation } from 'services/api/cars.api';
import { Car } from 'services/types/car';
import { Client } from 'services/types/client';

interface RecommendClientsModalProps {
  open: boolean;
  onClose: () => void;
  car: Car;
}

const LIMIT = 500;

const RecommendClientsModal: React.FC<RecommendClientsModalProps> = ({ open, onClose, car }) => {
  const [expandedClient, setExpandedClient] = useState<number | null>(null);
  const [clients, setClients] = useState<Client[]>([]);
  const [analyzedClientsCount, setAnalyzedClientsCount] = useState<number>(0);

  const [getRecommendedClients, { data, isLoading }] = useGetRecommendedClientsMutation();

  const fetchRecommendedClients = () => {
    getRecommendedClients({ carId: car.id, aiAnalysisLimit: LIMIT, pageIndex: analyzedClientsCount / LIMIT }).catch(
      (error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching clients', error);
      }
    );
  };

  useEffect(() => {
    setAnalyzedClientsCount(0);
    setClients([]);
    fetchRecommendedClients();
  }, [car.id]);

  useEffect(() => {
    if (data) {
      setAnalyzedClientsCount(analyzedClientsCount + data.analyzedClientsCount);
      setClients([...clients, ...data.recommendedClients]);
    }
  }, [data]);

  const handleOpenClientDetails = (clientId: number) => {
    window.open(`/clients/${clientId}`, '_blank');
  };

  const toggleExpandClient = (clientId: number) => {
    setExpandedClient(expandedClient === clientId ? null : clientId);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Recomendar Auto a Clientes</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="body1" gutterBottom>
            Buscar clientes que podrían estar interesados en:
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            {car.brand} {car.model} {car.version} ({car.year}) -{' '}
            {car.price.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}
          </Typography>
        </Box>

        {analyzedClientsCount > 0 && (
          <>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              {analyzedClientsCount > 0
                ? `${analyzedClientsCount} clientes analizados, ${clients.length} seleccionados`
                : 'No se encontraron clientes que coincidan con este vehículo'}
            </Typography>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {clients.map((client) => (
                <React.Fragment key={client.id}>
                  <ListItem
                    disablePadding
                    secondaryAction={
                      <>
                        <Button onClick={() => handleOpenClientDetails(client.id)} sx={{ mr: 1 }}>
                          Ver cliente
                        </Button>
                        <Button
                          onClick={() => toggleExpandClient(client.id)}
                          endIcon={expandedClient === client.id ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                        >
                          Ver mensajes
                        </Button>
                      </>
                    }
                  >
                    <ListItemButton onClick={() => handleOpenClientDetails(client.id)}>
                      <ListItemText
                        primary={`${
                          client.firstName ? `${client.firstName} ${client.lastName}` : `Cliente ${client.id}`
                        }`}
                        secondary={`Último mensaje: ${new Date(client.lastMessageDate).toLocaleDateString('es-CL')}`}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={expandedClient === client.id} timeout="auto" unmountOnExit>
                    <Box sx={{ pl: 4, pr: 2, py: 2, bgcolor: '#f5f5f5' }}>
                      {client.messages && client.messages.length > 0 ? (
                        <Conversation
                          conversation={client.messages}
                          isFilteredConversation={false}
                          fullHeight={false}
                        />
                      ) : (
                        <Typography variant="body2">No hay mensajes disponibles</Typography>
                      )}
                    </Box>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </>
        )}
        {isLoading && (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        )}
        <Box display="flex" justifyContent="center" mt={3}>
          <Button variant="outlined" onClick={fetchRecommendedClients} disabled={isLoading}>
            Buscar más clientes
          </Button>
        </Box>
        {data && data.analyzedClientsCount === 0 && (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2">No hay más clientes para analizar</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecommendClientsModal;
