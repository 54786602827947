import React, { useState, useEffect, useMemo } from 'react';

import { Box, Typography, CircularProgress } from '@mui/material';

import BusinessUnitSelector from 'components/common/BusinessUnitsSelector';
import OriginSelector from 'components/common/OriginSelector';
import { useBusinessUnits } from 'contexts/BusinessUnitsContext';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetBusinessUnitByIdMutation, useGetUserOriginsQuery } from 'services/api/user.api';
import type { BusinessUnit } from 'services/types/businessUnit';

const Reports = () => {
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(true);
  const [businessUnitId, setBusinessUnitId] = useState<number | null>(null);
  const [originId, setOriginId] = useState<number | null>(null);
  const { businessUnits } = useBusinessUnits();
  const [getBusinessUnitById, { data: businessUnitData }] = useGetBusinessUnitByIdMutation();
  const { data: origins } = useGetUserOriginsQuery(null);

  const selectedBusinessUnit = businessUnits?.find((bu): bu is BusinessUnit => bu.id === businessUnitId);
  const selectedOrigin = origins?.find((origin) => origin.id === originId);

  const reportUrl = useMemo(() => {
    if (currentUser?.isOriginAdmin) {
      return selectedOrigin?.lookerReportUrl || '';
    }

    if (businessUnitData?.type === 'financial_advisor') {
      if (originId === null) return '';
      if (originId === -1) return selectedBusinessUnit?.lookerReportUrl || '';
      return selectedOrigin?.lookerReportUrl || '';
    }

    return selectedBusinessUnit?.lookerReportUrl || '';
  }, [currentUser?.isOriginAdmin, selectedOrigin, selectedBusinessUnit, businessUnitData?.type, originId]);

  useEffect(() => {
    if (businessUnitId && businessUnitId !== -1) {
      getBusinessUnitById(businessUnitId).catch((err) => console.error(err)); // eslint-disable-line no-console
    }
  }, [businessUnitId]);

  useEffect(() => {
    setOriginId(null);
  }, [businessUnitId]);

  const shouldShowOriginSelector = useMemo(() => {
    if (currentUser?.isOriginAdmin) return true;
    return businessUnitData?.type === 'financial_advisor';
  }, [currentUser?.isOriginAdmin, businessUnitData?.type]);

  const getErrorMessage = () => {
    if (!businessUnitId) return null;

    if (currentUser?.isOriginAdmin) {
      if (!originId) return 'Por favor selecciona un origen para ver el reporte.';
      if (!selectedOrigin?.lookerReportUrl) return 'El reporte no está configurado actualmente para este origen.';
      return null;
    }

    if (businessUnitData?.type === 'financial_advisor') {
      if (originId === null) return 'Por favor selecciona un origen para ver el reporte.';
      if (originId === -1 && !selectedBusinessUnit?.lookerReportUrl) {
        return 'Esta unidad de negocio no tiene un reporte configurado.';
      }
      if (originId !== -1 && !selectedOrigin?.lookerReportUrl) {
        return 'El reporte no está configurado actualmente para este origen.';
      }
      return null;
    }

    if (!reportUrl) return 'Esta unidad de negocio no tiene un reporte configurado.';
    return null;
  };

  const errorMessage = getErrorMessage();

  return (
    <Box m={2}>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Typography variant="h4">Reportes</Typography>
        <BusinessUnitSelector
          businessUnitId={businessUnitId}
          setBusinessUnitId={setBusinessUnitId}
          disableAllBusinessUnitsOption
          selectItemIfOnlyOne
        />
        {shouldShowOriginSelector && (
          <OriginSelector
            originId={originId}
            setOriginId={setOriginId}
            selectItemIfOnlyOne={currentUser?.isOriginAdmin}
            showAllOption={!currentUser?.isOriginAdmin}
            businessUnitId={businessUnitId}
          />
        )}
      </Box>

      {errorMessage && <Typography color="error">{errorMessage}</Typography>}

      {isLoading && reportUrl && (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      )}

      {reportUrl && (
        <Box
          component="iframe"
          sx={{
            width: '100%',
            height: 'calc(100vh - 150px)',
            border: 0,
            display: isLoading ? 'none' : 'block',
          }}
          src={reportUrl || undefined}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          onLoad={() => setIsLoading(false)}
        />
      )}
    </Box>
  );
};

export default Reports;
