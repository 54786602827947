import React, { FC } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import BusinessUnitSelector from 'components/common/BusinessUnitsSelector';

interface Props {
  searchTerm: string;
  onSearchTermChange: (searchTerm: string) => void;
  businessUnitId?: number | null;
  setBusinessUnitId?: (businessUnitId: number) => void;
}

const SearchBar: FC<Props> = ({ searchTerm, onSearchTermChange, businessUnitId, setBusinessUnitId }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 1.5,
        alignItems: 'center',
        '& .MuiTextField-root': {
          minWidth: '200px',
          maxWidth: '280px',
        },
        '& .MuiOutlinedInput-root': {
          height: '40px',
          borderRadius: '8px',
          backgroundColor: 'background.default',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
          '&.Mui-focused': {
            backgroundColor: 'background.paper',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
              borderWidth: '2px',
            },
          },
        },
      }}
    >
      <TextField
        type="text"
        placeholder="Buscar"
        size="small"
        onChange={(e) => onSearchTermChange(e.target.value)}
        value={searchTerm}
        InputProps={{
          sx: {
            pr: 0.5,
            '& .MuiInputBase-input': {
              fontSize: '14px',
              fontWeight: 500,
              '&::placeholder': {
                color: 'text.secondary',
                opacity: 0.8,
              },
            },
          },
        }}
      />
      {setBusinessUnitId !== undefined && businessUnitId !== undefined && (
        <BusinessUnitSelector
          businessUnitId={businessUnitId}
          setBusinessUnitId={setBusinessUnitId}
          selectItemIfOnlyOne
          small
        />
      )}
    </Box>
  );
};

SearchBar.defaultProps = {
  businessUnitId: undefined,
  setBusinessUnitId: undefined,
};

export default SearchBar;
