import React from 'react';

import Box from '@mui/material/Box';

import useIsInsurance from 'hooks/useIsInsurance';

import Conversations from './components/Conversations';
import Features from './components/Features';
import FeaturesInsurance from './components/FeaturesInsurance';
import Footer from './components/Footer';
import Header from './components/Header';
import How from './components/How';
import Included from './components/Included';
import IncludedInsurance from './components/IncludedInsurance';
import InsuranceHeader from './components/InsuranceHeader';
import Navigation from './components/Navigation';

const App = () => {
  const isInsurance = useIsInsurance();

  if (isInsurance) {
    return (
      <Box sx={{ backgroundColor: '#F1F6FC' }}>
        <Navigation />
        <InsuranceHeader />
        <Conversations />
        <FeaturesInsurance />
        <IncludedInsurance />
        <Footer />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#F1F6FC' }}>
      <Navigation />
      <Header />
      <Conversations />
      <Features />
      <Included />
      <How />
      <Footer />
    </Box>
  );
};

export default App;
