import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useGetBusinessUnitsQuery } from 'services/api/businessUnits.api';
import {
  useDeleteConversationEvalMutation,
  useGetAllConversationEvalsQuery,
  useRunConversationEvalMutation,
} from 'services/api/conversationEvals.api';
import { ConversationEval, isRunConversationEvalError } from 'services/types/conversationEvals';

import ConversationEvalForm from './components/ConversationEvalForm';
import ViewConversationEval from './components/ViewConversationEval';

const ConversationEvals: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBusinessUnitIds, setSelectedBusinessUnitIds] = useState<number[]>([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(!!searchParams.get('messageId'));
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedEval, setSelectedEval] = useState<ConversationEval | null>(null);
  const [openRunDialog, setOpenRunDialog] = useState(false);

  const { data: businessUnits } = useGetBusinessUnitsQuery(null);
  const { data: conversationEvals, refetch } = useGetAllConversationEvalsQuery(
    selectedBusinessUnitIds.length ? { businessUnitIds: selectedBusinessUnitIds } : undefined
  );
  const [deleteConversationEval] = useDeleteConversationEvalMutation();
  const [
    runConversationEval,
    { isLoading: isRunningEval, data: runConversationEvalData, error: runConversationEvalError },
  ] = useRunConversationEvalMutation();

  const handleBusinessUnitChange = (event: SelectChangeEvent<number[]>) => {
    setSelectedBusinessUnitIds(event.target.value as number[]);
  };

  const removeQueryParams = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('messageId');
    setSearchParams(newSearchParams);
  };

  const handleOpenViewDialog = (conversationEval: ConversationEval) => {
    setSelectedEval(conversationEval);
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    setSelectedEval(null);
  };

  const handleOpenDeleteDialog = (conversationEval: ConversationEval) => {
    setSelectedEval(conversationEval);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedEval(null);
  };

  const handleDeleteConversationEval = async () => {
    if (selectedEval?.id) {
      try {
        await deleteConversationEval(selectedEval.id).unwrap();
        handleCloseDeleteDialog();
        await refetch();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to delete conversation eval:', error);
      }
    }
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setSelectedEval(null);
    removeQueryParams();
    refetch().catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Failed to refetch conversation evals:', error);
    });
  };

  const handleOpenEditDialog = (conversationEval: ConversationEval) => {
    setSelectedEval(conversationEval);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedEval(null);
    refetch().catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Failed to refetch conversation evals:', error);
    });
  };

  useEffect(() => {
    if (isRunningEval || !!runConversationEvalData || !!runConversationEvalError) {
      setOpenRunDialog(true);
    }
  }, [runConversationEvalData, runConversationEvalError, isRunningEval]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={2} alignItems="center" gap={2}>
        <Typography variant="h4">Conversation Evaluations</Typography>
        <Box display="flex" gap={2}>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel id="business-unit-select-label">Business Units</InputLabel>
            <Select
              labelId="business-unit-select-label"
              multiple
              value={selectedBusinessUnitIds}
              onChange={handleBusinessUnitChange}
              label="Business Units"
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const businessUnit = businessUnits?.find((bu) => bu.id === value);
                    return businessUnit ? <Chip key={value} label={businessUnit.name} size="small" /> : null;
                  })}
                </Box>
              )}
            >
              {businessUnits?.map((businessUnit) => (
                <MenuItem key={businessUnit.id} value={businessUnit.id}>
                  {businessUnit.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={() => setOpenCreateDialog(true)}>
            Crear nuevo Chat Evaluation
          </Button>
        </Box>
      </Box>

      <TableContainer
        sx={{ height: 'calc(100vh - 180px)', overflow: 'auto', backgroundColor: 'white', borderRadius: 4, p: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Mock Client</TableCell>
              <TableCell>Business Units</TableCell>
              <TableCell>Cantidad de Mensajes</TableCell>
              <TableCell>Fecha de Creación</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conversationEvals?.map((conversationEval) => (
              <TableRow key={conversationEval.id}>
                <TableCell>{conversationEval.id}</TableCell>
                <TableCell>
                  {`${conversationEval.mockClient.firstName || ''} ${
                    conversationEval.mockClient.lastName || ''
                  }`.trim() || 'N/A'}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {conversationEval.businessUnitIds.map((buId) => {
                      const businessUnit = businessUnits?.find((bu) => bu.id === buId);
                      return businessUnit ? (
                        <Chip key={buId} label={businessUnit.name} size="small" />
                      ) : (
                        <Chip key={buId} label={`BU ${buId}`} size="small" />
                      );
                    })}
                  </Box>
                </TableCell>
                <TableCell>{conversationEval.messages.length}</TableCell>
                <TableCell>{new Date(conversationEval.createdAt || '').toLocaleString()}</TableCell>
                <TableCell>
                  <Box display="flex">
                    <Tooltip title="Ejecutar">
                      <IconButton
                        onClick={() => {
                          runConversationEval(conversationEval.id).catch((e) => {
                            // eslint-disable-next-line no-console
                            console.error('Failed to run conversation eval:', e);
                          });
                        }}
                      >
                        <PlayCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ver">
                      <IconButton onClick={() => handleOpenViewDialog(conversationEval)}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton onClick={() => handleOpenEditDialog(conversationEval)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <IconButton onClick={() => handleOpenDeleteDialog(conversationEval)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Create Dialog */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} maxWidth="md" fullWidth>
        <DialogTitle>Crear Nueva Chat Evaluation</DialogTitle>
        <DialogContent>
          <ConversationEvalForm
            onClose={handleCloseCreateDialog}
            messageId={searchParams.get('messageId') ? Number(searchParams.get('messageId')) : undefined}
          />
        </DialogContent>
      </Dialog>

      {/* View Dialog */}
      <Dialog open={openViewDialog} onClose={handleCloseViewDialog} maxWidth="md" fullWidth>
        <DialogContent>{selectedEval && <ViewConversationEval conversationEval={selectedEval} />}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth="md" fullWidth>
        <DialogTitle>Editar Chat Evaluation</DialogTitle>
        <DialogContent>
          {selectedEval && <ConversationEvalForm conversationEval={selectedEval} onClose={handleCloseEditDialog} />}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Eliminar Chat Evaluation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro de que desea eliminar esta evaluación de conversación? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
          <Button
            onClick={() => {
              handleDeleteConversationEval().catch((e) => {
                // eslint-disable-next-line no-console
                console.error('Failed to delete conversation eval:', e);
              });
            }}
            color="error"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Run Dialog */}
      <Dialog open={openRunDialog} onClose={() => setOpenRunDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Ejecutar Chat Evaluation</DialogTitle>
        <DialogContent>
          {isRunningEval && (
            <>
              <Typography>Ejecutando...</Typography>
              <CircularProgress />
            </>
          )}
          {isRunConversationEvalError(runConversationEvalError) && (
            <>
              <Typography>Evals fallidos:</Typography>
              {runConversationEvalError.data.failures.map((failure, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', padding: 10, borderRadius: 10, marginBottom: 15 }}
                >
                  <Typography>Alignment Score: {failure.alignmentScore}</Typography>
                  <br />
                  <Typography>Expected:</Typography>
                  <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                    {JSON.stringify(failure.evalData.expected, null, 2)}
                  </div>
                  <br />
                  <Typography>Actual:</Typography>
                  <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                    {JSON.stringify(failure.response, null, 2)}
                  </div>
                </div>
              ))}
            </>
          )}
          {runConversationEvalData && (
            <>
              <Typography>Ejecución completada</Typography>
              <Typography>
                Mensajes evaluados con éxito: {runConversationEvalData.successCount} de {runConversationEvalData.total}
              </Typography>
              {runConversationEvalData.failures.length > 0 && (
                <>
                  <Typography>Evals fallidos:</Typography>
                  {runConversationEvalData.failures.map((failure, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 15,
                      }}
                    >
                      <Typography>Alignment Score: {failure.alignmentScore}</Typography>
                      <Typography>Expected:</Typography>
                      <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                        {JSON.stringify(failure.evalData.expected, null, 2)}
                      </div>
                      <br />
                      <Typography>Actual:</Typography>
                      <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                        {JSON.stringify(failure.response, null, 2)}
                      </div>
                    </div>
                  ))}
                </>
              )}

              {runConversationEvalData.successes.length > 0 && (
                <>
                  <Typography>Respuestas de mensajes evaluados con éxito:</Typography>
                  {runConversationEvalData.successes.map((data, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 15,
                      }}
                    >
                      <Typography>Respuesta esperada:</Typography>
                      <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                        {JSON.stringify(data.evalData.expected, null, 2)}
                      </div>
                      <br />
                      <Typography>Respuesta actual:</Typography>
                      <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                        {JSON.stringify(data.response, null, 2)}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ConversationEvals;
