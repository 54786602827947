import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import CloudDone from '@mui/icons-material/CloudDone';
import Feedback from '@mui/icons-material/Feedback';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import SearchBar from 'components/UI/SearchBar';
import { useGetClientsListQuery } from 'services/api/clients.api';
import { ClientStatus } from 'services/types/client';
import { MessageSender } from 'services/types/message';

const ClientsList: FC = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const querySearchTerm = queryParams.get('searchTerm');
  const highlightedClientId = queryParams.get('highlightedClientId') || '';

  const listRef = useRef<FixedSizeList>(null);

  const [searchTerm, setSearchTerm] = useState<string>(querySearchTerm || '');
  const [filter, setFilter] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<ClientStatus | 'all'>('all');

  const { data: clients } = useGetClientsListQuery({ searchTerm });
  const [filteredClients, setFilteredClients] = useState(clients);

  const scrollToHighlightedRow = () => {
    if (!highlightedClientId || !filteredClients) return;
    const rowIndex = filteredClients?.findIndex((client) => client.id.toString() === highlightedClientId);
    if (!rowIndex || rowIndex < 5 || filteredClients.length < 5) return;
    listRef.current?.scrollToItem(rowIndex, 'center');
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value);
  };

  const handleFilterStatusChange = (event: SelectChangeEvent<string>) => {
    setFilterStatus(event.target.value as ClientStatus);
  };

  const getMessageSender = (client: { lastMessageSender: MessageSender; lastMessageUserId: number }) => {
    if (client.lastMessageSender === 'system' || client.lastMessageSender === 'none') {
      return 'Bot';
    }
    if (client.lastMessageUserId) {
      return 'Tú';
    }
    return 'Cliente';
  };

  const updateFilteredClients = () => {
    let filtered = clients || [];
    if (filter === 'disabledIA') {
      filtered = filtered.filter((client) => client.disabledAutomaticResponses);
    } else if (filter === 'withAlerts') {
      filtered = filtered.filter((client) => client.alertId);
    } else if (filter === 'waitingAnswer') {
      filtered = filtered.filter(
        (client) => client.disabledAutomaticResponses && getMessageSender(client) === 'Cliente'
      );
    }

    if (filterStatus && filterStatus !== 'all') {
      if (filterStatus === 'open') {
        filtered = filtered.filter((client) => client.status === 'open' || !client.status);
      } else {
        filtered = filtered.filter((client) => client.status === filterStatus);
      }
    }
    setFilteredClients(filtered);
  };

  useEffect(() => {
    updateFilteredClients();
  }, [filter, filterStatus]);

  useEffect(() => {
    setTimeout(() => {
      scrollToHighlightedRow();
    }, 400);
    updateFilteredClients();
  }, [clients]);

  useEffect(() => {
    queryParams.set('searchTerm', searchTerm);
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [searchTerm]);

  const navigateToClientId = (id: number, newPage = false) => {
    queryParams.set('highlightedClientId', id.toString());
    if (newPage) {
      window.open(`/clients/${id}`, '_blank');
      return;
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
    navigate(`/clients/${id}`);
  };

  const getDateString = (dateToFormat: Date) => {
    const today = new Date();
    const date = new Date(dateToFormat);
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
    }
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.toDateString() === yesterday.toDateString()) {
      return 'Ayer';
    }
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - 7);
    startOfWeek.setHours(0, 0, 0, 0);

    if (date >= startOfWeek) {
      const day = date.toLocaleDateString('es-ES', { weekday: 'long' });
      return day.charAt(0).toUpperCase() + day.slice(1);
    }

    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  const renderRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const client = filteredClients?.[index];
    if (!client) return <>Item vacío</>;
    const messageSender = getMessageSender(client);

    return (
      <div style={style} id={client.id.toString()}>
        <ListItemButton
          selected={highlightedClientId === client.id.toString()}
          onClick={() => navigateToClientId(client.id)}
        >
          <Box display="flex" width="100%">
            <Box pr={1} pt={1}>
              {client.disabledAutomaticResponses && messageSender === 'Cliente' && (
                <Badge variant="dot" color="primary">
                  <SpeakerNotesOffIcon />
                </Badge>
              )}
              {client.disabledAutomaticResponses && messageSender !== 'Cliente' && <SpeakerNotesOffIcon />}
              {client.lastCRMUpdate && <CloudDone />}
              {client.alertId && <Feedback />}
            </Box>
            <ListItemText
              primary={
                <Typography fontSize="17px">
                  {client.firstName ? `${client.firstName} ${client.lastName}` : client.phone}
                </Typography>
              }
              secondary={
                <Typography fontSize="15px" height="3em" overflow="hidden">
                  {messageSender}: {client.lastMessageText}
                </Typography>
              }
            />
            <Box justifyItems="center">
              <Typography fontSize="15px" pb={1}>
                {getDateString(client.lastMessageDate)}
              </Typography>
              {(client.status === 'open' || !client.status) && <CircleIcon sx={{ color: 'primary.main' }} />}
              {client.status === 'pending' && <CircleIcon sx={{ color: '#F9D00A' }} />}
              {client.status === 'success-closed' && <CheckCircleIcon sx={{ color: 'success.main' }} />}
              {client.status === 'failed-closed' && <CancelIcon sx={{ color: 'error.main' }} />}
            </Box>
          </Box>
        </ListItemButton>
        <Divider />
      </div>
    );
  };

  return (
    <Box height="100%">
      <Typography variant="h5" textAlign="center" pt={2}>
        Clientes
      </Typography>
      <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
        <SearchBar searchTerm={searchTerm} onSearchTermChange={setSearchTerm} />
        <Box>
          <FormControl sx={{ minWidth: 250, paddingRight: 1 }}>
            <InputLabel id="select-filter">Filtro</InputLabel>
            <Select labelId="select-filter" value={filter} label="Filtro" onChange={handleFilterChange}>
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="disabledIA">IA deshabilitada</MenuItem>
              <MenuItem value="withAlerts">Con alertas</MenuItem>
              <MenuItem value="waitingAnswer">Esperando respuesta</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box py={1}>
          <FormControl sx={{ minWidth: 250, paddingRight: 1 }}>
            <InputLabel id="select-filter-status">Estado</InputLabel>
            <Select
              labelId="select-filter-status"
              value={filterStatus || ''}
              label="Estado"
              onChange={handleFilterStatusChange}
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="open">Abiertas</MenuItem>
              <MenuItem value="pending">Pendientes</MenuItem>
              <MenuItem value="success-closed">Cerradas Exitosas</MenuItem>
              <MenuItem value="failed-closed">Cerradas Fallidas</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {filteredClients && filteredClients?.length > 0 && (
        <FixedSizeList height={500} width="100%" itemSize={100} itemCount={filteredClients?.length} ref={listRef}>
          {renderRow}
        </FixedSizeList>
      )}
    </Box>
  );
};

export default ClientsList;
