import React from 'react';

import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { useBusinessUnits } from 'contexts/BusinessUnitsContext';

interface ChatSelectorProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ChatSelector: React.FC<ChatSelectorProps> = ({ anchorEl, onClose }) => {
  const { businessUnits, getBusinessUnitsByCompany, getCompanyNames } = useBusinessUnits();

  if (!businessUnits || !anchorEl) {
    return null;
  }

  if (businessUnits.length === 1) {
    window.open(`/demochat/${businessUnits[0].pusherIdentification}`, '_blank');
    onClose();
    return null;
  }

  const menuItems = getCompanyNames()
    .sort((a, b) => a.localeCompare(b))
    .map((company) => {
      const companyBusinessUnits = getBusinessUnitsByCompany(company);
      return [
        <ListSubheader key={company} sx={{ textAlign: 'center', backgroundColor: '#f0f0f0' }}>
          <Typography fontWeight={500}>{company}</Typography>
        </ListSubheader>,
        ...companyBusinessUnits.map((businessUnit) => (
          <MenuItem
            key={businessUnit.id}
            onClick={() => {
              window.open(`/demochat/${businessUnit.pusherIdentification}`, '_blank');
              onClose();
            }}
          >
            <Typography fontWeight={500}>{businessUnit.name}</Typography>
          </MenuItem>
        )),
      ];
    });

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {menuItems}
    </Menu>
  );
};

export default ChatSelector;
