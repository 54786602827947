import React, { FC, useState, useMemo, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useCreateBusinessUnitTemplateMutation } from 'services/api/businessUnits.api';
import type { TemplateCreate } from 'services/api/businessUnits.api';

// Common emojis matrix
const COMMON_EMOJIS = [
  ['😊', '👍', '🎉', '❤️', '🙏', '✨', '💪', '🤝'],
  ['👋', '🌟', '💯', '🔥', '👏', '🎯', '✅', '🚗'],
  ['📱', '💼', '📊', '📈', '🎁', '🛒', '💰', '📝'],
  ['🔔', '⏰', '📅', '🌈', '🚀', '🏆', '💡', '🔍'],
];
interface Props {
  businessUnitId: number;
}

const MAX_BUTTON_LENGTH = 25;
const MAX_VARIABLE_LENGTH = 25;

const CreateTemplateForm: FC<Props> = ({ businessUnitId }) => {
  const [createTemplate, { isLoading, isSuccess, isError }] = useCreateBusinessUnitTemplateMutation();
  const [headerEnabled, setHeaderEnabled] = useState(false);
  const [footerEnabled, setFooterEnabled] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [newTemplate, setNewTemplate] = useState<TemplateCreate>({
    name: '',
    category: 'MARKETING',
    language: 'es',
    components: [
      { type: 'HEADER', text: '' },
      { type: 'BODY', text: '' },
      { type: 'FOOTER', text: '' },
      { type: 'BUTTONS', buttons: [{ type: 'QUICK_REPLY', text: 'Desactivar marketing' }] },
    ],
  });
  const [bodyVariableExamples, setBodyVariableExamples] = useState<string[]>([]);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState<HTMLButtonElement | null>(null);

  const transformTemplateName = (name: string) => {
    return name.toLowerCase().replace(/[^a-z0-9]/g, '_');
  };

  const addVariable = () => {
    const bodyComponent = newTemplate.components.find((c) => c.type === 'BODY') as {
      type: 'BODY';
      text: string;
      example?: { body_text: string[][] };
    };

    if (bodyComponent) {
      const currentVarCount = (bodyComponent.text.match(/{{[1-9]}}/g) || []).length;
      bodyComponent.text += `{{${currentVarCount + 1}}} `;
      setBodyVariableExamples([...bodyVariableExamples, '']);
      setNewTemplate({ ...newTemplate });

      // Focus the input again after adding a variable
      setTimeout(() => {
        const bodyInput = document.querySelector('textarea[name="BODY"]') as HTMLTextAreaElement;
        if (bodyInput) {
          bodyInput.focus();
          bodyInput.selectionStart = bodyInput.value.length;
          bodyInput.selectionEnd = bodyInput.value.length;
        }
      }, 0);
    }
  };

  const handleExampleChange = (index: number, value: string) => {
    const newExamples = [...bodyVariableExamples];
    newExamples[index] = value;
    setBodyVariableExamples(newExamples);
  };

  const removeVariable = (indexToRemove: number) => {
    const bodyComponent = newTemplate.components.find((c) => c.type === 'BODY') as {
      type: 'BODY';
      text: string;
      example?: { body_text: string[][] };
    };

    if (bodyComponent) {
      // Remove the variable from the text and adjust remaining variables
      let newText = bodyComponent.text.replaceAll(`{{${indexToRemove + 1}}}`, '');

      // Adjust the remaining variable numbers
      for (let i = indexToRemove + 1; i <= bodyVariableExamples.length - 1; i += 1) {
        newText = newText.replaceAll(`{{${i + 1}}}`, `{{${i}}}`);
      }
      // Remove the last variable
      newText = newText.replaceAll(`{{${bodyVariableExamples.length}}}`, '');

      bodyComponent.text = newText;

      // Remove the example and update state
      const newExamples = bodyVariableExamples.filter((_, index) => index !== indexToRemove);
      setBodyVariableExamples(newExamples);
      setNewTemplate({ ...newTemplate });
    }
  };

  const removeButton = (buttonIndex: number) => {
    const newComponents = [...newTemplate.components];
    const buttonsComponent = newComponents.find((c) => c.type === 'BUTTONS') as {
      type: 'BUTTONS';
      buttons: Array<{ type: 'QUICK_REPLY'; text: string }>;
    };

    if (buttonsComponent) {
      buttonsComponent.buttons = buttonsComponent.buttons.filter((_, index) => index !== buttonIndex);
      setNewTemplate({ ...newTemplate, components: newComponents });
    }
  };

  const handleNewTemplateSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const components = newTemplate.components
      .filter((component) => {
        if (component.type === 'BODY') {
          return true;
        }
        if (component.type === 'BUTTONS' && component.buttons.length === 0) {
          return false;
        }
        if (component.type === 'HEADER' && !headerEnabled) {
          return false;
        }
        if (component.type === 'FOOTER' && !footerEnabled) {
          return false;
        }
        return true;
      })
      .map((component) => {
        if (component.type === 'BODY') {
          return {
            ...component,
            example:
              bodyVariableExamples.length > 0
                ? {
                    body_text: [bodyVariableExamples],
                  }
                : undefined,
          };
        }
        if (component.type === 'HEADER') {
          return {
            ...component,
            format: 'TEXT',
          };
        }
        return component;
      });

    const templateToCreate = {
      ...newTemplate,
      components,
    };

    createTemplate({
      businessUnitId,
      template: templateToCreate,
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
  };

  // Handle template creation success
  useEffect(() => {
    if (!isSuccess) return () => {};

    setShowSuccess(true);
    const timer = setTimeout(() => {
      window.location.reload();
    }, 15000);

    return () => clearTimeout(timer);
  }, [isSuccess]);

  // Handle template creation error
  useEffect(() => {
    if (isError) {
      // eslint-disable-next-line no-console
      console.error('Error creating template');
      setShowError(true);
    }
  }, [isError]);

  const getPlaceholderText = (type: string) => {
    if (type === 'HEADER') return 'Título';
    if (type === 'FOOTER') return 'Pie de página';
    return undefined;
  };

  const getHelperText = (type: string) => {
    if (type === 'HEADER') return 'Se verá en negrita y más grande al inicio del mensaje';
    if (type === 'FOOTER') return 'Se verá en gris claro, sirve para poner el nombre de la empresa o persona';
    return undefined;
  };

  const validateBodyVariables = (text: string): boolean => {
    // Count variables in text
    const variables = text.match(/{{[1-9]}}/g) || [];
    if (variables.length === 0) return true;

    // Count total words in the text
    const words = text.trim().split(/\s+/);

    // Need at least 4 words per variable
    return words.length >= variables.length * 4;
  };

  const isFormValid = useMemo(() => {
    // Check if name is empty
    if (!newTemplate.name) return false;
    if (!newTemplate.name.match(/^[a-z_]{5,40}$/)) return false;

    const bodyComponent = newTemplate.components[1];
    if (bodyComponent.type !== 'BODY' || !bodyComponent.text) return false;

    // Check if body has enough words per variable
    if (!validateBodyVariables(bodyComponent.text)) return false;

    // Check if any variable example is empty
    if (bodyVariableExamples.some((example) => !example)) return false;

    // Check if any button text is empty
    const buttonsComponent = newTemplate.components[3];
    if (buttonsComponent.type !== 'BUTTONS') return false;

    for (let i = 0; i < buttonsComponent.buttons.length; i += 1) {
      if (!buttonsComponent.buttons[i].text) {
        return false;
      }
    }

    return true;
  }, [newTemplate, bodyVariableExamples]);

  const getPreviewText = (text: string): string => {
    let previewText = text;
    bodyVariableExamples.forEach((example, index) => {
      previewText = previewText.replace(`{{${index + 1}}}`, example || `{{${index + 1}}}`);
    });
    return previewText;
  };

  const handleFormat = (char: string) => {
    const textarea = document.querySelector('textarea[name="BODY"]') as HTMLTextAreaElement;
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const newText = text.substring(0, start) + char + text.substring(start, end) + char + text.substring(end);

      const newComponents = [...newTemplate.components];
      const bodyComponent = newComponents.find((c) => c.type === 'BODY');
      if (bodyComponent && 'text' in bodyComponent) {
        bodyComponent.text = newText;
        setNewTemplate({ ...newTemplate, components: newComponents });

        // Set cursor position between the formatting characters
        setTimeout(() => {
          textarea.focus();
          textarea.setSelectionRange(start + 1, end + 1);
        }, 0);
      }
    }
  };

  const handleEmojiClick = (emoji: string) => {
    const textarea = document.querySelector('textarea[name="BODY"]') as HTMLTextAreaElement;
    if (textarea) {
      const start = textarea.selectionStart;
      const text = textarea.value;
      const newText = text.substring(0, start) + emoji + text.substring(start);

      const newComponents = [...newTemplate.components];
      const bodyComponent = newComponents.find((c) => c.type === 'BODY');
      if (bodyComponent && 'text' in bodyComponent) {
        bodyComponent.text = newText;
        setNewTemplate({ ...newTemplate, components: newComponents });

        // Set cursor position after the emoji
        setTimeout(() => {
          textarea.focus();
          const newPosition = start + emoji.length;
          textarea.setSelectionRange(newPosition, newPosition);
        }, 0);
      }
    }
    setEmojiAnchorEl(null);
  };

  const formatPreviewText = (text: string): JSX.Element => {
    // Split by bold and italic markers while preserving them
    const parts = text.split(/(\*[^*]+\*|_[^_]+_)/g);

    return (
      <>
        {parts.map((part, i) => {
          const key = `${i}-${part}`;
          if (part.startsWith('*') && part.endsWith('*')) {
            return <strong key={key}>{part.slice(1, -1)}</strong>;
          }
          if (part.startsWith('_') && part.endsWith('_')) {
            return <em key={key}>{part.slice(1, -1)}</em>;
          }
          return <span key={key}>{part}</span>;
        })}
      </>
    );
  };

  return (
    <Box p={2} maxWidth={1400} mx="auto">
      <Snackbar
        open={showSuccess}
        autoHideDuration={15000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          border: '1px solid',
        }}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
          ¡Template creado exitosamente! El template será revisado por WhatsApp para su aprobación. Este proceso suele
          ser automático y rápido si es que cumple las políticas de WhatsApp. La página se actualizará en unos segundos.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          border: '1px solid',
        }}
      >
        <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
          Ha ocurrido un error al crear el template. Por favor, inténtalo nuevamente o contacta a soporte.
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <form onSubmit={handleNewTemplateSubmit}>
            <TextField
              autoFocus
              label="Nombre del template"
              value={newTemplate.name}
              onChange={(e) => setNewTemplate({ ...newTemplate, name: transformTemplateName(e.target.value) })}
              required
              fullWidth
              sx={{ mb: 2 }}
              error={newTemplate.name.length < 5 || newTemplate.name.length > 40}
              helperText={
                newTemplate.name.length < 5 || newTemplate.name.length > 40
                  ? 'El nombre debe tener entre 5 y 40 caracteres y solo puede contener letras minúsculas y guiones bajos'
                  : ''
              }
            />

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Categoría</InputLabel>
              <Select
                value={newTemplate.category}
                label="Categoría"
                onChange={(e) =>
                  setNewTemplate({ ...newTemplate, category: e.target.value as 'UTILITY' | 'MARKETING' })
                }
              >
                <MenuItem value="UTILITY">Utility</MenuItem>
                <MenuItem value="MARKETING">Marketing</MenuItem>
              </Select>
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                Utility: Solo para temas específicos como recordatorios de visitas, encuesta de satisfacción, etc.
                <br />
                Marketing: Para todo el resto de los casos en que se busca convencer al cliente de comprar/contratar un
                servicio.
              </Typography>
            </FormControl>

            <Paper
              elevation={0}
              sx={{
                p: 2,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 3,
                mb: 2,
              }}
            >
              {newTemplate.components.map((component, index) => {
                if (component.type === 'BUTTONS') {
                  return (
                    <Box key={component.type}>
                      <Typography variant="subtitle1" fontWeight={500} mb={1}>
                        Botones
                      </Typography>
                      <Stack spacing={1}>
                        {component.buttons.map((button, buttonIndex) => (
                          <Box key={`${buttonIndex + 1}`} display="flex" alignItems="center" gap={1}>
                            <TextField
                              label={`Botón ${buttonIndex + 1}`}
                              value={button.text}
                              onChange={(e) => {
                                const value = e.target.value.slice(0, MAX_BUTTON_LENGTH);
                                const newComponents = [...newTemplate.components];
                                const buttonsComponent = newComponents[index] as {
                                  type: 'BUTTONS';
                                  buttons: Array<{ type: 'QUICK_REPLY'; text: string }>;
                                };
                                buttonsComponent.buttons[buttonIndex] = { ...button, text: value };
                                setNewTemplate({ ...newTemplate, components: newComponents });
                              }}
                              fullWidth
                              inputProps={{ maxLength: MAX_BUTTON_LENGTH }}
                              helperText={`${button.text.length}/${MAX_BUTTON_LENGTH}`}
                            />
                            <Button onClick={() => removeButton(buttonIndex)} color="error" sx={{ minWidth: 'auto' }}>
                              <DeleteIcon />
                            </Button>
                          </Box>
                        ))}
                        {component.buttons.length < 3 && (
                          <Button
                            variant="outlined"
                            onClick={() => {
                              const newComponents = [...newTemplate.components];
                              const buttonsComponent = newComponents[index] as {
                                type: 'BUTTONS';
                                buttons: Array<{ type: 'QUICK_REPLY'; text: string }>;
                              };
                              if (buttonsComponent.buttons.length < 3) {
                                buttonsComponent.buttons.push({ type: 'QUICK_REPLY', text: '' });
                                setNewTemplate({ ...newTemplate, components: newComponents });
                              }
                            }}
                            startIcon={<AddIcon />}
                            sx={{
                              borderRadius: 2,
                              textTransform: 'none',
                            }}
                          >
                            Agregar Botón
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  );
                }

                if (component.type === 'BODY') {
                  return (
                    <Box key={component.type} mb={2}>
                      <Typography variant="subtitle2" mb={1}>
                        {component.type}
                      </Typography>
                      <TextField
                        multiline
                        name={component.type}
                        rows={3}
                        value={component.text}
                        onChange={(e) => {
                          const newComponents = [...newTemplate.components];
                          newComponents[index] = { ...component, text: e.target.value };
                          setNewTemplate({ ...newTemplate, components: newComponents });
                        }}
                        fullWidth
                        placeholder={getPlaceholderText(component.type)}
                        helperText={
                          !validateBodyVariables(component.text) && component.text.includes('{{')
                            ? 'Debe haber al menos 4 palabras por cada variable en el texto'
                            : getHelperText(component.type)
                        }
                        error={!validateBodyVariables(component.text) && component.text.includes('{{')}
                      />
                      <Box
                        sx={{
                          mt: 1,
                          display: 'flex',
                        }}
                      >
                        <Tooltip title="Negrita">
                          <IconButton size="small" onClick={() => handleFormat('*')}>
                            <FormatBoldIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cursiva">
                          <IconButton size="small" onClick={() => handleFormat('_')}>
                            <FormatItalicIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Emoji">
                          <IconButton size="small" onClick={(e) => setEmojiAnchorEl(e.currentTarget)}>
                            <InsertEmoticonIcon />
                          </IconButton>
                        </Tooltip>
                        <Popover
                          open={Boolean(emojiAnchorEl)}
                          anchorEl={emojiAnchorEl}
                          onClose={() => setEmojiAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Grid spacing={1}>
                            {COMMON_EMOJIS.map((row) => (
                              <Grid container item key={row[0]} spacing={1}>
                                {row.map((emoji) => (
                                  <Grid item key={emoji}>
                                    <IconButton
                                      size="small"
                                      onClick={() => handleEmojiClick(emoji)}
                                      sx={{
                                        fontSize: '1.5rem',
                                      }}
                                    >
                                      {emoji}
                                    </IconButton>
                                  </Grid>
                                ))}
                              </Grid>
                            ))}
                          </Grid>
                        </Popover>
                        <Button
                          variant="outlined"
                          onClick={addVariable}
                          size="small"
                          startIcon={<AddIcon />}
                          sx={{ ml: 'auto' }}
                        >
                          Agregar variable
                        </Button>
                      </Box>
                      {bodyVariableExamples.length > 0 && (
                        <Box mt={1.5}>
                          <Typography variant="subtitle2" fontWeight={500} mb={0.5}>
                            Ejemplos de variables:
                          </Typography>
                          <Stack spacing={1.5}>
                            {bodyVariableExamples.map((example, varIndex) => (
                              <Box key={`${varIndex + 1}`} display="flex" alignItems="center" gap={1}>
                                <TextField
                                  label={`Variable {{${varIndex + 1}}}`}
                                  value={example}
                                  onChange={(e) => {
                                    const value = e.target.value.slice(0, MAX_VARIABLE_LENGTH);
                                    handleExampleChange(varIndex, value);
                                  }}
                                  fullWidth
                                  size="small"
                                  inputProps={{ maxLength: MAX_VARIABLE_LENGTH }}
                                  helperText={
                                    !example
                                      ? 'El ejemplo no puede estar vacío'
                                      : `${example.length}/${MAX_VARIABLE_LENGTH}`
                                  }
                                  error={!example}
                                />
                                <Button
                                  onClick={() => removeVariable(varIndex)}
                                  color="error"
                                  sx={{ minWidth: 'auto' }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Box>
                            ))}
                          </Stack>
                        </Box>
                      )}
                    </Box>
                  );
                }

                return (
                  <Box key={component.type} mb={2}>
                    {component.type === 'HEADER' && (
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="subtitle2">HEADER</Typography>
                        <Switch
                          checked={headerEnabled}
                          onChange={(e) => setHeaderEnabled(e.target.checked)}
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      </Box>
                    )}
                    {component.type === 'FOOTER' && (
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="subtitle2">FOOTER</Typography>
                        <Switch
                          checked={footerEnabled}
                          onChange={(e) => setFooterEnabled(e.target.checked)}
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      </Box>
                    )}
                    {((component.type === 'HEADER' && headerEnabled) ||
                      (component.type === 'FOOTER' && footerEnabled)) && (
                      <TextField
                        name={component.type}
                        value={component.text}
                        onChange={(e) => {
                          const newComponents = [...newTemplate.components];
                          newComponents[index] = { ...component, text: e.target.value };
                          setNewTemplate({ ...newTemplate, components: newComponents });
                        }}
                        fullWidth
                        placeholder={getPlaceholderText(component.type)}
                        helperText={getHelperText(component.type)}
                      />
                    )}
                  </Box>
                );
              })}
            </Paper>

            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={!isFormValid || isLoading}
              sx={{
                mt: 1.5,
                borderRadius: 2,
                textTransform: 'none',
                fontSize: '1rem',
              }}
            >
              {isLoading ? 'Creando Template...' : 'Crear Template'}
            </Button>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Después de crear el template, podrás utilizarlo para enviar mensajes a tus clientes desde la pestaña
              &quot;Enviar Template&quot;.
            </Typography>
          </form>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 3,
              position: 'sticky',
              top: 16,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Vista Previa
            </Typography>
            <Box
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: 2,
                p: 2,
                minHeight: 200,
              }}
            >
              {headerEnabled && newTemplate.components[0].type === 'HEADER' && newTemplate.components[0].text && (
                <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                  {formatPreviewText(getPreviewText(newTemplate.components[0].text))}
                </Typography>
              )}
              {newTemplate.components[1].type === 'BODY' &&
                'text' in newTemplate.components[1] &&
                newTemplate.components[1].text && (
                  <Typography variant="body1" paragraph sx={{ whiteSpace: 'pre-line' }}>
                    {formatPreviewText(getPreviewText(newTemplate.components[1].text))}
                  </Typography>
                )}
              {footerEnabled && newTemplate.components[2].type === 'FOOTER' && newTemplate.components[2].text && (
                <Typography variant="body2" color="text.secondary">
                  {formatPreviewText(getPreviewText(newTemplate.components[2].text))}
                </Typography>
              )}
              {newTemplate.components[3].type === 'BUTTONS' && newTemplate.components[3].buttons.length > 0 && (
                <Stack spacing={1} mt={2}>
                  {newTemplate.components[3].buttons.map(
                    (button, index) =>
                      button.text && (
                        <Button
                          key={`${index + 1}`}
                          variant="outlined"
                          fullWidth
                          size="small"
                          sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                          }}
                        >
                          {button.text}
                        </Button>
                      )
                  )}
                </Stack>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateTemplateForm;
