import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useGetBusinessUnitsQuery } from 'services/api/businessUnits.api';
import {
  useCreateConversationEvalMutation,
  useGetEvalFromMessageMutation,
  useUpdateConversationEvalMutation,
} from 'services/api/conversationEvals.api';
import { ConversationEval, MessageToEval } from 'services/types/conversationEvals';

interface ConversationEvalFormProps {
  conversationEval?: ConversationEval;
  onClose: () => void;
  messageId?: number;
}

const getRandomKey = () => Math.random().toString(36).substring(2, 15);

const ConversationEvalForm: React.FC<ConversationEvalFormProps> = ({ conversationEval, onClose, messageId }) => {
  const isEditMode = !!conversationEval;
  const [mockClientJSON, setMockClientJSON] = useState<string>(
    conversationEval?.mockClient ? JSON.stringify(conversationEval.mockClient, null, 2) : '{}'
  );
  const [toolCallsJSON, setToolCallsJSON] = useState<(string | null)[]>(
    conversationEval?.messages.map((m) => (m.toolCalls ? JSON.stringify(m.toolCalls, null, 2) : null)) || []
  );
  const [formData, setFormData] = useState<Omit<ConversationEval, 'id' | 'createdAt' | 'updatedAt'>>({
    businessUnitIds: conversationEval?.businessUnitIds || [],
    mockClient: conversationEval?.mockClient || {},
    messages: conversationEval?.messages.map((m) => ({ ...m, key: getRandomKey() })) || [
      {
        role: 'user',
        text: '',
        key: getRandomKey(),
      },
    ],
  });
  const [errors, setErrors] = useState<{
    businessUnitIds?: string;
    mockClient?: string;
    messages?: string;
    toolCalls: (string | null)[];
  }>({
    toolCalls: formData.messages.map(() => null),
  });
  const [evalFromMessage, setEvalFromMessage] = useState<ConversationEval | null>(null);
  const [createConversationEval] = useCreateConversationEvalMutation();
  const [updateConversationEval] = useUpdateConversationEvalMutation();
  const { data: businessUnits } = useGetBusinessUnitsQuery(null);
  const [getEvalFromMessage] = useGetEvalFromMessageMutation();

  const handleBusinessUnitChange = (event: SelectChangeEvent<number[]>) => {
    setFormData({
      ...formData,
      businessUnitIds: event.target.value as number[],
    });
    setErrors({
      ...errors,
      businessUnitIds: undefined,
    });
  };

  const handleMessageChange = (index: number, field: keyof MessageToEval, value: string) => {
    const updatedMessages = [...formData.messages];
    if (field === 'text') {
      updatedMessages[index] = {
        ...updatedMessages[index],
        text: value,
      };
    } else if (field === 'role') {
      updatedMessages[index] = {
        ...updatedMessages[index],
        role: value as MessageToEval['role'],
      };
    } else if (field === 'toolCalls') {
      const updatedToolCalls = [...toolCallsJSON];
      updatedToolCalls[index] = value;
      setToolCallsJSON(updatedToolCalls);
      setErrors({
        ...errors,
        toolCalls: errors.toolCalls.map((error, i) => (i === index ? null : error)),
      });
    } else if (field === 'toolCallId') {
      updatedMessages[index] = {
        ...updatedMessages[index],
        toolCallId: value,
      };
    }
    setFormData({
      ...formData,
      messages: updatedMessages,
    });
    setErrors({
      ...errors,
      messages: undefined,
    });
  };

  const handleExpectedChange = (
    index: number,
    field: 'responseDescription' | 'responseDistance',
    value: string | number
  ) => {
    const updatedMessages = [...formData.messages];
    const currentMessage = updatedMessages[index];
    updatedMessages[index] = {
      ...currentMessage,
      expected: {
        ...(currentMessage.expected || { responseDescription: '' }),
        [field]: field === 'responseDistance' ? Number(value) : value,
      },
    };
    setFormData({
      ...formData,
      messages: updatedMessages,
    });
  };

  const addMessage = () => {
    setFormData({
      ...formData,
      messages: [
        ...formData.messages,
        {
          role: 'user',
          text: '',
          toolCalls: null,
          key: getRandomKey(),
        },
      ],
    });
    setToolCallsJSON([...toolCallsJSON, null]);
    setErrors({
      ...errors,
      toolCalls: [...errors.toolCalls, null],
    });
  };

  const removeMessage = (index: number) => {
    const updatedMessages = [...formData.messages];
    updatedMessages.splice(index, 1);
    setFormData({
      ...formData,
      messages: updatedMessages,
    });
    setToolCallsJSON(toolCallsJSON.filter((_, i) => i !== index));
    setErrors({
      ...errors,
      toolCalls: errors.toolCalls.filter((_, i) => i !== index),
    });
  };

  const validateForm = (): boolean => {
    const newErrors: typeof errors = {
      toolCalls: toolCallsJSON.map(() => null),
    };
    if (formData.businessUnitIds.length === 0) {
      newErrors.businessUnitIds = 'At least one business unit is required';
    }
    if (formData.messages.length === 0) {
      newErrors.messages = 'At least one message is required';
    } else {
      const invalidMessages = formData.messages.some(
        (msg) =>
          (msg.role !== 'assistant' && !msg.text) ||
          (msg.role === 'assistant' && !msg.text && !msg.toolCalls) ||
          (msg.role === 'tool' && !msg.toolCallId)
      );
      if (invalidMessages) {
        newErrors.messages = 'All messages must have text or toolCalls. All tool messages must have a toolCallId';
      }
    }
    toolCallsJSON.forEach((toolCall, index) => {
      if (toolCall) {
        try {
          JSON.parse(toolCall);
        } catch (error) {
          newErrors.toolCalls[index] = 'Invalid JSON format for tool calls';
        }
      }
    });
    try {
      JSON.parse(mockClientJSON);
    } catch (error) {
      newErrors.mockClient = 'Invalid JSON format';
    }
    setErrors(newErrors);

    return newErrors.toolCalls.every((error) => error === null) && Object.values(newErrors).length === 1;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    const parsedMockClient = JSON.parse(mockClientJSON) as typeof formData.mockClient;
    setFormData({
      ...formData,
      mockClient: parsedMockClient,
      messages: formData.messages.map((message, index) => ({
        ...message,
        toolCalls: toolCallsJSON[index]
          ? (JSON.parse(toolCallsJSON[index] || '[]') as MessageToEval['toolCalls'])
          : null,
      })),
    });
    try {
      if (isEditMode && conversationEval?.id) {
        await updateConversationEval({
          conversationEvalId: conversationEval.id,
          ...formData,
          messages: formData.messages.map((message) => ({
            ...message,
            key: undefined,
          })),
        });
      } else {
        await createConversationEval(formData);
      }
      setFormData({
        businessUnitIds: [],
        mockClient: {},
        messages: [],
      });
      onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to save conversation eval:', error);
    }
  };

  useEffect(() => {
    if (messageId) {
      getEvalFromMessage({ messageId })
        .then((data) => {
          if ('data' in data) {
            setEvalFromMessage(data.data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Failed to get eval from message:', error);
        });
    }
  }, [messageId]);

  useEffect(() => {
    try {
      const value = JSON.parse(mockClientJSON) as typeof formData.mockClient;
      setFormData({
        ...formData,
        mockClient: value,
      });
      setErrors({
        ...errors,
        mockClient: undefined,
      });
    } catch (error) {
      setErrors({
        ...errors,
        mockClient: 'Invalid JSON format',
      });
    }
  }, [mockClientJSON]);

  useEffect(() => {
    if (evalFromMessage) {
      setFormData({
        ...evalFromMessage,
        messages: evalFromMessage.messages.map((m) => ({ ...m, key: getRandomKey() })),
      });
      setMockClientJSON(JSON.stringify(evalFromMessage.mockClient, null, 2));
      setToolCallsJSON(evalFromMessage.messages.map((m) => (m.toolCalls ? JSON.stringify(m.toolCalls, null, 2) : '')));
    }
  }, [evalFromMessage]);

  useEffect(() => {
    const newErrors: typeof errors = {
      toolCalls: toolCallsJSON.map(() => null),
    };
    toolCallsJSON.forEach((toolCall, index) => {
      if (toolCall) {
        try {
          const value = JSON.parse(toolCall) as MessageToEval['toolCalls'];
          setFormData({
            ...formData,
            messages: formData.messages.map((message, i) => (i === index ? { ...message, toolCalls: value } : message)),
          });
        } catch (error) {
          newErrors.toolCalls[index] = 'Invalid JSON format for tool calls';
        }
      }
    });
    setErrors(newErrors);
  }, [toolCallsJSON]);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.businessUnitIds}>
            <InputLabel id="business-units-label">Business Units</InputLabel>
            <Select
              labelId="business-units-label"
              multiple
              value={formData.businessUnitIds}
              onChange={handleBusinessUnitChange}
              label="Business Units"
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const businessUnit = businessUnits?.find((bu) => bu.id === value);
                    return businessUnit ? <Chip key={value} label={businessUnit.name} size="small" /> : null;
                  })}
                </Box>
              )}
            >
              {businessUnits?.map((businessUnit) => (
                <MenuItem key={businessUnit.id} value={businessUnit.id}>
                  {businessUnit.name}
                </MenuItem>
              ))}
            </Select>
            {errors.businessUnitIds && <FormHelperText>{errors.businessUnitIds}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Mock Client
          </Typography>
          <Box
            sx={{
              p: 2,
              backgroundColor: '#f5f5f5',
              borderRadius: 1,
              mb: 2,
            }}
          >
            <TextField
              fullWidth
              label="Client JSON"
              multiline
              rows={5}
              value={mockClientJSON}
              onChange={(e) => setMockClientJSON(e.target.value)}
              style={{
                backgroundColor: errors.mockClient ? '#e1c0c0' : '#c9d9c9',
                fontSize: '12px',
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Mensajes</Typography>
            <Button startIcon={<AddIcon />} onClick={addMessage}>
              Agregar Mensaje
            </Button>
          </Box>
          {errors.messages && (
            <FormHelperText error sx={{ mb: 2 }}>
              {errors.messages}
            </FormHelperText>
          )}
          {formData.messages.map((message, index) => (
            <Box
              key={message.key}
              sx={{
                p: 2,
                mb: 2,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                position: 'relative',
              }}
            >
              {!message.key && (
                <Typography variant="caption" color="error" sx={{ mb: 2 }}>
                  Message key is missing
                </Typography>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel id={`role-label-${message.text}`}>Rol</InputLabel>
                    <Select
                      labelId={`role-label-${message.text}`}
                      value={message.role}
                      onChange={(e) => handleMessageChange(index, 'role', e.target.value)}
                      label="Role"
                    >
                      <MenuItem value="user">User</MenuItem>
                      <MenuItem value="assistant">Assistant</MenuItem>
                      <MenuItem value="tool">Tool</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    fullWidth
                    label="Message Text"
                    value={message.text || ''}
                    onChange={(e) => handleMessageChange(index, 'text', e.target.value)}
                    multiline
                    rows={3}
                  />
                </Grid>

                {message.role === 'assistant' && (
                  <>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        fullWidth
                        label="Tool Calls"
                        value={toolCallsJSON[index] || ''}
                        onChange={(e) => handleMessageChange(index, 'toolCalls', e.target.value)}
                        multiline
                        rows={4}
                        style={{
                          backgroundColor: errors.toolCalls[index] ? '#e1c0c0' : '#c9d9c9',
                          fontSize: '12px',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        fullWidth
                        label="Expected Response Description"
                        value={message.expected?.responseDescription || ''}
                        onChange={(e) => handleExpectedChange(index, 'responseDescription', e.target.value)}
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        label="Response Distance"
                        type="number"
                        value={message.expected?.responseDistance || ''}
                        onChange={(e) => handleExpectedChange(index, 'responseDistance', e.target.value)}
                      />
                    </Grid>
                  </>
                )}
                {message.role === 'tool' && (
                  <Grid item xs={12} sm={9}>
                    <TextField
                      fullWidth
                      label="Tool Call ID"
                      value={message.toolCallId || ''}
                      onChange={(e) => handleMessageChange(index, 'toolCallId', e.target.value)}
                      multiline
                      rows={4}
                    />
                  </Grid>
                )}
              </Grid>
              {formData.messages.length > 1 && (
                <IconButton
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                  onClick={() => removeMessage(index)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubmit().catch((error) => {
                  // eslint-disable-next-line no-console
                  console.error('Failed to save conversation eval:', error);
                });
              }}
            >
              {isEditMode ? 'Actualizar' : 'Crear'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ConversationEvalForm.defaultProps = {
  conversationEval: undefined,
  messageId: undefined,
};

export default ConversationEvalForm;
