import { Message } from '../types/message';
import baseApi from './baseApi';

const publicAccessApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createDemochatClient: build.mutation({
      query: (props: {
        companyIdentifier: string;
        recaptchaToken: string;
        isFromWidget?: boolean;
        clientData?: {
          firstName: string;
          lastName: string;
          rut: string;
          email: string;
          modelOfInterest: string;
          brandOfInterest: string;
        };
      }) => ({
        url: '/demochat/clients',
        method: 'POST',
        body: {
          companyIdentifier: props.companyIdentifier,
          recaptchaToken: props.recaptchaToken,
          isFromWidget: props.isFromWidget,
          clientData: props.clientData,
        },
      }),
      transformResponse: (response: { id: string }) => response,
    }),
    getDemoClientMessages: build.query({
      query: (props: { clientPhoneIdentifier: string }) => ({
        url: `/demochat/messages?clientPhoneIdentifier=${props.clientPhoneIdentifier}`,
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
    createDemochatMessage: build.mutation({
      query: (props: { clientPhoneIdentifier: string; message: string }) => ({
        url: '/demochat/messages',
        method: 'POST',
        body: props,
      }),
      transformResponse: (response: { status: string }) => response,
    }),
    getIsPrivateDemo: build.query({
      query: (props: { companyIdentifier: string }) => ({
        url: `/demochat/is-private-demo?companyIdentifier=${props.companyIdentifier}`,
        method: 'GET',
      }),
      transformResponse: (response: { isPrivateDemo: boolean }) => response,
    }),
    uploadDemochatFile: build.mutation({
      query: (props: { clientPhoneIdentifier: string; file: File }) => {
        const formData = new FormData();
        formData.append('file', props.file);
        return {
          url: `/demochat/file/${props.clientPhoneIdentifier}`,
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response: { status: string }) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDemoClientMessagesQuery,
  useCreateDemochatClientMutation,
  useCreateDemochatMessageMutation,
  useGetIsPrivateDemoQuery,
  useUploadDemochatFileMutation,
} = publicAccessApi;
