import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import MarkChatUnread from '@mui/icons-material/MarkChatUnread';
import ReplyIcon from '@mui/icons-material/Reply';
import SmsFailed from '@mui/icons-material/SmsFailed';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridFilterModel, GridSortModel, GridColDef } from '@mui/x-data-grid';

import CustomDataGrid from 'components/common/CustomDataGrid';
import { onMessage } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import useCurrentUser from 'hooks/useCurrentUser';
import useIsMobile from 'hooks/useIsMobile';
import { useGetAlertsQuery } from 'services/api/alerts.api';
import { useGetClientsQuery, useGetClientsSourcesQuery } from 'services/api/clients.api';
import { Client } from 'services/types/client';

import AdvancedSearch from './AdvancedSearch';
import Alerts from './Alerts';
import AdminSection from './components/AdminSection';
import SearchSection from './components/SearchSection';

const last24hoursDate = new Date();
last24hoursDate.setDate(last24hoursDate.getDate() - 1);

const getBaseUserAttributes = (businessUnitType: string, currentUserId: number | null): GridColDef<Client>[] => {
  const baseColumns: GridColDef<Client>[] = [
    {
      field: 'businessUnit',
      headerName: 'Unidades de Negocio',
      width: 200,
      valueFormatter: (value: { name: string }) => value.name,
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 260,
      renderCell(params) {
        return (
          <div style={{ color: params.row.isTestClient ? 'grey' : undefined, display: 'flex', alignItems: 'center' }}>
            {params.row.disabledAutomaticResponses ? <SpeakerNotesOffIcon sx={{ mr: 1 }} /> : null}
            {params.row.clientMessagesCount > 0 ? (
              <>
                <ReplyIcon titleAccess="Cliente respondió" fontSize="small" color="primary" />
                <span style={{ color: 'grey', fontSize: '0.7em', marginRight: '5px' }}>
                  x{params.row.clientMessagesCount}
                </span>
              </>
            ) : null}
            {params.row.messagesCount === 0 ? <SmsFailed sx={{ mr: 1 }} color="error" /> : null}
            {params.row.userId ? (
              <ConnectWithoutContactIcon
                sx={{ mr: 1 }}
                color={params.row.userId === currentUserId ? 'info' : 'inherit'}
              />
            ) : null}
            {params.row.lastSentMessageRole && params.row.lastSentMessageRole !== 'assistant' ? (
              <MarkChatUnread sx={{ mr: 1 }} />
            ) : null}
            {params.row.aiSafetyStatus === 'unsafe' ? <WarningIcon sx={{ mr: 1 }} color="warning" /> : null}
            {params.row.firstName} {params.row.lastName}
            {params.row.hasValidName && <CheckIcon color="success" sx={{ align: 'center' }} />}
          </div>
        );
      },
    },
    {
      field: 'source',
      headerName: 'Origen',
      width: 190,
    },
  ];
  if (businessUnitType === 'car_dealership') {
    baseColumns.push(
      {
        field: 'lastCRMUpdate',
        headerName: 'Subido CRM',
        width: 120,
        valueFormatter: (value: Date | null) => (value ? 'Si' : 'No'),
      },
      {
        field: 'visitDate',
        headerName: 'Agenda visita',
        width: 200,
        valueFormatter: (value: Date | null) =>
          value
            ? new Date(value).toLocaleString('es-CL', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })
            : '',
      },
      {
        field: 'brandOfInterest',
        headerName: 'Marca de interés',
        width: 150,
      },
      {
        field: 'modelsOfInterest',
        headerName: 'Modelo de interés',
        valueFormatter: (value: string[] | null) => (value ? value.at(-1) : ''),
        width: 160,
      },
      {
        field: 'clientType',
        headerName: 'Tipo',
        width: 100,
        valueFormatter: (value: 'buy' | 'sell' | null) => {
          if (value === 'buy') return 'Compra';
          if (value === 'sell') return 'Venta';
          if (value === 'after-sale') return 'Post-Venta';
          return '';
        },
      },
      {
        field: 'outboundCampaignId',
        headerName: 'Campaña',
        width: 150,
      },
      {
        field: 'branchOffice',
        headerName: 'Sucursal',
        width: 300,
      },
      {
        field: 'requiresFinancing',
        headerName: 'Quiere Financiamiento',
        width: 150,
        valueFormatter: (value: boolean) => (value ? 'Si' : ''),
      },
      {
        field: 'endReason',
        headerName: 'Motivo de término',
        width: 150,
      }
    );
  }

  return [
    ...baseColumns,
    {
      field: 'lastMessageDate',
      headerName: 'Actualización',
      width: 140,
      valueFormatter: (value: Date) => {
        const dateValue = new Date(value);
        if (dateValue > last24hoursDate) return dateValue.toLocaleTimeString('es-CL');
        return `${dateValue.getDate()}/${dateValue.getMonth() + 1} ${dateValue.getHours()}:${dateValue
          .getMinutes()
          .toString()
          .padStart(2, '0')}`;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Creación',
      width: 140,
      valueFormatter: (value: Date) => {
        const dateValue = new Date(value);
        if (dateValue > last24hoursDate) return dateValue.toLocaleTimeString('es-CL');
        return `${dateValue.getDate()}/${dateValue.getMonth() + 1} ${dateValue.getHours()}:${dateValue
          .getMinutes()
          .toString()
          .padStart(2, '0')}`;
      },
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      width: 160,
    },
    {
      field: 'rut',
      headerName: 'RUT',
      width: 140,
    },
    {
      field: 'nationality',
      headerName: 'Nacionalidad',
      width: 130,
    },
    {
      field: 'dateOfBirth',
      headerName: 'Edad',
      width: 70,
      valueFormatter: (value: string | null) => {
        if (!value) return null;
        const birthday = new Date();
        birthday.setDate(Number(value.slice(0, 2)));
        birthday.setMonth(Number(value.slice(3, 5)));
        birthday.setFullYear(Number(value.slice(-4)));
        const ageDifMs = Date.now() - birthday.getTime();
        return Math.abs(new Date(ageDifMs).getUTCFullYear() - 1970);
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'messagesCount',
      headerName: 'Mensajes',
      width: 100,
    },
    {
      field: 'clientMessagesCount',
      headerName: 'Mensajes Cliente',
      width: 100,
    },
    {
      field: 'disabledAutomaticResponses',
      headerName: 'Sin IA',
      width: 60,
      valueFormatter: (value: boolean) => (value ? 'Si' : ''),
    },
    {
      field: 'user',
      headerName: 'Vendedor asignado',
      width: 150,
      valueFormatter: (value?: { email: string }) => value?.email || '',
    },
    {
      field: 'lastSentMessageRole',
      headerName: 'Rol último mensaje',
      width: 100,
      colSpan: 0,
    },
    {
      field: 'aiSafetyStatus',
      headerName: 'Seguridad IA',
      width: 100,
      colSpan: 0,
    },
    {
      field: 'hasValidName',
      headerName: 'Nombre validado',
      width: 100,
      colSpan: 0,
    },
  ];
};

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface ClientsQueryParams {
  paginationModel: PaginationModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  searchTerm?: string;
  businessUnitId?: number;
  businessUnitType?: 'car_dealership' | 'financial_advisor' | null;
}

interface Props {
  clients: Client[];
  filterModel: GridFilterModel;
  paginationModel: PaginationModel;
  sortModel: GridSortModel;
  setFilterModel: (model: GridFilterModel) => void;
  setPaginationModel: (model: PaginationModel) => void;
  setSortModel: (model: GridSortModel) => void;
  navigateToClientId: (id: number, newPage?: boolean) => void;
  total: number;
  highlightedClientId: string;
  businessUnitType: string;
  sources: string[];
}

const ClientsTable: FC<Props> = ({
  clients,
  filterModel,
  paginationModel,
  sortModel,
  setFilterModel,
  setPaginationModel,
  setSortModel,
  navigateToClientId,
  highlightedClientId,
  total,
  businessUnitType,
  sources,
}) => {
  const currentUser = useCurrentUser();
  const [userFilterAnchorEl, setUserFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [clientMessagesFilterAnchorEl, setClientMessagesFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [iaFilterAnchorEl, setIaFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [lastMessageFilterAnchorEl, setLastMessageFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [safetyFilterAnchorEl, setSafetyFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [messagesFilterAnchorEl, setMessagesFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [nameValidationFilterAnchorEl, setNameValidationFilterAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterClick = (
    event: React.MouseEvent<HTMLElement>,
    setAnchorEl: (element: HTMLElement | null) => void
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (setAnchorEl: (element: HTMLElement | null) => void) => {
    setAnchorEl(null);
  };

  const handleFilterSelect = (field: string, operator: string, value: string | number | boolean | null) => {
    const newFilterItems = filterModel.items.filter((item) => item.field !== field);

    if (value !== null) {
      newFilterItems.push({
        field,
        operator,
        value,
      });
    }

    setFilterModel({
      ...filterModel,
      items: newFilterItems,
    });
  };

  const handleUserFilterSelect = (filterType: 'mine' | 'unassigned' | 'assigned' | 'all') => {
    if (filterType === 'mine') {
      if (currentUser?.id !== undefined) {
        handleFilterSelect('userId', 'equals', currentUser.id);
      }
    } else if (filterType === 'unassigned') {
      handleFilterSelect('userId', 'isEmpty', '');
    } else if (filterType === 'assigned') {
      handleFilterSelect('userId', 'isNotEmpty', '');
    } else {
      handleFilterSelect('userId', 'equals', null);
    }
    handleFilterClose(setUserFilterAnchorEl);
  };

  const handleClientMessagesFilterSelect = (filterType: 'none' | 'some' | 'fiveplus' | 'fifteenplus' | 'all') => {
    if (filterType === 'none') {
      handleFilterSelect('clientMessagesCount', 'equals', 0);
    } else if (filterType === 'some') {
      handleFilterSelect('clientMessagesCount', 'greaterThan', 0);
    } else if (filterType === 'fiveplus') {
      handleFilterSelect('clientMessagesCount', 'greaterThan', 4);
    } else if (filterType === 'fifteenplus') {
      handleFilterSelect('clientMessagesCount', 'greaterThan', 14);
    } else {
      handleFilterSelect('clientMessagesCount', 'equals', null);
    }
    handleFilterClose(setClientMessagesFilterAnchorEl);
  };

  const handleIaFilterSelect = (filterType: 'enabled' | 'disabled' | 'all') => {
    if (filterType === 'enabled') {
      handleFilterSelect('disabledAutomaticResponses', 'equals', false);
    } else if (filterType === 'disabled') {
      handleFilterSelect('disabledAutomaticResponses', 'equals', true);
    } else {
      handleFilterSelect('disabledAutomaticResponses', 'equals', null);
    }
    handleFilterClose(setIaFilterAnchorEl);
  };

  const handleLastMessageFilterSelect = (filterType: 'client' | 'executive' | 'all') => {
    if (filterType === 'client') {
      handleFilterSelect('lastSentMessageRole', 'equals', 'user');
    } else if (filterType === 'executive') {
      handleFilterSelect('lastSentMessageRole', 'equals', 'assistant');
    } else {
      handleFilterSelect('lastSentMessageRole', 'equals', null);
    }
    handleFilterClose(setLastMessageFilterAnchorEl);
  };

  const handleSafetyFilterSelect = (filterType: 'unsafe' | 'safe' | 'all') => {
    if (filterType === 'unsafe') {
      handleFilterSelect('aiSafetyStatus', 'equals', 'unsafe');
    } else if (filterType === 'safe') {
      handleFilterSelect('aiSafetyStatus', 'notEquals', 'unsafe');
    } else {
      handleFilterSelect('aiSafetyStatus', 'equals', null);
    }
    handleFilterClose(setSafetyFilterAnchorEl);
  };

  const handleMessagesFilterSelect = (filterType: 'none' | 'some' | 'fiveplus' | 'fifteenplus' | 'all') => {
    if (filterType === 'none') {
      handleFilterSelect('messagesCount', 'equals', 0);
    } else if (filterType === 'some') {
      handleFilterSelect('messagesCount', 'greaterThan', 0);
    } else if (filterType === 'fiveplus') {
      handleFilterSelect('messagesCount', 'greaterThan', 4);
    } else if (filterType === 'fifteenplus') {
      handleFilterSelect('messagesCount', 'greaterThan', 14);
    } else {
      handleFilterSelect('messagesCount', 'equals', null);
    }
    handleFilterClose(setMessagesFilterAnchorEl);
  };

  const handleNameValidationFilterSelect = (filterType: 'valid' | 'invalid' | 'all') => {
    if (filterType === 'valid') {
      handleFilterSelect('hasValidName', 'equals', true);
    } else if (filterType === 'invalid') {
      handleFilterSelect('hasValidName', 'equals', false);
    } else {
      handleFilterSelect('hasValidName', 'equals', null);
    }
    handleFilterClose(setNameValidationFilterAnchorEl);
  };

  const ADMIN_USER_ATTRIBUTES: GridColDef<Client>[] = [
    {
      field: 'AIRevision',
      headerName: 'Puntaje IA',
      width: 120,
      renderCell(params) {
        if (!params.row.AIRevision) return '-';
        const score = params.row.AIRevision.match(/Puntaje: (\d+)/);
        return (
          <Tooltip title={params.row.AIRevision}>
            <span>{score && score[1] ? score[1] : '*'}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'imagesSent',
      headerName: 'Imágenes',
      width: 90,
    },
    {
      field: 'isTestClient',
      headerName: 'Test',
      width: 50,
      valueFormatter: (value: boolean) => (value ? 'Si' : ''),
    },
    ...(businessUnitType === 'car_dealership'
      ? [
          {
            field: 'followUpsSent',
            headerName: 'Follow Ups',
            width: 100,
          },
          {
            field: 'failedMessagesCount',
            headerName: 'Mensajes fallidos',
            width: 100,
          },
          {
            field: 'hasValidatedAllData',
            headerName: 'Validó Datos',
            width: 90,
            valueFormatter: (value: boolean) => (value ? 'Si' : ''),
          },
          {
            field: 'floidRequestsCount',
            headerName: 'Floid',
            width: 60,
          },
          {
            field: 'floidRequestsDetails',
            headerName: 'Floid detalles',
            width: 250,
            valueFormatter: (value: string) =>
              value
                ?.replace(/REGISTRO_CIVIL/g, 'RegCivil')
                .replace(/CAMARA_COMERCIO/g, 'CCS')
                .replace(/200/g, 'OK')
                .replace(/400/g, 'ERROR'),
          },
        ]
      : []),
    {
      field: 'adminNotes',
      headerName: 'Notas Administrador',
      width: 400,
    },
  ];

  const scrollToHighlightedRow = () => {
    const rowIndex = clients.findIndex((client) => client.id.toString() === highlightedClientId);
    if (rowIndex < 5 || clients.length < 5) return;
    document.querySelector('.MuiDataGrid-virtualScroller')?.scrollTo({ top: 52 * (rowIndex - 3), behavior: 'smooth' });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToHighlightedRow();
    }, 400);
  }, []);

  useEffect(() => {
    scrollToHighlightedRow();
  }, [clients]);

  let columns = currentUser?.isAdmin
    ? getBaseUserAttributes(businessUnitType, currentUser.id).concat(ADMIN_USER_ATTRIBUTES)
    : getBaseUserAttributes(businessUnitType, currentUser?.id || null);

  if (currentUser?.isAdmin) {
    // swap the businessUnit column to the first position
    const businessUnitColumn = columns.find((column) => column.field === 'businessUnit');
    if (businessUnitColumn) {
      columns = [businessUnitColumn, ...columns.filter((column) => column.field !== 'businessUnit')];
    }
    const AIRevisionColumn = columns.findIndex((column) => column.field === 'AIRevision');
    if (AIRevisionColumn > -1) {
      columns.splice(8, 0, columns[AIRevisionColumn]);
      columns.splice(AIRevisionColumn + 1, 1);
    }
  }

  return (
    <Box style={{ width: '100%' }} my={2}>
      <Menu
        anchorEl={userFilterAnchorEl}
        open={Boolean(userFilterAnchorEl)}
        onClose={() => handleFilterClose(setUserFilterAnchorEl)}
      >
        <MenuItem onClick={() => handleUserFilterSelect('all')}>Ver todos</MenuItem>
        <MenuItem onClick={() => handleUserFilterSelect('mine')}>Mis clientes</MenuItem>
        <MenuItem onClick={() => handleUserFilterSelect('unassigned')}>Clientes sin asignar</MenuItem>
        <MenuItem onClick={() => handleUserFilterSelect('assigned')}>Clientes asignados</MenuItem>
      </Menu>

      <Menu
        anchorEl={clientMessagesFilterAnchorEl}
        open={Boolean(clientMessagesFilterAnchorEl)}
        onClose={() => handleFilterClose(setClientMessagesFilterAnchorEl)}
      >
        <MenuItem onClick={() => handleClientMessagesFilterSelect('all')}>Ver todos</MenuItem>
        <MenuItem onClick={() => handleClientMessagesFilterSelect('none')}>Sin mensajes del cliente</MenuItem>
        <MenuItem onClick={() => handleClientMessagesFilterSelect('some')}>Con mensajes del cliente</MenuItem>
        <MenuItem onClick={() => handleClientMessagesFilterSelect('fiveplus')}>5+ mensajes del cliente</MenuItem>
        <MenuItem onClick={() => handleClientMessagesFilterSelect('fifteenplus')}>15+ mensajes del cliente</MenuItem>
      </Menu>

      <Menu
        anchorEl={iaFilterAnchorEl}
        open={Boolean(iaFilterAnchorEl)}
        onClose={() => handleFilterClose(setIaFilterAnchorEl)}
      >
        <MenuItem onClick={() => handleIaFilterSelect('all')}>Ver todos</MenuItem>
        <MenuItem onClick={() => handleIaFilterSelect('enabled')}>IA habilitada</MenuItem>
        <MenuItem onClick={() => handleIaFilterSelect('disabled')}>IA deshabilitada</MenuItem>
      </Menu>

      <Menu
        anchorEl={lastMessageFilterAnchorEl}
        open={Boolean(lastMessageFilterAnchorEl)}
        onClose={() => handleFilterClose(setLastMessageFilterAnchorEl)}
      >
        <MenuItem onClick={() => handleLastMessageFilterSelect('all')}>Ver todos</MenuItem>
        <MenuItem onClick={() => handleLastMessageFilterSelect('client')}>Último mensaje: Cliente</MenuItem>
        <MenuItem onClick={() => handleLastMessageFilterSelect('executive')}>Último mensaje: Ejecutivo</MenuItem>
      </Menu>

      <Menu
        anchorEl={safetyFilterAnchorEl}
        open={Boolean(safetyFilterAnchorEl)}
        onClose={() => handleFilterClose(setSafetyFilterAnchorEl)}
      >
        <MenuItem onClick={() => handleSafetyFilterSelect('all')}>Ver todos</MenuItem>
        <MenuItem onClick={() => handleSafetyFilterSelect('unsafe')}>Marcados inseguros</MenuItem>
        <MenuItem onClick={() => handleSafetyFilterSelect('safe')}>Marcados seguros</MenuItem>
      </Menu>

      <Menu
        anchorEl={messagesFilterAnchorEl}
        open={Boolean(messagesFilterAnchorEl)}
        onClose={() => handleFilterClose(setMessagesFilterAnchorEl)}
      >
        <MenuItem onClick={() => handleMessagesFilterSelect('all')}>Ver todos</MenuItem>
        <MenuItem onClick={() => handleMessagesFilterSelect('none')}>Sin mensajes</MenuItem>
        <MenuItem onClick={() => handleMessagesFilterSelect('some')}>Con mensajes</MenuItem>
        <MenuItem onClick={() => handleMessagesFilterSelect('fiveplus')}>5+ mensajes</MenuItem>
        <MenuItem onClick={() => handleMessagesFilterSelect('fifteenplus')}>15+ mensajes</MenuItem>
      </Menu>

      <Menu
        anchorEl={nameValidationFilterAnchorEl}
        open={Boolean(nameValidationFilterAnchorEl)}
        onClose={() => handleFilterClose(setNameValidationFilterAnchorEl)}
      >
        <MenuItem onClick={() => handleNameValidationFilterSelect('all')}>Ver todos</MenuItem>
        <MenuItem onClick={() => handleNameValidationFilterSelect('valid')}>Nombre validado</MenuItem>
        <MenuItem onClick={() => handleNameValidationFilterSelect('invalid')}>Nombre sin validar</MenuItem>
      </Menu>

      <Box
        sx={{
          backgroundColor: 'White',
          borderRadius: 4,
          p: { xs: 0, sm: 1 },
        }}
      >
        <CustomDataGrid
          rows={clients}
          columns={columns}
          filterModel={filterModel}
          sortModel={sortModel}
          onFilterModelChange={setFilterModel}
          onSortModelChange={setSortModel}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowCount={total}
          onRowClick={(params, event) => navigateToClientId(Number(params.id), event.ctrlKey || event.metaKey)}
          getRowClassName={(params) => (params.id.toString() === highlightedClientId ? 'Mui-selected' : '')}
          sources={sources}
          topBar={
            <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" overflow="scroll">
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    '& .MuiTypography-root': {
                      textDecoration: 'underline',
                    },
                  },
                }}
                onClick={(e) => handleFilterClick(e, setClientMessagesFilterAnchorEl)}
              >
                <ReplyIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} color="primary" />
                <Typography variant="caption">Mensajes del cliente</Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    '& .MuiTypography-root': {
                      textDecoration: 'underline',
                    },
                  },
                }}
                onClick={(e) => handleFilterClick(e, setIaFilterAnchorEl)}
              >
                <SpeakerNotesOffIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} />
                <Typography variant="caption">Sin IA</Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    '& .MuiTypography-root': {
                      textDecoration: 'underline',
                    },
                  },
                }}
                onClick={(e) => handleFilterClick(e, setLastMessageFilterAnchorEl)}
              >
                <MarkChatUnread fontSize="small" sx={{ ml: '10px', mr: '4px' }} />
                <Typography variant="caption">Por responder</Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    '& .MuiTypography-root': {
                      textDecoration: 'underline',
                    },
                  },
                }}
                onClick={(e) => handleFilterClick(e, setSafetyFilterAnchorEl)}
              >
                <WarningIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} color="warning" />
                <Typography variant="caption">Inseguro</Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    '& .MuiTypography-root': {
                      textDecoration: 'underline',
                    },
                  },
                }}
                onClick={(e) => handleFilterClick(e, setMessagesFilterAnchorEl)}
              >
                <SmsFailed fontSize="small" sx={{ ml: '10px', mr: '4px' }} color="error" />
                <Typography variant="caption">Sin mensajes</Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    '& .MuiTypography-root': {
                      textDecoration: 'underline',
                    },
                  },
                }}
                onClick={(e) => handleFilterClick(e, setUserFilterAnchorEl)}
              >
                <ConnectWithoutContactIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} color="info" />
                <Typography variant="caption">Asignado a mí</Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    '& .MuiTypography-root': {
                      textDecoration: 'underline',
                    },
                  },
                }}
                onClick={(e) => handleFilterClick(e, setNameValidationFilterAnchorEl)}
              >
                <CheckIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} color="success" />
                <Typography variant="caption">Nombre validado</Typography>
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

const Clients: FC = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isMobile = useIsMobile();
  const queryParams = new URLSearchParams(window.location.search);
  const queryFilterModel = queryParams.get('filterModel');
  const queryPaginationModel = queryParams.get('paginationModel');
  const querySortModel = queryParams.get('sortModel');
  const querySearchTerm = queryParams.get('searchTerm');
  const queryBusinessUnitId = queryParams.get('businessUnitId');
  const queryBusinessUnitType = queryParams.get('businessUnitType') as 'car_dealership' | 'financial_advisor' | null;
  const highlightedClientId = queryParams.get('highlightedClientId') || '';

  const [searchTerm, setSearchTerm] = useState<string>(querySearchTerm || '');
  const parsedBusinessUnitId = parseInt(queryBusinessUnitId || '', 10);
  const [businessUnitId, setBusinessUnitId] = useState<number | null>(
    Number.isNaN(parsedBusinessUnitId) ? null : parsedBusinessUnitId
  );
  const [businessUnitTypeFilter, setBusinessUnitTypeFilter] = useState<'car_dealership' | 'financial_advisor' | null>(
    queryBusinessUnitType || null
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    queryFilterModel ? (JSON.parse(queryFilterModel) as GridFilterModel) : { items: [] }
  );
  const parsedQueryPaginationModel = queryPaginationModel
    ? (JSON.parse(queryPaginationModel) as PaginationModel)
    : null;
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(
    parsedQueryPaginationModel && parsedQueryPaginationModel.pageSize <= 100 && parsedQueryPaginationModel.page >= 0
      ? parsedQueryPaginationModel
      : {
          page: 0,
          pageSize: isMobile ? 50 : 100,
        }
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    querySortModel ? (JSON.parse(querySortModel) as GridSortModel) : []
  );
  const [allMessagesSearch, setAllMessagesSearch] = useState<string>('');
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState<boolean>(false);

  const { data, refetch } = useGetClientsQuery({
    paginationModel,
    filterModel,
    sortModel,
    searchTerm,
    businessUnitId: businessUnitId || undefined,
    businessUnitType: businessUnitTypeFilter,
  } as ClientsQueryParams);

  const { data: sources, refetch: refetchSources } = useGetClientsSourcesQuery({
    businessUnitId: businessUnitId || undefined,
  });

  const { data: alerts, refetch: refetchAlerts } = useGetAlertsQuery(null);

  const { clients, total } = data || { clients: [], total: 0 };

  const businessUnitType =
    new Set(clients.map((client) => client.businessUnit?.type)).size === 1 ? clients[0].businessUnit?.type : '';

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error fetching clients');
    });
  }, [paginationModel, filterModel, sortModel, searchTerm, businessUnitId, businessUnitTypeFilter]);

  useEffect(() => {
    refetchSources().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error fetching sources');
    });
  }, [businessUnitId]);

  useEffect(() => {
    queryParams.delete('filterModel');
    // save filters in query params
    if (filterModel.items.length > 0) {
      queryParams.set('filterModel', JSON.stringify(filterModel));
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [filterModel]);

  useEffect(() => {
    queryParams.delete('paginationModel');
    // save pagination in query params
    queryParams.set('paginationModel', JSON.stringify(paginationModel));
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [paginationModel]);

  useEffect(() => {
    queryParams.delete('sortModel');
    // save sort in query params
    if (sortModel.length > 0) {
      queryParams.set('sortModel', JSON.stringify(sortModel));
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [sortModel]);

  useEffect(() => {
    queryParams.set('searchTerm', searchTerm);
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [searchTerm]);

  useEffect(() => {
    queryParams.set('businessUnitId', businessUnitId?.toString() || '');
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [businessUnitId]);

  useEffect(() => {
    queryParams.set('businessUnitType', businessUnitTypeFilter || '');
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [businessUnitTypeFilter]);

  if (messaging) {
    onMessage(messaging, (payload) => {
      // eslint-disable-next-line no-console
      console.log('Message received from clients page:', payload);
      if (payload.data?.identifier === 'new-client-assigned' || payload.data?.identifier === 'new-client-message') {
        const notificationAudio = new Audio('/notification-sound.wav');
        notificationAudio.play().catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
        refetch().catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
      }
      if (payload.data?.identifier === 'new-client-assigned') {
        refetchAlerts().catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
      }
    });
  }

  const navigateToClientId = (id: number, newPage = false) => {
    queryParams.set('highlightedClientId', id.toString());
    if (newPage) {
      window.open(`/clients/${id}`, '_blank');
      return;
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
    navigate(`/clients/${id}`);
  };

  return (
    <>
      {advancedSearchModalOpen && (
        <AdvancedSearch
          searchQuery={allMessagesSearch}
          open={advancedSearchModalOpen}
          setOpen={setAdvancedSearchModalOpen}
          selectClient={(client) => navigateToClientId(client.id, true)}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'center' },
          gap: 2,
          pt: 1.5,
          px: 2,
        }}
      >
        <Box display="flex" alignItems="center" pl={{ xs: 5, md: 0 }}>
          <Typography variant="h5" textAlign="left">
            Lista de clientes
          </Typography>
          <Alerts alerts={alerts || []} refetchAlerts={refetchAlerts} />
        </Box>
        <SearchSection
          searchTerm={searchTerm}
          onSearchTermChange={setSearchTerm}
          businessUnitId={businessUnitId}
          setBusinessUnitId={setBusinessUnitId}
          businessUnitTypeFilter={businessUnitTypeFilter}
          setBusinessUnitTypeFilter={setBusinessUnitTypeFilter}
        />
        {currentUser?.isAdmin && (
          <AdminSection
            allMessagesSearch={allMessagesSearch}
            setAllMessagesSearch={setAllMessagesSearch}
            onAdvancedSearch={() => setAdvancedSearchModalOpen(true)}
          />
        )}
      </Box>
      <ClientsTable
        clients={clients}
        filterModel={filterModel}
        paginationModel={paginationModel}
        sortModel={sortModel}
        sources={sources || []}
        setFilterModel={setFilterModel}
        setPaginationModel={setPaginationModel}
        setSortModel={setSortModel}
        navigateToClientId={navigateToClientId}
        highlightedClientId={highlightedClientId}
        total={total}
        businessUnitType={businessUnitType || ''}
      />
    </>
  );
};

export default Clients;
