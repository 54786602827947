import React, { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

interface AdminSectionProps {
  allMessagesSearch: string;
  setAllMessagesSearch: (search: string) => void;
  onAdvancedSearch: () => void;
}

const AdminSection: FC<AdminSectionProps> = ({ allMessagesSearch, setAllMessagesSearch, onAdvancedSearch }) => {
  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        gap: 1.5,
        '& .MuiTextField-root': {
          minWidth: '250px',
          maxWidth: '280px',
        },
        '& .MuiOutlinedInput-root': {
          height: '40px',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
          '&.Mui-focused': {
            backgroundColor: 'background.paper',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
              borderWidth: '2px',
            },
          },
        },
      }}
    >
      <TextField
        label="Buscar en todos los mensajes"
        size="small"
        variant="outlined"
        onChange={(e) => setAllMessagesSearch(e.target.value)}
        value={allMessagesSearch}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={onAdvancedSearch}
              size="small"
              sx={{
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                },
              }}
            >
              <SearchIcon />
            </IconButton>
          ),
          sx: {
            borderRadius: '8px',
            backgroundColor: 'background.default',
            pr: 0.5,
            '& .MuiInputBase-input': {
              fontSize: '14px',
              fontWeight: 500,
            },
          },
        }}
      />
    </Box>
  );
};

export default AdminSection;
