import {
  BusinessUnit,
  BusinessUnitDraft,
  BusinessUnitUpdate,
  BusinessUnitCostMetrics,
  MessageToNotify,
} from 'services/types/businessUnit';
import { TemplateResponse } from 'services/types/message';

import baseApi from './baseApi';

type TemplateComponent =
  | { type: 'HEADER' | 'FOOTER'; text: string }
  | { type: 'BODY'; text: string; example?: { body_text: string[][] } }
  | { type: 'BUTTONS'; buttons: Array<{ type: 'QUICK_REPLY'; text: string }> };

export interface TemplateCreate {
  name: string;
  category: 'UTILITY' | 'MARKETING';
  language: string;
  components: TemplateComponent[];
}

const businessUnitsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBusinessUnits: build.query({
      query: () => ({
        url: '/admin/businessUnits',
        method: 'GET',
      }),
      transformResponse: (response: BusinessUnit[]) => response,
    }),
    getBusinessUnit: build.query({
      query: (id: number) => ({
        url: `/admin/businessUnits/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: BusinessUnit) => response,
    }),
    editBusinessUnit: build.mutation({
      query: (props: { businessUnit: BusinessUnitUpdate; id: number }) => ({
        url: `admin/businessUnits/${props.id}`,
        method: 'PATCH',
        body: props.businessUnit,
      }),
      transformResponse: (response: BusinessUnit) => response,
    }),
    createBusinessUnit: build.mutation({
      query: (company: BusinessUnitDraft) => ({
        url: `admin/businessUnits`,
        method: 'POST',
        body: company,
      }),
      transformResponse: (response: BusinessUnit) => response,
    }),
    deleteBusinessUnitImage: build.mutation({
      query: (props: { businessUnitId: number; fileName: string }) => ({
        url: `/admin/businessUnits/${props.businessUnitId}/images/${props.fileName}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
    }),
    uploadBusinessUnitImages: build.mutation({
      query: (props: { businessUnitId: number; files: File[] }) => {
        const formData = new FormData();
        props.files.forEach((file) => {
          formData.append('file', file);
        });
        return {
          url: `/admin/businessUnits/${props.businessUnitId}/images`,
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response) => response,
    }),
    adminGetBusinessUnitCostCharts: build.query({
      query: (id: number) => ({
        url: `/admin/businessUnits/${id}/cost-charts`,
        method: 'GET',
      }),
      transformResponse: (response: BusinessUnitCostMetrics) => response,
    }),
    adminGetBusinessUnitTemplates: build.mutation({
      query: (id: number) => ({
        url: `/admin/businessUnits/${id}/templates`,
        method: 'GET',
      }),
      transformResponse: (response: TemplateResponse) => response,
    }),
    editBusinessUnitMessagesToNotify: build.mutation({
      query: (props: { messagesToNotify: MessageToNotify[]; id: number }) => ({
        url: `admin/businessUnits/messages-to-notify/${props.id}`,
        method: 'PATCH',
        body: props.messagesToNotify,
      }),
      transformResponse: (response: BusinessUnit) => response,
    }),
    createBusinessUnitTemplate: build.mutation({
      query: (props: { businessUnitId: number; template: TemplateCreate }) => ({
        url: `businessUnits/${props.businessUnitId}/template`,
        method: 'POST',
        body: props.template,
      }),
      transformResponse: (response: TemplateResponse) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBusinessUnitsQuery,
  useGetBusinessUnitQuery,
  useEditBusinessUnitMutation,
  useCreateBusinessUnitMutation,
  useDeleteBusinessUnitImageMutation,
  useUploadBusinessUnitImagesMutation,
  useAdminGetBusinessUnitCostChartsQuery,
  useAdminGetBusinessUnitTemplatesMutation,
  useEditBusinessUnitMessagesToNotifyMutation,
  useCreateBusinessUnitTemplateMutation,
} = businessUnitsApi;
