import React, { FC } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import useCurrentUser from 'hooks/useCurrentUser';

interface Props {
  businessUnitType: 'car_dealership' | 'financial_advisor' | null;
  setBusinessUnitType: (businessUnitType: 'car_dealership' | 'financial_advisor' | null) => void;
}

const BusinessUnitTypeSelector: FC<Props> = ({ businessUnitType, setBusinessUnitType }) => {
  const currentUser = useCurrentUser();

  if (!currentUser?.isAdmin) {
    return null;
  }

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: 80,
        '& .MuiOutlinedInput-root': {
          height: '40px',
          borderRadius: '8px',
          backgroundColor: 'background.default',
        },
        '& .MuiSelect-select': {
          height: '40px !important',
          minHeight: '40px !important',
          display: 'flex',
          alignItems: 'center',
          py: 0,
        },
        '& .MuiInputLabel-root': {
          display: 'flex',
          alignItems: 'center',
          transform: 'translate(14px, 9px)',
          '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
      }}
    >
      <InputLabel id="business-unit-type-select-label">Tipo</InputLabel>
      <Select
        labelId="business-unit-type-select-label"
        label="Tipo"
        variant="outlined"
        value={businessUnitType || ''}
        onChange={(e) => setBusinessUnitType(e.target.value as 'car_dealership' | 'financial_advisor' | null)}
      >
        <MenuItem value="">
          <Typography fontWeight={500}>Todas las empresas</Typography>
        </MenuItem>
        <MenuItem value="car_dealership">
          <Typography fontWeight={500}>Concesionarios</Typography>
        </MenuItem>
        <MenuItem value="financial_advisor">
          <Typography fontWeight={500}>Asesores Financieros</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default BusinessUnitTypeSelector;
