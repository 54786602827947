import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { getToken } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import { useHandleFirebaseNotificationTokensMutation } from 'services/api/user.api';

type Permission = 'granted' | 'denied' | 'default';

const Notifications: FC = () => {
  const notificationsPermission = localStorage.getItem('notificationsPermission') as Permission | null;

  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<Permission>(notificationsPermission || 'default');

  const [handleFirebaseNotificationTokens] = useHandleFirebaseNotificationTokensMutation();

  async function requestPermission() {
    if (!messaging) {
      // eslint-disable-next-line no-console
      console.error('Messaging is not initialized');
      return;
    }
    const newPermission = await Notification.requestPermission();
    setPermission(newPermission);

    if (newPermission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BJKa7jjFgmbQlKkEAgd39ptf8rH8uNxrmfwJxEnyZLehh6Xgxuqqo2vUDi0Q8KncUsGsfIJ_A6EYyVmkbBaknxs',
      });
      await handleFirebaseNotificationTokens({ newNotificationToken: token });
    } else if (newPermission === 'denied') {
      // eslint-disable-next-line no-console
      console.log('You denied the notifications');
    } else if (newPermission === 'default') {
      // eslint-disable-next-line no-console
      console.log('You did not respond to the notifications');
    }
    localStorage.setItem('notificationsPermission', newPermission);
  }

  if (!messaging) {
    // eslint-disable-next-line no-console
    console.error('Messaging is not initialized');
    return <div>Messaging is not initialized</div>;
  }
  if (!('Notification' in window)) {
    return (
      <Box>
        <Typography variant="h4">Notificaciones</Typography>
        <Typography variant="body1">Este navegador no soporta notificaciones</Typography>
      </Box>
    );
  }

  const handleRequestPermission = () => {
    setIsLoading(true);
    requestPermission()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('An error occurred while retrieving the token.', err);
      });
  };

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {permission === 'default' && (
        <>
          <Typography>¿Deseas recibir notificaciones de la aplicación?</Typography>
          <Button variant="contained" onClick={handleRequestPermission}>
            Habilitar
          </Button>
        </>
      )}
      {permission === 'granted' && (
        <>
          <Typography color="success.main">Notificaciones activadas</Typography>
          <Button variant="outlined" onClick={handleRequestPermission}>
            Activar notificaciones nuevamente
          </Button>
        </>
      )}
      {permission === 'denied' && (
        <Typography color="error">
          Notificaciones desactivadas. Actívalas desde la configuración de tu navegador.
        </Typography>
      )}
    </Box>
  );
};

export default Notifications;
