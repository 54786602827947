import React, { FC } from 'react';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import useCurrentUser from 'hooks/useCurrentUser';
import { useGetUsersQuery } from 'services/api/user.api';
import { User } from 'services/types/user';
import removeCSVInjection from 'utils/removeCSVInjection';

const USER_ATTRIBUTES: GridColDef<User>[] = [
  {
    field: 'name',
    headerName: 'Nombre',
    width: 100,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'businessUnitNames',
    headerName: 'Unidades de negocio',
    width: 200,
  },
  {
    field: 'brandsToSell',
    headerName: 'Marcas que vende',
    width: 200,
  },
  {
    field: 'topicsOfInterest',
    headerName: 'Temas de interés',
    width: 200,
  },
];

const USER_ATTRIBUTES_ADMIN: GridColDef<User>[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
  },
  {
    field: 'role',
    headerName: 'Rol',
    width: 100,
  },
  {
    field: 'filterConversationsByDefault',
    headerName: 'Filtrar conversaciones activado?',
    width: 300,
    valueFormatter: (value) => (value ? 'Si' : 'No'),
  },
  {
    field: 'companyId',
    headerName: 'Empresa',
    width: 100,
    valueFormatter: (value: number | null) => value ?? '(admin)',
  },
];

const UsersTable: FC = () => {
  const { data: users } = useGetUsersQuery(null);

  const currentUser = useCurrentUser();

  const usersRows = (users || []).map((user) => ({
    ...user,
    email: removeCSVInjection(user.email || ''),
  }));

  const userAttributes = currentUser?.isAdmin ? [...USER_ATTRIBUTES, ...USER_ATTRIBUTES_ADMIN] : USER_ATTRIBUTES;

  return (
    <Box style={{ width: '100%' }} my={2}>
      <Box display="flex" justifyContent="flex-start" mt={2} sx={{ ml: { xs: 6, sm: 10, md: 2 } }} alignItems="center">
        <Typography variant="h4">Lista de Usuarios</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: 'White',
          borderRadius: 4,
          p: 2,
        }}
      >
        <DataGrid
          rows={usersRows}
          columns={userAttributes}
          slots={{
            toolbar: GridToolbar,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            color: 'Black',
            height: 'calc(100vh - 150px)',
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#e8f0fe',
            },
            border: 'none',
          }}
        />
      </Box>
    </Box>
  );
};

export default UsersTable;
