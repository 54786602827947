import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import useCurrentUser from 'hooks/useCurrentUser';
import { setUserName } from 'redux/slices/user.slice';
import { useUpdateNameMutation } from 'services/api/user.api';

import ChangePassword from './ChangePassword';
import NotificationsPermissions from './NotificationsPermissions';

const Profile = () => {
  const currentUser = useCurrentUser();
  const [name, setName] = useState(currentUser?.name || '');
  const dispatch = useDispatch();

  const [updateName, { isLoading }] = useUpdateNameMutation();

  const handleNameSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-console
    updateName({ name }).catch((error) => console.error(error));
    dispatch(setUserName(name));
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" mt={2} sx={{ ml: { xs: 6, sm: 10, md: 0 }, mb: 4 }}>
        Mi Perfil
      </Typography>

      <Stack spacing={4}>
        <Card elevation={2}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Avatar
                sx={{
                  width: 64,
                  height: 64,
                  bgcolor: 'primary.main',
                  mr: 2,
                }}
              >
                <PersonIcon sx={{ fontSize: 40 }} />
              </Avatar>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Información Personal
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Actualiza tu información básica
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Box component="form" onSubmit={handleNameSubmit}>
              <TextField
                fullWidth
                label="Email"
                value={currentUser?.email}
                disabled
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Nombre"
                value={name}
                onChange={(event) => setName(event.target.value)}
                variant="outlined"
                helperText="Tu nombre será visible para otros usuarios"
                sx={{ mb: 2 }}
              />
              <PrimaryBtn type="submit" variant="contained" disabled={!name || isLoading} sx={{ minWidth: '150px' }}>
                {isLoading ? 'Actualizando...' : 'Guardar Cambios'}
              </PrimaryBtn>
            </Box>
          </CardContent>
        </Card>

        <Card elevation={2}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Notificaciones
            </Typography>
            <NotificationsPermissions />
          </CardContent>
        </Card>

        <Card elevation={2}>
          <CardContent>
            <Typography variant="h6">Seguridad</Typography>
            <Grid container spacing={2}>
              <ChangePassword />
            </Grid>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
};

export default Profile;
