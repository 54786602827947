import React, { FC, useRef, useEffect, useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowUpDownIcon from '@mui/icons-material/ImportExport';
import SearchIcon from '@mui/icons-material/Search';
import { Badge, Box, Button, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
  GridRenderCellParams,
  GridRowParams,
  GridFilterItem,
} from '@mui/x-data-grid';

import useCurrentUser from 'hooks/useCurrentUser';
import { Client } from 'services/types/client';

import FilterModal from '../FilterModal';

interface CustomDataGridProps {
  rows: Client[];
  columns: GridColDef[];
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  onFilterModelChange: (model: GridFilterModel) => void;
  onSortModelChange: (model: GridSortModel) => void;
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  rowCount: number;
  onRowClick: (params: GridRowParams, event: React.MouseEvent) => void;
  getRowClassName: (params: GridRowParams) => string;
  sources: string[];
  topBar?: React.ReactNode;
}

const CustomDataGrid: FC<CustomDataGridProps> = ({
  rows,
  columns,
  filterModel,
  sortModel,
  onFilterModelChange,
  onSortModelChange,
  paginationModel,
  onPaginationModelChange,
  rowCount,
  onRowClick,
  getRowClassName,
  sources,
  topBar,
}) => {
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedFilterField, setSelectedFilterField] = useState<string>('');
  const [updatedColumns, setUpdatedColumns] = useState<GridColDef[]>(columns.filter((col) => col.colSpan !== 0));
  const currentUser = useCurrentUser();

  const handleSort = (field: string) => {
    const existingSort = sortModel.find((sort) => sort.field === field);
    // change from asc to desc or remove the sort
    let newSortModel = existingSort
      ? sortModel.map((sort) =>
          sort.field === field ? { ...sort, sort: sort.sort === 'asc' ? ('desc' as const) : undefined } : sort
        )
      : [...sortModel, { field, sort: 'asc' as const }];
    newSortModel = newSortModel.filter((sort) => sort.sort !== undefined);
    onSortModelChange(newSortModel);
  };

  const handleFilterChange = (items: GridFilterItem[]) => {
    const newFilterModel = {
      items,
    };
    onFilterModelChange(newFilterModel);
  };

  const openFilterModalWithField = (field: string) => {
    setSelectedFilterField(field);
    setFilterModalOpen(true);
  };

  const getRowValue = (row: Client, column: GridColDef) => {
    const value = row[column.field as keyof Client];
    if (column.renderCell) {
      return column.renderCell({
        row,
        field: column.field,
        value,
        api: {},
      } as GridRenderCellParams);
    }
    if (column.valueFormatter && value !== undefined && value !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return column.valueFormatter(value as never, row, column, {} as never);
    }
    return value || '';
  };

  const getSortIcon = (field: string) => {
    const sort = sortModel.find((s) => s.field === field);
    if (sort) {
      return (
        <Box>
          {sort.sort === 'asc' ? (
            <ArrowUpwardIcon sx={{ mr: 1, cursor: 'pointer' }} onClick={() => handleSort(field)} />
          ) : (
            <ArrowDownwardIcon sx={{ mr: 1, cursor: 'pointer' }} onClick={() => handleSort(field)} />
          )}
        </Box>
      );
    }
    return (
      <Box className="sort-on-hover" sx={{ display: 'none' }}>
        <ArrowUpDownIcon
          fontSize="inherit"
          sx={{ mr: 1, cursor: 'pointer', color: 'grey' }}
          onClick={() => handleSort(field)}
        />
      </Box>
    );
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    onPaginationModelChange(model);
  };

  const handleResize = (field: string, newWidth: number) => {
    const newUpdatedColumns = updatedColumns.map((col) => (col.field === field ? { ...col, width: newWidth } : col));
    setUpdatedColumns(newUpdatedColumns);
  };

  const handleMouseDown = (e: React.MouseEvent, field: string) => {
    const startX = e.clientX;
    const startWidth = updatedColumns.find((col) => col.field === field)?.width || 0;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const newWidth = startWidth + (moveEvent.clientX - startX);
      handleResize(field, newWidth);
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const hasFilter = (field: string) => {
    return filterModel.items.some((item) => item.field === field);
  };

  useEffect(() => {
    if (tableBodyRef.current) {
      const selectedRow = tableBodyRef.current.querySelector('.Mui-selected');
      if (selectedRow) {
        selectedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [rows, getRowClassName]);

  useEffect(() => {
    setUpdatedColumns(columns);
  }, [columns]);

  const sortTooltipTitle =
    sortModel.length > 0
      ? `Ordenado por: ${sortModel.map((sort) => `${sort.field} ${sort.sort === 'asc' ? '↑' : '↓'}`).join(', ')}`
      : 'Ordenamiento';

  const getRowBackgroundColor = (row: Client) => {
    if (hoveredRow === row.id) {
      return { backgroundColor: '#f0f0f0' };
    }
    if (getRowClassName(row as unknown as GridRowParams) === 'Mui-selected') {
      return { backgroundColor: '#e0e0e0' };
    }
    if (row.userId === currentUser?.id) {
      return { backgroundColor: 'rgba(2, 137, 209, 0.1)' };
    }
    return {};
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 1, mb: 2, alignItems: 'center' }}>
        <Tooltip title={sortTooltipTitle}>
          <Badge badgeContent={sortModel.length} color="primary" invisible={sortModel.length === 0}>
            <ArrowUpDownIcon color={sortModel.length > 0 ? 'primary' : 'disabled'} />
          </Badge>
        </Tooltip>
        <Tooltip title="Filtros">
          <Badge badgeContent={filterModel.items.length} color="primary" invisible={filterModel.items.length === 0}>
            <SearchIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => setFilterModalOpen(true)}
              color={filterModel.items.length > 0 ? 'primary' : 'disabled'}
            />
          </Badge>
        </Tooltip>
        {topBar}
      </Box>
      <FilterModal
        open={isFilterModalOpen}
        onClose={() => {
          setFilterModalOpen(false);
          setSelectedFilterField('');
        }}
        onFilterChange={handleFilterChange}
        columns={columns}
        filterModel={{
          items:
            selectedFilterField && !filterModel.items.find((item) => item.field === selectedFilterField)
              ? [...filterModel.items, { field: selectedFilterField, operator: 'contains', value: '' }]
              : filterModel.items,
        }}
        sources={sources}
      />
      <Box sx={{ flex: 1, overflowX: 'auto', height: { xs: '55vh', md: '80vh' }, position: 'relative' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
          <thead style={{ position: 'sticky', top: 0, backgroundColor: '#f5f5f5', zIndex: 1 }}>
            <tr>
              {updatedColumns.map((column) => (
                <th
                  key={column.field}
                  style={{
                    width: column.width,
                    padding: '8px',
                    textAlign: 'left',
                    fontWeight: 'bold',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    borderBottom: '1px solid #ddd',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover .sort-on-hover': {
                        display: 'block',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'calc(100% - 15px)',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {column.headerName}
                    </Typography>
                    {getSortIcon(column.field)}
                    {hasFilter(column.field) ? (
                      <SearchIcon
                        sx={{ mx: 1, cursor: 'pointer', color: 'primary.main' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          openFilterModalWithField(column.field);
                        }}
                      />
                    ) : (
                      <Box className="sort-on-hover" sx={{ display: 'none' }}>
                        <Tooltip title="Buscar en esta columna">
                          <SearchIcon
                            fontSize="inherit"
                            sx={{ mr: 1, cursor: 'pointer', color: 'grey' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              openFilterModalWithField(column.field);
                            }}
                          />
                        </Tooltip>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'col-resize',
                        width: 15,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 1,
                        justifyContent: 'center',
                        backgroundColor: '#f5f5f5',
                      }}
                      onMouseDown={(e) => handleMouseDown(e, column.field)}
                    >
                      <Box
                        sx={{
                          width: '2px',
                          height: '80%',
                          border: '1px solid lightgray',
                          backgroundColor: 'lightgray',
                        }}
                        onMouseDown={(e) => handleMouseDown(e, column.field)}
                      />
                    </Box>
                  </Box>
                </th>
              ))}
            </tr>
          </thead>
          <tbody ref={tableBodyRef} className="MuiDataGrid-virtualScroller">
            {rows.map((row) => (
              <tr
                key={row.id}
                style={{
                  cursor: 'pointer',
                  ...getRowBackgroundColor(row),
                }}
                className={getRowClassName(row as unknown as GridRowParams)}
                onClick={(event) => onRowClick(row as unknown as GridRowParams, event)}
                onMouseEnter={() => setHoveredRow(row.id)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                {updatedColumns.map((column) => (
                  <td
                    key={column.field}
                    style={{
                      padding: '8px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    {getRowValue(row, column)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        <Select
          value={paginationModel.pageSize}
          onChange={(event) => handlePaginationModelChange({ page: 0, pageSize: Number(event.target.value) })}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <Typography>
          {paginationModel.page * paginationModel.pageSize + 1} -{' '}
          {Math.min(paginationModel.page * paginationModel.pageSize + paginationModel.pageSize, rowCount)} de {rowCount}
        </Typography>
        <Button
          disabled={paginationModel.page === 0}
          onClick={() =>
            handlePaginationModelChange({ page: paginationModel.page - 1, pageSize: paginationModel.pageSize })
          }
        >
          Anterior
        </Button>
        <Button
          disabled={paginationModel.page >= Math.ceil(rowCount / paginationModel.pageSize) - 1}
          onClick={() =>
            handlePaginationModelChange({ page: paginationModel.page + 1, pageSize: paginationModel.pageSize })
          }
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};

CustomDataGrid.defaultProps = {
  topBar: null,
};

export default CustomDataGrid;
