import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import Conversation from 'components/common/Conversation';
import { onMessage } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import useCurrentUser from 'hooks/useCurrentUser';
import useIsMobile from 'hooks/useIsMobile';
import {
  useGetClientQuery,
  useUpdateAdminEditableAttributesMutation,
  useGetFinancialAdvisorIndividualClientMetricsMutation,
  useGetClientLeadsQuery,
} from 'services/api/clients.api';
import { useGetClientMessagesQuery } from 'services/api/messages';
import { useGetBusinessUnitByIdMutation } from 'services/api/user.api';
import priceFormat from 'services/format/priceFormat';
import { EditableAdminClientAttributes } from 'services/types/client';
import { Message } from 'services/types/message';

import ChatFooter from './ChatFooter';
import ChatHeader from './ChatHeader';
import EditableAttribute from './EditableAttribute';
import UserAssigner from './UserAssigner';

const Chat = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { isMobile } = useIsMobile();

  const { data: client, refetch: refetchClient, isLoading } = useGetClientQuery(Number(clientId));
  const { data: messages, refetch: refetchMessages } = useGetClientMessagesQuery(Number(clientId));
  const [getBusinessUnitById, { data: clientDealership }] = useGetBusinessUnitByIdMutation();
  const [updateClient, { data: updatedClient }] = useUpdateAdminEditableAttributesMutation();
  const [getFinancialAdvisorIndividualClientMetrics, { data: financialAdvisorIndividualClientMetrics }] =
    useGetFinancialAdvisorIndividualClientMetricsMutation();
  const { data: clientLeads, refetch: refetchClientLeads } = useGetClientLeadsQuery(Number(clientId));

  const [clientDraft, setClientDraft] = useState<EditableAdminClientAttributes | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [conversation, setConversation] = useState<Message[]>([]);
  const [isFilteredConversation, setIsFilteredConversation] = useState<boolean>(
    currentUser?.filterConversationsByDefault || false
  );
  const [showAttributes, setShowAttributes] = useState(false);

  const isCarDealershipAssistant = clientDealership?.type === 'car_dealership';

  useEffect(() => {
    if (messages) {
      setConversation(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (client) {
      setClientDraft({
        firstName: client.firstName,
        lastName: client.lastName,
        hasValidName: client.hasValidName,
        rut: client.rut || undefined,
        email: client.email,
        salary: client.salary,
        savingsAmount: client.savingsAmount || undefined,
        hasVehicleAsPartPayment: client.hasVehicleAsPartPayment,
        requiresFinancing: client.requiresFinancing,
        monthlyPaymentsAmount: client.monthlyPaymentsAmount || undefined,
        budgetAmount: client.budgetAmount || undefined,
        modelsOfInterest: client.modelsOfInterest || [],
        disabledAutomaticResponses: client.disabledAutomaticResponses || false,
        disabledFollowUps: client.disabledFollowUps || false,
        isTestClient: client.isTestClient || false,
        brandOfInterest: client.brandOfInterest || undefined,
        aiSafetyStatus: client.aiSafetyStatus || undefined,
      });
      getBusinessUnitById(client.businessUnitId).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  }, [client]);

  useEffect(() => {
    if (updatedClient) {
      refetchClient().catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  }, [updatedClient, refetchClient]);

  useEffect(() => {
    if (clientDealership?.type === 'financial_advisor' && client?.id) {
      getFinancialAdvisorIndividualClientMetrics(client?.id).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  }, [clientDealership, client]);

  const handleEditClient = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClient = () => {
    if (clientDraft && client) {
      updateClient({ id: client.id, body: clientDraft }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
      setIsEditing(false);
    }
  };

  const handleRefetchClient = () => {
    // eslint-disable-next-line no-console
    refetchClient().catch((err) => console.error(err));
    // eslint-disable-next-line no-console
    refetchMessages().catch((err) => console.error(err));
    // eslint-disable-next-line no-console
    refetchClientLeads().catch((err) => console.error(err));
  };

  if (messaging) {
    onMessage(messaging, (payload) => {
      // eslint-disable-next-line no-console
      console.log('Message received in client:', payload);
      if (payload.data?.identifier === 'new-client-message') {
        refetchMessages().catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
        const notificationAudio = new Audio('/new-message-sound.wav');
        notificationAudio.play().catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
      }
    });
  }

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!client) {
    return <div>Cliente no encontrado</div>;
  }

  return (
    <>
      <Toolbar sx={{ px: { xs: 2, md: 0 } }}>
        <Box
          pl={{ xs: 5, md: 0 }}
          py={2}
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <div>
            <IconButton onClick={() => navigate(-1)} size="large">
              <ArrowBackIcon />
            </IconButton>
            {currentUser?.isAdmin && (showAttributes || !isMobile) && (
              <IconButton onClick={handleEditClient} size="large">
                {isEditing ? <CancelIcon /> : <EditIcon />}
              </IconButton>
            )}
            {showAttributes && isMobile && <Button onClick={() => setShowAttributes(false)}>Conversación</Button>}
            {!showAttributes && isMobile && <Button onClick={() => setShowAttributes(true)}>Cliente</Button>}
          </div>
          <div>
            {(!showAttributes || !isMobile) && (
              <ChatHeader
                client={client}
                refetch={handleRefetchClient}
                setIsFilteredConversation={setIsFilteredConversation}
                isFilteredConversation={isFilteredConversation}
              />
            )}
          </div>
        </Box>
      </Toolbar>

      <Grid container>
        {(showAttributes || !isMobile) && (
          <Grid item xs={12} md={4} borderRight="1px solid #e0e0e0" height="calc(100vh - 80px)" display="inline-grid">
            <Box overflow="auto" height="100%" px={2} py={1}>
              <Grid item>
                <b>Identificador:</b> {client.id} {currentUser?.isAdmin && `(${client.businessUnit?.name})`}
              </Grid>
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                {clientDraft?.hasValidName && <CheckIcon color="success" />}
                <EditableAttribute
                  label="Nombre"
                  value={clientDraft?.firstName || ''}
                  onChange={(e) => setClientDraft({ ...clientDraft, firstName: e.target.value })}
                  disabled={!isEditing}
                  type="text"
                />
              </Box>
              <EditableAttribute
                label="Apellido"
                value={clientDraft?.lastName || ''}
                onChange={(e) => setClientDraft({ ...clientDraft, lastName: e.target.value })}
                disabled={!isEditing}
                type="text"
              />
              <EditableAttribute
                label="Email"
                value={clientDraft?.email || ''}
                onChange={(e) => setClientDraft({ ...clientDraft, email: e.target.value })}
                disabled={!isEditing}
                type="email"
              />
              <EditableAttribute
                label="Rut"
                value={clientDraft?.rut || ''}
                onChange={(e) => setClientDraft({ ...clientDraft, rut: e.target.value })}
                disabled={!isEditing}
                type="text"
              />
              <Grid item>
                <b>Teléfono:</b> {client.phone}
              </Grid>
              <EditableAttribute
                label="Desactivar respuestas automáticas"
                value={clientDraft?.disabledAutomaticResponses ? '🚫🤖 Si' : false}
                onChange={(e) => setClientDraft({ ...clientDraft, disabledAutomaticResponses: e.target.checked })}
                disabled={!isEditing}
                type="checkbox"
              />
              <EditableAttribute
                label="Sueldo"
                value={clientDraft?.salary?.toString() || ''}
                onChange={(e) => setClientDraft({ ...clientDraft, salary: Number(e.target.value) })}
                disabled={!isEditing}
                type="price"
              />
              <Grid item>
                <b>Mensaje inicial:</b> {client.prospectMessage}
              </Grid>
              <Grid item>
                <b>Fuente:</b> {client.source}
              </Grid>
              <Grid item>
                <b>Temas de conversación:</b> {client.topics?.join(', ') || '-'}
              </Grid>
              {financialAdvisorIndividualClientMetrics && (
                <Grid item>
                  <b>Completó el escáner:</b>{' '}
                  {financialAdvisorIndividualClientMetrics.hasOnboardingComplete ? 'Si' : 'No'}
                </Grid>
              )}
              {financialAdvisorIndividualClientMetrics && (
                <Grid item>
                  <b>Está en Dicom:</b> {client.customAttributes?.hasUnpaidDebts ? 'Si' : 'No'}
                </Grid>
              )}
              {financialAdvisorIndividualClientMetrics && (
                <Grid item>
                  <b>Hizo una solicitud de refinanciamiento:</b>{' '}
                  {financialAdvisorIndividualClientMetrics.hasRefinanceRequest ? 'Si' : 'No'}
                </Grid>
              )}
              {financialAdvisorIndividualClientMetrics && (
                <Grid item>
                  <b>Recibió ofertas:</b> {financialAdvisorIndividualClientMetrics.hasOffers ? 'Si' : 'No'}
                </Grid>
              )}
              {isCarDealershipAssistant && (
                <>
                  <EditableAttribute
                    label="Pie"
                    value={clientDraft?.savingsAmount?.toString() || ''}
                    onChange={(e) => setClientDraft({ ...clientDraft, savingsAmount: Number(e.target.value) })}
                    disabled={!isEditing}
                    type="price"
                  />
                  <EditableAttribute
                    label="¿Vehículo en parte de pago?"
                    value={!!clientDraft?.hasVehicleAsPartPayment}
                    onChange={(e) => setClientDraft({ ...clientDraft, hasVehicleAsPartPayment: e.target.checked })}
                    disabled={!isEditing}
                    type="checkbox"
                  />
                  <EditableAttribute
                    label="¿Requiere financiamiento?"
                    value={!!clientDraft?.requiresFinancing}
                    onChange={(e) => setClientDraft({ ...clientDraft, requiresFinancing: e.target.checked })}
                    disabled={!isEditing}
                    type="checkbox"
                  />
                  <EditableAttribute
                    label="Modelos de interés"
                    value={clientDraft?.modelsOfInterest?.join(', ') || ''}
                    onChange={(e) => setClientDraft({ ...clientDraft, modelsOfInterest: e.target.value.split(', ') })}
                    disabled={!isEditing}
                    type="text"
                  />
                  <EditableAttribute
                    label="Marca de interés"
                    value={clientDraft?.brandOfInterest || ''}
                    onChange={(e) => setClientDraft({ ...clientDraft, brandOfInterest: e.target.value })}
                    disabled={!isEditing}
                    type="text"
                  />
                  <EditableAttribute
                    label="Presupuesto"
                    value={clientDraft?.budgetAmount || 0}
                    onChange={(e) => setClientDraft({ ...clientDraft, budgetAmount: Number(e.target.value) })}
                    disabled={!isEditing}
                    type="price"
                  />
                  <Grid item>
                    <b>Fecha de visita:</b>{' '}
                    {client.visitDate ? new Date(client.visitDate).toLocaleString('es-CL') : 'No'}
                  </Grid>
                  <Grid item>
                    <b>Estado de crédito:</b> {client.creditStatus}
                  </Grid>
                  {currentUser?.canAssignClient && client.businessUnit?.hasSellerManagement && (
                    <Grid item>
                      <UserAssigner client={client} />
                    </Grid>
                  )}
                  {clientLeads?.leads && clientLeads.leads.length > 0 && (
                    <Grid item>
                      <b>Prospectos:</b>
                      {clientLeads.leads.map((lead) => (
                        <Accordion key={lead.id} style={{ backgroundColor: lead.discarded ? '#f0f0f0' : 'inherit' }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Box>
                              <Typography>{lead.source}</Typography>
                              <Typography variant="caption">
                                {new Date(lead.createdAt).toLocaleString('es-CL')}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <b>Marca de interés:</b> {lead.initialBrandOfInterest}
                            </Typography>
                            <Typography>
                              <b>Modelo de interés:</b> {lead.initialModelOfInterest}
                            </Typography>
                            <Typography>
                              <b>Mensaje inicial:</b> {lead.initialMessage}
                            </Typography>
                            {lead.source && (
                              <Typography>
                                <b>Fuente:</b> {lead.source}
                              </Typography>
                            )}
                            {lead.outboundCampaignId && (
                              <Typography>
                                <b>Campaña:</b> {lead.outboundCampaignId}
                              </Typography>
                            )}
                            <Typography>
                              <b>Subido al CRM:</b>{' '}
                              {lead.CRMUpdateAt ? new Date(lead.CRMUpdateAt).toLocaleString('es-CL') : 'No'}
                            </Typography>
                            {lead.status && (
                              <Typography>
                                <b>Estado:</b> {lead.status}
                              </Typography>
                            )}
                            {lead.url && (
                              <Typography noWrap>
                                <b>URL:</b>{' '}
                                <a
                                  href={lead.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                                >
                                  {lead.url}
                                </a>
                              </Typography>
                            )}
                            {lead.UTM && (
                              <>
                                {lead.UTM.source && (
                                  <Typography>
                                    <b>UTM_source:</b> {lead.UTM.source}
                                  </Typography>
                                )}
                                {lead.UTM.medium && (
                                  <Typography>
                                    <b>UTM_medium:</b> {lead.UTM.medium}
                                  </Typography>
                                )}
                                {lead.UTM.campaign && (
                                  <Typography>
                                    <b>UTM_campaign:</b> {lead.UTM.campaign}
                                  </Typography>
                                )}
                                {lead.UTM.content && (
                                  <Typography>
                                    <b>UTM_content:</b> {lead.UTM.content}
                                  </Typography>
                                )}
                                {lead.UTM.term && (
                                  <Typography>
                                    <b>UTM_term:</b> {lead.UTM.term}
                                  </Typography>
                                )}
                              </>
                            )}
                            {lead.CRMCreationPayload && (
                              <div>
                                <Button
                                  onClick={() => {
                                    const dataStr = JSON.stringify(lead.CRMCreationPayload, null, 2);
                                    const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(
                                      dataStr
                                    )}`;
                                    const downloadAnchorNode = document.createElement('a');
                                    downloadAnchorNode.setAttribute('href', dataUri);
                                    downloadAnchorNode.setAttribute('download', `crm_payload_creation_${lead.id}.json`);
                                    document.body.appendChild(downloadAnchorNode);
                                    downloadAnchorNode.click();
                                    downloadAnchorNode.remove();
                                  }}
                                  endIcon={<DownloadIcon />}
                                  color="inherit"
                                  size="small"
                                  variant="outlined"
                                >
                                  Payload de creación en CRM
                                </Button>
                              </div>
                            )}
                            {lead.CRMCreationResponse && (
                              <div>
                                <Button
                                  onClick={() => {
                                    const dataStr = JSON.stringify(lead.CRMCreationResponse, null, 2);
                                    const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(
                                      dataStr
                                    )}`;
                                    const downloadAnchorNode = document.createElement('a');
                                    downloadAnchorNode.setAttribute('href', dataUri);
                                    downloadAnchorNode.setAttribute(
                                      'download',
                                      `crm_creation_response_${lead.id}.json`
                                    );
                                    document.body.appendChild(downloadAnchorNode);
                                    downloadAnchorNode.click();
                                    downloadAnchorNode.remove();
                                  }}
                                  endIcon={<DownloadIcon />}
                                  color="inherit"
                                  size="small"
                                  variant="outlined"
                                >
                                  Respuesta de creación en CRM
                                </Button>
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Grid>
                  )}
                </>
              )}

              {currentUser?.isAdmin && (
                <>
                  {clientDealership?.type !== 'financial_advisor' && (
                    <EditableAttribute
                      label="Follow ups desactivados"
                      value={!!clientDraft?.disabledFollowUps}
                      onChange={(e) => setClientDraft({ ...clientDraft, disabledFollowUps: e.target.checked })}
                      disabled={!isEditing}
                      type="checkbox"
                    />
                  )}
                  <EditableAttribute
                    label="Cliente de prueba"
                    value={!!clientDraft?.isTestClient}
                    onChange={(e) => setClientDraft({ ...clientDraft, isTestClient: e.target.checked })}
                    disabled={!isEditing}
                    type="checkbox"
                  />
                  {client.branchOffice && (
                    <Grid item>
                      <b>Sucursal:</b> {client.branchOffice}
                    </Grid>
                  )}
                  {clientDealership?.type !== 'financial_advisor' && (
                    <>
                      <Grid item>
                        <b>Subido a CRM:</b> {client.lastCRMUpdate !== null ? 'Si' : 'No'}
                      </Grid>
                      <Grid item>
                        <b>Última actualización en CRM:</b>{' '}
                        {client.lastCRMUpdate ? new Date(client.lastCRMUpdate).toLocaleString('es-CL') : '-'}
                      </Grid>
                      <Grid item>
                        <b>Tipo:</b>{' '}
                        {client.clientType
                          ? {
                              buy: 'Compra',
                              sell: 'Venta',
                              'after-sale': 'Post-Venta',
                            }[client.clientType] || '-'
                          : '-'}
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <b>Motivo de término:</b> {client.endReason}
                  </Grid>
                  <Grid item>
                    <b>Revisión chat:</b> {client.AIRevision}
                  </Grid>
                  {!isFilteredConversation && (
                    <>
                      <Grid item>
                        <b>Costo Total:</b> {priceFormat.format(client.cost || 0)}
                      </Grid>
                      <EditableAttribute
                        label="Safety Status (safe, unsafe or force-always-safe)"
                        value={clientDraft?.aiSafetyStatus || ''}
                        onChange={(e) => setClientDraft({ ...clientDraft, aiSafetyStatus: e.target.value })}
                        disabled={!isEditing}
                        type="text"
                      />
                    </>
                  )}
                </>
              )}
              {isEditing && (
                <Grid item xs={12}>
                  <PrimaryBtn onClick={handleSaveClient} variant="contained">
                    Guardar
                  </PrimaryBtn>
                </Grid>
              )}
              {client.surveys?.map((survey) => (
                <Grid item key={survey.name}>
                  <b>Resultado encuesta:</b> {survey.name} ({survey.status === 'finished' ? '✅' : 'Sin terminar'})
                  {survey.results.map((result, index) => (
                    <Grid item pl={1} key={result.question}>
                      {index + 1}. {result.question}: <b>{result.response || '-'}</b>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Box>
          </Grid>
        )}
        {(!showAttributes || !isMobile) && (
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              backgroundImage: 'url("/whatsapp-background.png")',
              backgroundColor: '#efeae2',
              backgroundRepeat: 'repeat',
              backgroundSize: '412.5px 749.25px',
              height: 'calc(100vh - 80px)', // 80px is the height of the toolbar
              display: 'grid',
            }}
          >
            {conversation.length > 0 && (
              <Box overflow="auto" height="100%">
                <Conversation conversation={conversation} isFilteredConversation={isFilteredConversation} fullHeight />
              </Box>
            )}
            <Box alignSelf="end">
              <ChatFooter client={client} conversation={conversation} setConversation={setConversation} />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Chat;
