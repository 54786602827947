import { ConversationEval, RunConversationEvalResponse } from 'services/types/conversationEvals';

import baseApi from './baseApi';

const conversationEvalsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllConversationEvals: build.query({
      query: (params?: { businessUnitIds?: number[] }) => ({
        url: '/admin/conversationEvals/all',
        method: 'GET',
        params,
      }),
      transformResponse: (response: ConversationEval[]): ConversationEval[] => response,
    }),
    getOneConversationEval: build.query({
      query: (conversationEvalId: number) => ({
        url: `/admin/conversationEvals/${conversationEvalId}`,
        method: 'GET',
      }),
      transformResponse: (response: ConversationEval): ConversationEval => response,
    }),
    createConversationEval: build.mutation({
      query: (conversationEval: Omit<ConversationEval, 'id' | 'createdAt' | 'updatedAt'>) => ({
        url: '/admin/conversationEvals',
        method: 'POST',
        body: conversationEval,
      }),
      transformResponse: (response: ConversationEval): ConversationEval => response,
    }),
    createBulkConversationEvals: build.mutation({
      query: (conversationEvals: Omit<ConversationEval, 'id' | 'createdAt' | 'updatedAt'>[]) => ({
        url: '/admin/conversationEvals/bulk',
        method: 'POST',
        body: conversationEvals,
      }),
    }),
    updateConversationEval: build.mutation({
      query: ({ conversationEvalId, ...data }: Partial<ConversationEval> & { conversationEvalId: number }) => ({
        url: `/admin/conversationEvals/${conversationEvalId}`,
        method: 'PATCH',
        body: data,
      }),
      transformResponse: (response: ConversationEval): ConversationEval => response,
    }),
    deleteConversationEval: build.mutation({
      query: (conversationEvalId: number) => ({
        url: `/admin/conversationEvals/${conversationEvalId}`,
        method: 'DELETE',
      }),
    }),
    getEvalFromMessage: build.mutation({
      query: (props: { messageId: number }) => ({
        url: `/admin/conversationEvals/getEvalFromMessage/${props.messageId}`,
        method: 'GET',
      }),
      transformResponse: (response: ConversationEval) => response,
    }),
    runConversationEval: build.mutation({
      query: (conversationEvalId: number) => ({
        url: `/admin/agentEvals/conversations`,
        method: 'POST',
        body: {
          conversationEvalId,
        },
      }),
      transformResponse: (response: RunConversationEvalResponse) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllConversationEvalsQuery,
  useGetOneConversationEvalQuery,
  useCreateConversationEvalMutation,
  useCreateBulkConversationEvalsMutation,
  useUpdateConversationEvalMutation,
  useDeleteConversationEvalMutation,
  useGetEvalFromMessageMutation,
  useRunConversationEvalMutation,
} = conversationEvalsApi;
