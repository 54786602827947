import { useEffect, useState } from 'react';

const useIsInsurance = () => {
  const [isInsurance, setIsInsurance] = useState(false);

  useEffect(() => {
    const { hostname, port } = window.location;
    setIsInsurance(hostname.includes('seguros.zeller.ai') || port === '3008');
  }, []);

  return isInsurance;
};

export default useIsInsurance;
