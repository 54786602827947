import type {
  BusinessUnitClientsMetrics,
  BusinessUnitMessagesMetrics,
  BusinessUnitResponseTimesMetrics,
  GetBusinessUnitMetricsParams,
  FinancialAdvisorMetrics,
} from '../types/metrics';
import baseApi from './baseApi';

const metricsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClientsMetrics: build.mutation({
      query: (params: GetBusinessUnitMetricsParams) => ({
        url: '/metrics/clients',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: BusinessUnitClientsMetrics): BusinessUnitClientsMetrics => response,
    }),
    getMessagesMetrics: build.mutation({
      query: (params: GetBusinessUnitMetricsParams) => ({
        url: '/metrics/messages',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: BusinessUnitMessagesMetrics): BusinessUnitMessagesMetrics => response,
    }),
    getResponseTimesMetrics: build.mutation({
      query: (params: GetBusinessUnitMetricsParams) => ({
        url: '/metrics/response-times',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: BusinessUnitResponseTimesMetrics): BusinessUnitResponseTimesMetrics => response,
    }),
    getFinancialAdvisorMetrics: build.mutation({
      query: (params: GetBusinessUnitMetricsParams) => ({
        url: '/metrics/financial-advisor',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: FinancialAdvisorMetrics): FinancialAdvisorMetrics => response,
    }),
    getConversationMetrics: build.mutation({
      query: (props: GetBusinessUnitMetricsParams) => ({
        url: 'metrics/conversations-metrics',
        method: 'POST',
        body: props,
      }),
      transformResponse: (response: {
        topics: Record<string, number>;
        endReasons: Record<string, number>;
        aiScores: Record<number, number>;
      }) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClientsMetricsMutation,
  useGetMessagesMetricsMutation,
  useGetResponseTimesMetricsMutation,
  useGetFinancialAdvisorMetricsMutation,
  useGetConversationMetricsMutation,
} = metricsApi;
