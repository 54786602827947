import { Car } from 'services/types/car';
import { Client } from 'services/types/client';

import baseApi from './baseApi';

const carsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCars: build.query({
      query: (params: { businessUnitId?: number; isUsed?: string }) => ({
        url: `/admin/cars?businessUnitId=${params.businessUnitId}&isUsed=${params.isUsed}`,
        method: 'GET',
      }),
      transformResponse: (response: Car[]) => response,
    }),
    getCar: build.query({
      query: (id: number) => ({
        url: `/admin/cars/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Car) => response,
    }),
    getRecommendedClients: build.mutation({
      query: (params: { carId: number; aiAnalysisLimit?: number; pageIndex?: number }) => ({
        url: `/admin/cars/${params.carId}/recommend-to-clients`,
        method: 'POST',
        body: {
          aiAnalysisLimit: params.aiAnalysisLimit,
          pageIndex: params.pageIndex,
        },
      }),
      transformResponse: (response: {
        analyzedClientsCount: number;
        recommendedClientsCount: number;
        recommendedClients: Client[];
      }) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCarsQuery, useGetCarQuery, useGetRecommendedClientsMutation } = carsApi;
