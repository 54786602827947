import React, { FC } from 'react';

import { Circle } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ListItem = (text: string) => (
  <Box display="flex">
    <Circle style={{ color: '#F9D00A', width: '10px', height: '10px', alignSelf: 'center' }} />
    <Typography fontFamily="Jost" fontWeight="regular" fontSize={{ lg: '22px', xs: '18px' }} lineHeight="30px" pl={2}>
      {text}
    </Typography>
  </Box>
);

const FeaturesInsurance: FC = () => {
  return (
    <Grid container justifyContent="center" pt={8}>
      <Box
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        justifyContent="center"
        py={8}
        width="100%"
        sx={{ backgroundColor: 'white', borderRadius: '200px 0 0 200px' }}
      >
        <Box display="flex" justifyContent="center">
          <Box width={{ xs: '80%', md: '50%', lg: '80%' }}>
            <img alt="" src="/landing/chatbot.svg" style={{ width: '100%' }} />
          </Box>
        </Box>
        <Box pl={{ xs: 0, lg: 20 }} textAlign={{ xs: 'center', lg: 'left' }} justifyContent="center" display="grid">
          <Typography fontFamily="Roboto" fontSize={{ lg: '50px', xs: '35px' }} lineHeight="50px" fontWeight="600">
            No somos el típico
          </Typography>
          <Typography
            fontFamily="Roboto"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            fontWeight="medium"
            color="#2C5889"
            maxWidth={{ xs: '450px', md: '600px', lg: '450px' }}
          >
            chatbot presente en todas las industrias
          </Typography>
          <Typography
            fontFamily="Jost"
            fontWeight="regular"
            fontSize={{ lg: '22px', xs: '18px' }}
            lineHeight="25px"
            pt={4}
            px={{ xs: 4, lg: 0 }}
            maxWidth={{ xs: '450px', md: '600px', lg: '450px' }}
          >
            Nos especializamos en la industria de seguros, conocemos el proceso de venta y sabemos entregar la mejor
            experiencia para tu empresa y tus clientes.
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column-reverse' }}
        justifyContent="center"
        py={8}
        width="100%"
      >
        <Box textAlign={{ xs: 'center', lg: 'left' }}>
          <Typography
            fontFamily="Roboto"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            fontWeight="600"
            pt={{ xs: 4, lg: 0 }}
          >
            ¿Qué incluimos?
          </Typography>
          <Box pt={{ xs: 2, lg: 4 }} justifyContent={{ xs: 'center', lg: 'left' }} display="grid">
            {ListItem('Hablamos con tus clientes')}
            {ListItem('Filtramos según tus necesidades')}
            {ListItem('Conversaciones naturales')}
            {ListItem('Seguimiento automático y personalizado')}
            {ListItem('Solicitud de información')}
            {ListItem('Validación de datos y documentos')}
            {ListItem('Agente Backoffice')}
            {ListItem('Atención 24/7')}
          </Box>
        </Box>
        <Box pl={{ xs: 0, lg: 20 }} display="flex" justifyContent="center">
          <Box width={{ xs: '80%', md: '50%', lg: '80%' }}>
            <img alt="" src="/landing/data.svg" style={{ width: '100%' }} />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default FeaturesInsurance;
