import React from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useGetBusinessUnitsQuery } from 'services/api/businessUnits.api';
import { ConversationEval } from 'services/types/conversationEvals';

interface ViewConversationEvalProps {
  conversationEval: ConversationEval;
}

const ViewConversationEval: React.FC<ViewConversationEvalProps> = ({ conversationEval }) => {
  const { data: businessUnits } = useGetBusinessUnitsQuery(null);

  const getBusinessUnitNames = () => {
    if (!businessUnits) return [];

    return conversationEval.businessUnitIds.map((id) => {
      const businessUnit = businessUnits.find((bu) => bu.id === id);
      return businessUnit ? businessUnit.name : `Business Unit ${id}`;
    });
  };

  const getMessageStyle = (role: string) => {
    if (role === 'user') {
      return {
        backgroundColor: '#f0f4f8',
        alignSelf: 'flex-start',
        borderRadius: '12px 12px 12px 0',
      };
    }
    return {
      backgroundColor: '#e3f2fd',
      alignSelf: 'flex-end',
      borderRadius: '12px 12px 0 12px',
    };
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Business Units
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {getBusinessUnitNames().map((name) => (
              <Chip key={name} label={name} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Mock Client
          </Typography>
          <Box
            sx={{
              p: 2,
              backgroundColor: '#f5f5f5',
              borderRadius: 1,
              maxHeight: '200px',
              overflow: 'auto',
            }}
          >
            <pre style={{ margin: 0 }}>{JSON.stringify(conversationEval.mockClient, null, 2)}</pre>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Conversación
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {conversationEval.messages.map((message) => (
              <Box
                key={`${message.text}-${message.role}-${message.toolCalls?.join('') || ''}`}
                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                <Box
                  component={Paper}
                  elevation={1}
                  sx={{
                    p: 2,
                    maxWidth: '80%',
                    ...getMessageStyle(message.role),
                  }}
                >
                  <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                    {message.role.toUpperCase()}
                  </Typography>
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                    {message.text || JSON.stringify(message.toolCalls)}
                  </Typography>

                  {message.expected && (
                    <Box sx={{ mt: 2, p: 1, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: 1 }}>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Expected Response:
                      </Typography>
                      <Typography variant="body2">{message.expected.responseDescription}</Typography>
                      {message.expected.responseDistance && (
                        <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 1 }}>
                          Response Distance: {message.expected.responseDistance}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewConversationEval;
