import React, { useEffect } from 'react';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import BusinessUnitSelector from 'components/common/BusinessUnitsSelector';
import useCurrentUser from 'hooks/useCurrentUser';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import moment from 'moment';
import {
  useGetClientsMetricsMutation,
  useGetMessagesMetricsMutation,
  useGetResponseTimesMetricsMutation,
  useGetFinancialAdvisorMetricsMutation,
  useGetConversationMetricsMutation,
} from 'services/api/metrics.api';
import { useGetBusinessUnitByIdMutation } from 'services/api/user.api';

import styles from './Dashboard.module.scss';
import BrandsSelector from './components/BrandsSelector';
import Charts from './components/Charts';
import HardMetricCard from './components/HardMetricCard';
import SourcesSelector from './components/SourcesSelector';
import { getBigHardMetrics, getSmallHardMetrics } from './utils';

const Dashboard = () => {
  const currentUser = useCurrentUser();
  // Create a component for a date range selector
  const currentDate = new Date();
  const dateBeginningOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const dateEndOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));

  const [initialDate, setInitialDate] = React.useState<moment.Moment>(dateBeginningOfMonth);
  const [endDate, setEndDate] = React.useState<moment.Moment>(dateEndOfMonth);
  const [businessUnitId, setBusinessUnitId] = React.useState<number | null>(
    currentUser?.isAdmin ? -1 : currentUser?.businessUnitIds[0] || null
  );
  const [sources, setSources] = React.useState<string[]>([]);
  const [brands, setBrands] = React.useState<string[]>([]);

  const [getClientsMetrics, { data: clientsMetrics, isLoading: isClientsMetricsLoading }] =
    useGetClientsMetricsMutation();
  const [getMessagesMetrics, { data: messagesMetrics, isLoading: isMessagesMetricsLoading }] =
    useGetMessagesMetricsMutation();
  const [getResponseTimesMetrics, { data: responseTimesMetrics }] = useGetResponseTimesMetricsMutation();
  const [getFinancialAdvisorMetrics, { data: financialAdvisorMetrics, isLoading: isFinancialAdvisorMetricsLoading }] =
    useGetFinancialAdvisorMetricsMutation();
  const [getTopics, { data: conversationTopics }] = useGetConversationMetricsMutation();

  const noMetricsLoaded =
    !clientsMetrics && !messagesMetrics && !responseTimesMetrics && !financialAdvisorMetrics && !conversationTopics;

  const [getBusinessUnitById, { data: businessUnitData }] = useGetBusinessUnitByIdMutation();

  const isHardMetricsLoading = isClientsMetricsLoading || isMessagesMetricsLoading || isFinancialAdvisorMetricsLoading;

  const bigHardMetrics = getBigHardMetrics({
    businessUnitType: businessUnitData?.type || 'car_dealership',
    businessUnitClientsMetrics: clientsMetrics || null,
    businessUnitMessagesMetrics: messagesMetrics || null,
    businessUnitFinancialAdvisorMetrics: financialAdvisorMetrics || null,
  });

  const smallHardMetrics = getSmallHardMetrics({
    businessUnitType: businessUnitData?.type || 'car_dealership',
    businessUnitClientsMetrics: clientsMetrics || null,
    businessUnitMessagesMetrics: messagesMetrics || null,
    businessUnitFinancialAdvisorMetrics: financialAdvisorMetrics || null,
  });

  const getAllMetrics = () => {
    if (isHardMetricsLoading) return;
    getClientsMetrics({
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
      businessUnitId: businessUnitId || null,
      sources,
      brands,
    }).catch((err) => console.error(err)); // eslint-disable-line no-console
    getMessagesMetrics({
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
      businessUnitId: businessUnitId || null,
      sources,
      brands,
    }).catch((err) => console.error(err)); // eslint-disable-line no-console
    getResponseTimesMetrics({
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
      businessUnitId: businessUnitId || null,
      sources,
      brands,
    }).catch((err) => console.error(err)); // eslint-disable-line no-console
    getTopics({
      businessUnitId: businessUnitId || null,
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
    }).catch((err) => console.error(err)); // eslint-disable-line no-console
    if (businessUnitData?.type === 'financial_advisor') {
      getFinancialAdvisorMetrics({
        startDate: initialDate.toISOString(),
        endDate: endDate.toISOString(),
        businessUnitId: businessUnitData.id,
        sources,
      }).catch((err) => console.error(err)); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    if (businessUnitId && businessUnitId !== -1) {
      getBusinessUnitById(businessUnitId).catch((err) => console.error(err)); // eslint-disable-line no-console
    }
  }, [businessUnitId]);

  return (
    <Box m={2}>
      <Typography variant="h4" mb={2} mt={2} sx={{ ml: { xs: 6, sm: 10, md: 0 } }}>
        Dashboard
      </Typography>

      <Box ml={2} mb={3} display={{ xs: 'block', md: 'flex' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Fecha inicial"
            format="DD/MMM/YYYY"
            value={initialDate}
            onChange={(value) => setInitialDate(value || dateBeginningOfMonth)}
            slotProps={{ textField: { variant: 'outlined' } }}
            className={styles.marginRight2}
          />
          <DatePicker
            label="Fecha final"
            format="DD/MMM/YYYY"
            value={endDate}
            onChange={(value) => setEndDate(value || dateEndOfMonth)}
            slotProps={{ textField: { variant: 'outlined' } }}
            className={styles.marginRight2}
            sx={{ my: { xs: 2, sm: 0 } }}
          />
        </LocalizationProvider>
        <BusinessUnitSelector
          businessUnitId={businessUnitId}
          setBusinessUnitId={(value: number) => {
            setSources([]);
            setBusinessUnitId(value);
            setBrands([]);
          }}
          disableAllBusinessUnitsOption={!currentUser?.isAdmin}
        />
        {businessUnitData?.type === 'car_dealership' && (
          <>
            <SourcesSelector sources={sources} setSources={setSources} businessUnitId={businessUnitId} />
            <BrandsSelector brands={brands} setBrands={setBrands} businessUnitId={businessUnitId} />
          </>
        )}
        {businessUnitData?.type === 'financial_advisor' && (
          <SourcesSelector sources={sources} setSources={setSources} businessUnitId={businessUnitId} />
        )}
        <Button
          onClick={getAllMetrics}
          disabled={isHardMetricsLoading}
          variant="outlined"
          sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 2, sm: 0 } }}
        >
          Obtener métricas
        </Button>
      </Box>
      {noMetricsLoaded ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
          <Typography variant="h5">No se han cargado métricas para la unidad de negocio seleccionada</Typography>
          <Button onClick={getAllMetrics} disabled={isHardMetricsLoading} variant="outlined">
            Obtener métricas
          </Button>
        </Box>
      ) : (
        <>
          <Box>
            <Typography variant="h4">Resumen</Typography>
            {isHardMetricsLoading ? (
              <CircularProgress />
            ) : (
              <Grid container>
                <Grid item xs={12} xl={8}>
                  <Grid container>
                    {bigHardMetrics.map((metric) => (
                      <Grid item xs={12} md={6} key={metric.type} p={1}>
                        <HardMetricCard {...metric} isBigSize />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} xl={4}>
                  <Grid container>
                    {smallHardMetrics.map((metric) => (
                      <Grid item xs={12} md={6} key={metric.type} p={1}>
                        <HardMetricCard {...metric} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
          <Charts
            businessUnitType={businessUnitData?.type || ''}
            clientMetrics={clientsMetrics}
            messagesMetrics={messagesMetrics}
            responseTimesMetrics={responseTimesMetrics}
            conversationTopics={conversationTopics?.topics}
            endReasons={conversationTopics?.endReasons}
          />
        </>
      )}
    </Box>
  );
};

export default Dashboard;
