import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignInResponse } from 'services/types/user';

import { Action } from './base';

const initialState: SignInResponse = {
  id: 0,
  email: '',
  accessToken: '',
  tokenType: 'Bearer',
  isAdmin: false,
  businessUnitIds: [],
  filterConversationsByDefault: true,
  isTestUser: false,
  createdAt: '',
  updatedAt: '',
  name: null,
  canAssignClient: false,
  canReassignClient: false,
  isAssignable: false,
  isOriginAdmin: false,
  originId: null,
  role: null,
};

interface UserAction extends Action {
  payload: SignInResponse;
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInUser(state: SignInResponse, action: UserAction) {
      return {
        ...state,
        ...action.payload,
      };
    },
    signUpUser(state: SignInResponse, action: UserAction) {
      return {
        ...state,
        ...action.payload,
      };
    },
    signOutUser() {
      return {
        ...initialState,
      };
    },
    setUserName: (state, action: PayloadAction<string>) => {
      return { ...state, name: action.payload };
    },
  },
});

export const { signInUser, signUpUser, signOutUser, setUserName } = userSlice.actions;
export default userSlice.reducer;
