import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Included: FC = () => {
  return (
    <Grid container justifyContent="center" py={{ xs: 4, lg: 8 }} height="362px">
      <Box
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        justifyContent="center"
        sx={{
          background: 'linear-gradient(-90deg, #224267, #15183A)',
          borderRadius: '67px',
          width: '90%',
          marginTop: { xs: 8, sm: '-8px' },
        }}
      >
        <Box alignSelf="center" px={4} py={{ xs: 4, lg: 0 }} textAlign={{ xs: 'center', lg: 'left' }}>
          <Typography
            fontFamily="Roboto"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            fontWeight="medium"
            color="white"
          >
            Todo a la medida
          </Typography>
          <Typography
            fontFamily="Roboto"
            fontSize={{ lg: '50px', xs: '35px' }}
            lineHeight="50px"
            fontWeight="600"
            color="#F9D00A"
          >
            de tu negocio
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Included;
