import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import moment from 'moment';
import { useGetLeadsQuery, useGetOutboundCampaignIdsQuery } from 'services/api/leads.api';
import { Lead } from 'services/types/lead';

const last24hoursDate = new Date();
last24hoursDate.setDate(last24hoursDate.getDate() - 1);

const formatDateValue = (value: Date | null) => {
  if (!value) return 'No';
  const dateValue = new Date(value);
  if (dateValue > last24hoursDate) return dateValue.toLocaleTimeString('es-CL');
  return `${dateValue.getDate()}/${dateValue.getMonth() + 1} ${dateValue.getHours()}:${dateValue
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

const getAttributes = (): GridColDef<Lead>[] => {
  return [
    {
      field: 'businessUnitName',
      headerName: 'Unidad Negocio',
      width: 120,
    },
    {
      field: 'clientId',
      headerName: 'Cliente',
      width: 70,
    },
    {
      field: 'source',
      headerName: 'Origen',
      width: 180,
    },
    {
      field: 'outboundCampaignId',
      headerName: 'Campaña',
      width: 130,
    },
    {
      field: 'lastCRMUpdate',
      headerName: 'Subido CRM',
      width: 100,
      valueFormatter: (value: Date | null) => formatDateValue(value),
    },
    {
      field: 'initialBrandOfInterest',
      headerName: 'Marca interés',
      width: 120,
    },
    {
      field: 'initialModelOfInterest',
      headerName: 'Modelo interés',
      width: 120,
    },
    {
      field: 'createdAt',
      headerName: 'Creación',
      width: 100,
      valueFormatter: (value: Date) => formatDateValue(value),
    },
    {
      field: 'messagesCount',
      headerName: 'Mensajes',
      width: 90,
    },
    {
      field: 'discarded',
      headerName: 'Descartado',
      width: 90,
      valueFormatter: (value: boolean) => (value ? 'Si' : 'No'),
    },
  ];
};

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface LeadsProps {
  businessUnitId: number | null;
}

const Leads: FC<LeadsProps> = ({ businessUnitId }) => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const queryOutboundCampaignId = queryParams.get('outboundCampaignId');
  const [outboundCampaignId, setOutboundCampaignId] = useState<string | null>(queryOutboundCampaignId);

  const currentDate = new Date();
  const dateBeginningOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const dateEndOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  const queryStartDate = queryParams.get('startDate');
  const queryEndDate = queryParams.get('endDate');
  const [startDate, setStartDate] = useState<moment.Moment>(
    queryStartDate ? moment(queryStartDate) : dateBeginningOfMonth
  );
  const [endDate, setEndDate] = useState<moment.Moment>(queryEndDate ? moment(queryEndDate) : dateEndOfMonth);

  const queryPaginationModel = queryParams.get('paginationModel');
  const parsedQueryPaginationModel = queryPaginationModel
    ? (JSON.parse(queryPaginationModel) as PaginationModel)
    : null;
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(
    parsedQueryPaginationModel && parsedQueryPaginationModel.pageSize <= 100 && parsedQueryPaginationModel.page >= 0
      ? parsedQueryPaginationModel
      : {
          page: 0,
          pageSize: 50,
        }
  );

  const { data, refetch } = useGetLeadsQuery({
    paginationModel,
    businessUnitId: businessUnitId || -1,
    outboundCampaignId,
    startDate: startDate ? startDate.toISOString() : null,
    endDate: endDate ? endDate.toISOString() : null,
  });

  const { data: outboundCampaignIds } = useGetOutboundCampaignIdsQuery({
    businessUnitId: businessUnitId || -1,
    startDate: startDate ? startDate.toISOString() : null,
    endDate: endDate ? endDate.toISOString() : null,
  });

  const { leads, total } = data || { leads: [], total: 0 };

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error fetching leads');
    });
  }, [paginationModel, businessUnitId]);

  useEffect(() => {
    queryParams.delete('paginationModel');
    // save pagination in query params
    queryParams.set('paginationModel', JSON.stringify(paginationModel));
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [paginationModel]);

  useEffect(() => {
    queryParams.set('businessUnitId', businessUnitId?.toString() || '');
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [businessUnitId]);

  const columns = getAttributes();

  return (
    <Box style={{ width: '100%' }} my={2} pl={2}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Fecha inicial"
          format="DD/MMM/YYYY"
          value={startDate}
          onChange={(value) => setStartDate(value || dateBeginningOfMonth)}
          slotProps={{ textField: { variant: 'outlined' } }}
        />
        <DatePicker
          label="Fecha final"
          format="DD/MMM/YYYY"
          value={endDate}
          onChange={(value) => setEndDate(value || dateEndOfMonth)}
          slotProps={{ textField: { variant: 'outlined' } }}
          sx={{ ml: 2 }}
        />
      </LocalizationProvider>
      <FormControl sx={{ minWidth: 250, ml: 2 }}>
        <InputLabel id="outboundCampaignId-select-label">Campaña</InputLabel>
        <Select
          label="Campaña"
          variant="outlined"
          onChange={(e) => setOutboundCampaignId(e.target.value)}
          value={outboundCampaignId || ''}
          autoWidth
        >
          <MenuItem value="">Todas las campañas</MenuItem>
          {outboundCampaignIds?.map((id) => (
            <MenuItem key={id} value={id}>
              <Typography fontWeight={500}>{id}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box pl={0} pr={2} py={2}>
        <DataGrid
          rows={leads}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowCount={total}
          paginationMode="server"
        />
      </Box>
    </Box>
  );
};

export default Leads;
